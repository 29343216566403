/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The terms for Privacy Policy
* 
*****************************************************************************************************/

function PrivacyPolicy() {
    return (
        <>
            <div className="terms-header">
                <h3>Privacy Policy</h3>
            </div>
            <div className="terms-content row">
                <div className="terms-columns">
                    <h5>1.	Scope of Policy</h5>
                    <p>This statement applies to Max Advanced Brakes and its customers within North America, visitors to www.maxbrakes.com or any other website or mobile application on which we provide this statement.</p>
                    <h5>2.	What are Cookies?</h5>
                    <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by online service providers in order, for example, to make their websites or services work, or work more efficiently, as well as to provide reporting information.
                    <br /><br />
                    Cookies set by the website owner or service provider (in this case, Max Advanced Brakes) are called “first-party cookies”. Cookies set by parties other than the website owner are called “third-party cookies”. Third-party cookies enable third-party features or functionality to be provided on or through the website or service you are using (such as advertising, interactive content and analytics). The third parties that set these third-party cookies can recognize your computer both when it visits the website or service in question and also when it visits certain other websites or services.
                    <br /><br />
                    Why do we use Cookies?
                    <br /><br />
                    We may also use a technology called "tracer tags" or "Web Beacons". This technology allows us to understand which pages you visit on our website or what email communications you receive and open. For example, the information we collect through this technology may include the first date and time you open our email, and whether you click any links included in our email, This information helps us optimize and tailor our websites and marketing communications for you and others. 
                    We may offer you the opportunity to engage with our content on or through third-party social networking websites, plug-ins and applications. When you engage with our content on or through third-party social networking websites, plug-ins and applications, you may allow us to have access to certain information associated with your social media account (e.g., name, username, email address, profile picture, gender) to deliver the content or as part of the operation of the website, plug-in or application. When you provide information from your social media account, we may use this information to personalize your experience on our websites and on the third-party social networking websites, plug-ins and applications, and to provide you with other products or services you may request.
                    <br /><br />
                    How often will Max Advanced Brakes update this Cookie Statement?
                    <br /><br />
                    We may update this Cookie Statement from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Statement regularly to stay informed about our use of cookies and related technologies.
                    The date at the bottom of this Cookie Statement indicates when it was last updated.
                    Updated October 4, 2021</p>
                    <h5>3.	Information we collect includes:</h5>
                    <ul>
                        <li>Information you give us when registering with us;</li>
                        <li>Information collected when you contact us, visit our website or links, use our mobile application, use our Wi-Fi hotspot, or view our advertisements;</li>
                        <li>Information from cookies;</li>
                        <li>Information from third parties (e.g., credit reference agencies, law enforcement/regulatory bodies).</li>
                    </ul>
                    <h5>4.	We Use Information To:</h5>
                    <ul>
                        <li>Provide you with the information, services and products you request;</li>
                        <li>Help us develop new products and services;</li>
                        <li>Help us monitor and improve our products, services and technology;</li>
                        <li>Helps us run our sites and services;</li>
                        <li>Tell you about our products and services with your consent as required by applicable law;</li>
                        <li>Display online ads to you, and better personalize our advertising, sites and services to you; and</li>
                        <li>Create aggregate or de-identified information for various uses and to share with our business partners in that format.</li>
                    </ul>
                    <h5>5.	We Share Information With:</h5>
                    <ul>
                        <li>Other Max Advanced Brakes group entities;</li>
                        <li>Third-party services providers, such as cloud service providers, and as necessary, with third parties that help run our various programs (e.g competitions, hosting, delivery, payment, and fraud management providers, credit scoring agencies and analytics platform providers. These service providers use your Personal Information in accordance with Max Advanced Brakes guidelines and applicable law;</li>
                        <li>Social networks (only if you interact with a social network through our app)</li>
                        <li>Legal and law enforcement agencies where required by applicable law; and</li>
                        <li>Other third party with your consent or where permitted or required by applicable law.</li>
                    </ul>
                    <h5>6.	Choices</h5>
                    <ul>
                        <li>With your consent, we may market to you other Max Advanced Brakes products/services not reference in our privacy statement;</li>
                        <li>You can access your Personal Information and ask us to update, correct or delete any Personal Information we have about you; and</li>
                        <li>You may opt-out of further contact from us as well as certain other uses of your Personal Information for marketing or personalization purposes as explained in our full privacy statement</li>
                    </ul>
                    <h5>Contact Us</h5>
                    <p>If you’d like further information on: your privacy rights; opting-out or subscribing from message; accessing; deleting; or updating your Personal Information; or to register a complaint, please contact us at:
                    <br /><br />
                    By email: info.ws@maxbrakes.com 
                    <br /><br />
                    By phone: Customer Service at 1-855-818-8788</p>
                </div>
            </div>
        </>
    )
}
 
 export default PrivacyPolicy;