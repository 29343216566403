/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: Get all the years for the e-catalouge
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { catalogApiURL } from '../../../../context/addresses.js';
import Loading from '../../../../context/loading.js';
import ListGroup from 'react-bootstrap/ListGroup';

function GetAllYears() {
    const [years, setYears] = useState([]); // Years from url
    const [isLoading, setIsLoading] = useState(false); // Check if loading 
    const [isError, setisError] = useState(false); // Check for error
    const [errorStatus, setErrorStatus] = useState();
    const location = useLocation(); // used to get the pathname

    // Get the years
    useEffect(() => {
        const fetchYear = async () => {
            setisError(false)
            setIsLoading(true);

            try {
                const response = await axios.get(catalogApiURL, { Session: {} });

                response.data.sort((a, b) => { return a - b; })

                setYears(response.data);
            } catch (error) {
                if (error.response && error.response.data === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }

                console.log("fetchYear failed: " + error.message);
                setisError(true);
            }
            setIsLoading(false);
        };

        fetchYear();
    }, []);

    // The column header will change to the year clicked
    function handleClick(event) {
        document.getElementsByClassName('col1')[0].childNodes[0].innerHTML = event.currentTarget.innerHTML;
        document.getElementsByClassName('col2')[0].childNodes[0].innerHTML = "Make";
        document.getElementsByClassName('col3')[0].childNodes[0].innerHTML = "Model";
        document.getElementsByClassName('col4')[0].childNodes[0].innerHTML = "Category";
        document.getElementsByClassName('col5')[0].childNodes[0].innerHTML = "Product";
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <>
            {isError && <div>Something went wrong ...</div>}
            {isLoading ? (
                <Loading />
            ) : (
                years.slice(0).reverse().map((year, index) => {
                    return (
                        <ListGroup.Item id={`e-cat/${year}`} action className={`e-cat-item list-group-item-action ${(location.pathname.split("/")[2] === year.toString()) ? ("active") : ("")}`} key={index + 1}>
                            <Link to={`/e-cat/${year}`} onClick={event => handleClick(event)}>{year}</Link>
                        </ListGroup.Item>
                    )
                })
            )}
        </>
    );
}

export default GetAllYears;
