/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date Created: September 27th, 2021
 * 
 *  Description: Main-content component
 * 
 *****************************************************************************************************/

import { Route, Routes } from "react-router";
import { Accounts, Coupon, Credit, ECat, GlobalSettings, Home, History, Interchange, Login, NewOrder, NotAuthorized, NotFound, Password, Promotion, ReturnPage, SignUp, Statement, Terms, Unshipped } from "..";
import { Details, NewAccountUser } from "../pages/accounts/accounts";
import { CreditsDetails } from "../pages/credit/credit";
import { OrderDetails } from "../pages/history/history";
import { Preview } from "../pages/new-order/components/orderDraft";
import { ReturnBatch } from "../pages/return-page/return-page";
import { StatementsDetails } from "../pages/statements/statements";
import PrivateRoute from "../../PrivateRoute";

function MainContent() {

    return (
        <Routes>
            <Route path="/login" element={ <Login /> } />
            <Route path="/sign-up" element={ <SignUp /> } />
            <Route path="/password/*" element={ <Password /> } />
            <Route path="/notAuhtorized" element={ <NotAuthorized /> } />
            <Route path="/notFound" element={ <NotFound /> } />
            <Route path="/terms/*" element={ <Terms /> } />
            <Route 
                path="/" 
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/e-cat/*" 
                element={
                    <PrivateRoute>
                        <ECat />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/new-order/*" 
                element={
                    <PrivateRoute>
                        <NewOrder />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/preview-order" 
                element={
                    <PrivateRoute>
                        <Preview />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/history/*" 
                element={
                    <PrivateRoute>
                        <History />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/history/:orderItem" 
                element={
                    <PrivateRoute>
                        <OrderDetails />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/return-page/*" 
                element={
                    <PrivateRoute>
                        <ReturnPage />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/return-page/returns/:username" 
                element={
                    <PrivateRoute>
                        <ReturnBatch />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/credit/*" 
                element={
                    <PrivateRoute>
                        <Credit />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/credit/:creditId" 
                element={
                    <PrivateRoute>
                        <CreditsDetails />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/statements/*" 
                element={
                    <PrivateRoute>
                        <Statement />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/statements/:statement_id" 
                element={
                    <PrivateRoute>
                        <StatementsDetails />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/interchange" 
                element={
                    <PrivateRoute>
                        <Interchange />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/accounts/*" 
                element={
                    <PrivateRoute>
                        <Accounts />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/accounts/new-user" 
                element={
                    <PrivateRoute>
                        <NewAccountUser />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/accounts/user/:username" 
                element={
                    <PrivateRoute>
                        <Details />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/coupon" 
                element={
                    <PrivateRoute>
                        <Coupon />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/promotion" 
                element={
                    <PrivateRoute>
                        <Promotion />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/global-settings" 
                element={
                    <PrivateRoute>
                        <GlobalSettings />
                    </PrivateRoute>
                } 
            />
            <Route 
                path="/unshipped" 
                element={
                    <PrivateRoute>
                        <Unshipped />
                    </PrivateRoute>
                } 
            />
        </Routes>
    )
}

export default MainContent;