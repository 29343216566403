import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function RowItem({ item, product, setProduct, index }) {

    // add the item to cart
    function addToCart(i) {
        let productid = [i, 1];
        product.push(productid);
        setProduct(product);
        localStorage.setItem("productId", JSON.stringify(product));
    }

    return (
        <tr key={index}>
            <td>{(item.s !== null && item.s !== undefined) ? (item.s) : (item.n)}</td>
            <td>{item.d}</td>
            <td>${(item.price).toFixed(2)}</td>
            <td>
                {(item.q > 0 && item.price > 0) ? ("Yes") : ("No")}
            </td>
            <td>
                {(item.q > 0 && item.price > 0) ? (
                    <Button variant="secondary" onClick={() => addToCart(item.n)}>
                        <Link className="text-white" to="/new-order">Order</Link>
                    </Button>
                ) : (null)}
            </td>
        </tr>
    )
}

export default RowItem;