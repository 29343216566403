import Button from "react-bootstrap/Button";

function CartTableRows({ item, index, returnCart, setReturnCart, setNumRows2 }) {
    
    function handleRemove(product) {
        let tempArr = returnCart;
        tempArr = tempArr.filter((i) => {
            return i.product !== product;
        });
        setReturnCart(tempArr);
        setNumRows2(tempArr.length);
    }
    
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{item.product}</td>
            <td>{item.return_qty}</td>
            <td>${Number(item.return_amount).toFixed(2)}</td>
            <td>
                <Button variant="secondary" type="button" onClick={() => handleRemove(item.product)}>Remove</Button>
            </td>
        </tr>
    )
}

export default CartTableRows;