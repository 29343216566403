/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: August 6th, 2020
 * 
 *  Description: The component that will allow the user to place orders
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import * as XLSX from "xlsx";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import TableRow from './components/TableRow.js';
import Notes from './components/Notes.js';
import { useAuth } from '../../../../context/auth.js';
import PreviewOrder from '../../preview-order/preview-order.js';
import { table_headers, pattern } from '../../../../context/orderModels.js';
import { draftApiURL, usersApiURL } from '../../../../context/addresses.js';
import Loading from '../../../../context/loading.js';
import downloadTemplate from './example/orders.xlsx';
import './styles/orderDraft.css';

export const Preview = () => <PreviewOrder />;

// Makes the headers for the table
const OrderLineTableHeaders = () => {
    return (
        <thead>
            <tr>
                {Object.entries(table_headers).map(([key, value]) => {
                    return <th className={key} key={key}>{value}</th>;
                })}
            </tr>
        </thead>
    )
};

// draft
function OrderDraft() {
    let auth = useAuth();
    let location = useLocation();
    const [accounts, setAccounts] = useState([]); // Get the list of accounts for filter
    const [username, setUsername] = useState(""); // The username used for filter
    const [savedDraft, setSavedDraft] = useState({});
    const [orderInfo, setOrderInfo] = useState({ shipTo: (auth.user.address !== null && auth.user.address !== undefined && auth.user.address !== "") ? auth.user.address : "", shipping: "Shipping", po: "" }); // Shipping, shipto and po in orderinfo
    const [notes, setNotes] = useState({ myNotes: "", MaxNotes: "" }); // Notes in orderinfos
    const [instanceKey, setInstanceKey] = useState(0); // set key for emptyTable
    const [numRows, setNumRows] = useState(10); // set initial number of rows
    const [upBatch, setUpBatch] = useState([]); // products from uploaded file and exisiting products
    const [grandTotals, setGrandTotals] = useState({}); // all totals in the draft
    const [grandTotal, setGrandTotal] = useState(0); // calculate the grand total of the draft
    const [lineValues, setLineValues] = useState({}); // Values from TableRow
    const [orderDraft, setOrderDraft] = useState(); // All items in the draft are saved in this variable when preview order is clicked
    const [draftItems, setDraftItems] = useState([]); // To save draft items to server
    const exisitingProduct = JSON.parse(localStorage.getItem("productId")); // Get item from e-cat and quickOrder
    const [dup, setDup] = useState(); // Hold a list of row descriptions that will be used to remove descriptions from other rows to prevent duplicates
    const [isDup, setIsDup] = useState(false); // If there are duplicates, an error message will be shown to the user
    const maxqty = { "ONZ": auth.user.maxonz, "OCG": auth.user.maxocg, "SCB": auth.user.maxscb, "SNZ": auth.user.maxsnz, "AFN": auth.user.maxafn, "CDM": auth.user.maxcdm, "CDP": auth.user.maxcdp, "8100": auth.user.maxbearing, "CNZ": auth.user.maxcnz, "MD": auth.user.maxmd, "Other": auth.user.maxother,"NCG":auth.user.maxncg };
    const [isLoading, setIsLoading] = useState(false); // loading data
    const [errorStatus, setErrorStatus] = useState();
    const previewButton = useRef(); // To be able to disable if there are no items in table
    const customerSelectRef = useRef(); // To be able to clear select when clear is pressed
    const params = { user: (username === "") ? (auth.user.username) : (username), drafthash: location.pathname.split('/')[2], ...orderInfo, coupon: [], ...notes, draftitems: draftItems }; // parameter of the draft to send to save the state of the draft
    let row = []; // Where rows are stored after they are made
    let typingTimer; // setTimeout ID value
    let doneTypingInterval = 1000; // Typing interval

    // Get all users if current user is an admin
    useEffect(() => {
        if (auth.user.access !== 5) {
            async function fetchAccount() {
                setIsLoading(true);

                try {
                    const response = await axios(`${usersApiURL}?perpage=200`, { Session: {}, withCredentials: true });

                    response.data = response.data.sort((a, b) => {
                        return ("" + a.company_name).localeCompare("" + b.company_name);
                    })

                    setAccounts(response.data);
                } catch(error) {
                    if (error.response && error.response.status === 401) {
                        console.log("User is not signed in: " + error.message);
                        setErrorStatus(error.response.status);
                    }

                    console.log("fetchAccount failed: " + error.message);
                }

                setIsLoading(false);
            }

            fetchAccount();
        }
    }, [auth]);

    // Get the state of the drafts
    useEffect(() => {
        async function fetchDrafts() {
            try {
                const response = await axios.get(`${draftApiURL}/${location.pathname.split('/')[2]}`, { Session: {}, withCredentials: true });

                let draftItemsLength = (exisitingProduct && exisitingProduct !== "" && exisitingProduct !== null) ? response.data.draftitems.length + exisitingProduct.length : response.data.draftitems.length;

                if (draftItemsLength > 0) {
                    uploadBatchObject(response.data.draftitems);

                    setSavedDraft(response.data);
                    setOrderInfo({ ...orderInfo });
                }
                console.log(exisitingProduct)
                if (exisitingProduct !== null) {
                    uploadBatchArray(exisitingProduct, 'ecat');
        
                    localStorage.setItem("productId", JSON.stringify([]));
                }

                exisitingProductToTable();
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }

                console.log("FetchDrafts failed: " + error.message);
            }
        }

        fetchDrafts();
    }, [])

    // Save all line values in object
    useEffect(() => {
        let tempDraftItems = [];
        let disabledBool = false;
        
        let table_items = Object.values(lineValues).filter((item) => {
            console.log(item.pcode !== "")
            return item.pcode !== "";
        })
        
        setOrderDraft({ user: username, draftNum: location.pathname.split('/')[2], ...orderInfo, coupon: [], ...notes, table: table_items, grandTotal: grandTotal })
        
        Object.values(table_items).forEach((item, index) => {
            if (item !== null && item.pcode !== "") {
                tempDraftItems[index] = { product: item.pcode, des: item.product, qty: Number(item.qty) }
            }
        });

        if (hasDuplicates(tempDraftItems)) setIsDup(true);
        else setIsDup(false);

        if (tempDraftItems.length === 0) disabledBool = true;
        
        for (let key in table_items) {
            let result = '';
            if (table_items[key].product !== '' && table_items[key].product !== undefined && table_items[key].product !== null) result = table_items[key].product.match(pattern);
            if (result === null) result = "Other";
            if (Array.isArray(result) && result.length > 1) result = result[1];
            
            if (maxqty[result] < Number(table_items[key].qty) || table_items[key].qty === "" || isNaN(table_items[key].qty) || table_items[key].product === "") disabledBool = true;
        }
        
        disableButton(disabledBool);
        setDraftItems(tempDraftItems);
    }, [orderInfo, notes, lineValues, grandTotal]);

    // Get the grandTotal by calculating the totals for all rows
    useEffect(() => {
        if (Object.entries(grandTotals).length !== 0) {
            setGrandTotal(Object.values(grandTotals).reduce((acc, val) => {
                if (isNaN(val)) return acc + 0.00;
                else return acc + val;
            }))
        }
    }, [grandTotals]);

    function updateNotes(note, textString) {
        setNotes({ ...notes, [note]: textString });
    }

    const updateLineValues = useCallback((id, line) => {
        setLineValues({ ...lineValues, [id]: line });
    }, [lineValues])
    
    const updateGrandTotals = useCallback((id, total) => {
        setGrandTotals({ ...grandTotals, [id]: total });
    }, [grandTotals])

    function updateDup(id, value) {
        setDup({ ...dup, [id]: value });
    }

    // Save line values to localstorage for preview order
    function previewOrder() {
        let temp = orderDraft;

        if (temp !== undefined) {
            temp.table = temp.table.filter((item) => {
                if (item !== null && item !== undefined && item.pcode !== "") return item;
            })
        }
        
        localStorage.setItem("orderDraft", JSON.stringify(temp));
    }

    function hasDuplicates(arr) {
        let counts = [];
        if (arr.length > 0) {
            for (let i = 0; i <= arr.length; i++) {
                if (arr[i] === undefined) continue;
                
                let temp_product = arr[i].des;
                if (counts[temp_product] === undefined) counts[temp_product] = 1;
                else return true;
            }
        }
        return false;
    }

    function disableButton(disabledBool) {
        if (previewButton.current.disabled !== disabledBool) previewButton.current.disabled = disabledBool;
    }

    // When the user is done typing, post to the server the state of the draft
    async function postChange() {
        if (params.draftitems.length !== 0) {
            try {
                await axios.post(draftApiURL, qs.stringify(params), { Session: {}, withCredentials: true });
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }

                console.log("postChange() failed: " + error.message);
            }
        }
    }

    // Clear typing timer if the user is still typing
    function clearCount() {
        clearTimeout(typingTimer);
    }

    // Check if the user finished typing 
    function checkTyping() {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(postChange, doneTypingInterval)
    }

    // Reset the table and certain draft values
    function emptyTable() {
        setInstanceKey(instanceKey + 1);
        setGrandTotals({});
        setGrandTotal(0);
        setLineValues({});
        setNumRows(10);
        setUpBatch([]);
        setDraftItems([]);
        setDup([]);
        setIsDup(false);
        localStorage.removeItem("orderDraft");
        previewButton.current.disabled = true;
        deleteDraftItems();
    }

    async function deleteDraftItems() {
        try {
            await axios.delete(draftApiURL, { 
                Session: {}, 
                withCredentials: true, 
                params: {
                    drafthash: location.pathname.split('/')[2],
                    username: auth.user.username
                }
            });

            console.log('Table empty');
        } catch(error) {
            console.log('deleteDraftItems() failed: ' + error)
        }
    }

    // Open and read uploaded file
    function handleOnChange(event) {
        let file = event.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function(evt) {
            let data = evt.target.result;
            let parsed = '';
            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type:'binary' });
                parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
                
                if (checkUploadLength(parsed.length)) return;
            } else {
                parsed = data.trim().split("\n");
                for (let i in parsed) {
                    parsed[i] = parsed[i].split(",");
                }
                
                if (checkUploadLength(parsed.length)) return;

                for (let i = 1; i < parsed.length; i++) {
                    parsed[i][1] = Number(parsed[i][1]);
                }
            }

            uploadBatchArray(parsed, 'template');
        }

        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
    }

    function checkUploadLength(arrLength) {
        if (arrLength > 200) {
            alert("There are more than 200 items. Please make sperate orders.");
            return true;
        }

        return false;
    }

     // When a file is uploaded click submit to put values in the table
     function handleSubmit(event) {
        event.preventDefault();
        if (upBatch && upBatch.length > 0) {
            setInstanceKey(instanceKey + upBatch.length);
            checkNumRowWithLength(upBatch.length);
            document.getElementById('uploadButton').value = '';
        }
    }

    // get the all information for batch that are arrays
    function uploadBatchArray(batchItems, origin) {
        if (batchItems && batchItems.length) {
            let tempUpBatch = upBatch;
            for (let batchLineNum in batchItems) {
                if (batchLineNum === '0' && origin !== 'ecat') continue;
                let pro = { product: batchItems[batchLineNum][0], qty: batchItems[batchLineNum][1] };
                tempUpBatch.push(pro);
            }
            
            tempUpBatch = tempUpBatch.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.product === value.product
                ))
            )

            setUpBatch(tempUpBatch);
        }
    }

    // For saved draft items objects
    function uploadBatchObject(batchItems) {
        if (batchItems !== null && batchItems !== undefined) {
            let tempUpBatch = upBatch;
            
            for (let key in batchItems) {
                let pro = { product: batchItems[key].product, description: batchItems[key].des, qty: batchItems[key].qty };
                tempUpBatch.push(pro);
            }
            
            tempUpBatch = tempUpBatch.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.product === value.product && t.description === value.description
                ))
            )

            setUpBatch(tempUpBatch);
        }
    }

    // When the clear button is clicked the customer select is cleared
    function clearCustomer() {
        setUsername("");
        customerSelectRef.current.value = "";
    }

    // If there is an exisitingProduct add to table
    function exisitingProductToTable() {
        if (upBatch && upBatch.length > 0) {
            setInstanceKey(instanceKey + upBatch.length);
            checkNumRowWithLength(upBatch.length);
        }
    }

    function checkNumRows() {
        if (numRows < 200) {
            setNumRows(numRows + 5);
        } else {
            alert("Maximum 200 cart rows. Split and create another order");
        }
    }

    function checkNumRowWithLength(len) {
        if (len > 10 && len < 200) {
            setNumRows(len)
        } else if (len < 10) {
            setNumRows(10);
        } else {
            alert("Maximum 200 cart rows. Split and create another order");
        }
    }

    function customerList() {
        return (
            (isLoading) ? (<div><Loading /></div>) : (
                <Row>
                    <Form.Label column md={1}>Customer:</Form.Label>
                    <Form.Group as={Col} md={4} controlId="cusName">
                        <Form.Select ref={customerSelectRef} placeholder="Customer Name" onChange={(e) => { setUsername(e.target.value); }}>
                            <option value=""></option>
                            {accounts.map((item, index) => {
                                return (
                                    <option value={item.username} key={index}>
                                        {item.company_name} ({item.firstname} {item.lastname})
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Col md={4}>
                        <Button id="clearButton" variant="secondary" onClick={clearCustomer}>Clear</Button>
                    </Col>
                    <Form.Label>Placing order as: {username}</Form.Label>
                </Row>
            )
        )
    }

    for (let i = 0; i < numRows; i++) {
        row[i] = <TableRow id={i} proindex={i + i + i + 1} upBatch={upBatch[i]} desindex={i + i + i + 2} qtyindex={i + i + i + 3} grandTotals={grandTotals} updateGrandTotals={updateGrandTotals} lineValues={lineValues} updateLineValues={updateLineValues} updateDup={updateDup} auth={auth} username={username} dup={dup} />;
    }

    if (errorStatus === 401) return <Navigate to="/login" />
    
    return (
        <Container>
            <div id="draft" className="page" onKeyUp={checkTyping} onKeyDown={clearCount}>
                <div id="select-customer">
                    {(auth.user.access !== 5) ? (customerList()) : (null)}
                </div>
                <br />
                <div id="orderInfos" className='mb-3'>
                    <Row>
                        <Form.Label column md={1}>Ship to:</Form.Label>
                        <Col md={4}>
                            <Form.Select id="Location" onChange={event => setOrderInfo({...orderInfo, shipTo: event.target.value})}>
                                {(auth.user.address !== null && auth.user.address !== undefined && auth.user.address !== "") ? (
                                    <option value={auth.user.address}>{auth.user.address}</option>
                                ) : (
                                    <option value="N/A">Failed to get address</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Form.Label column md={1} className='offset-md-4'>PO:</Form.Label>
                        <Col md={2}>
                            <Form.Control type="text" maxLength="15" onChange={event => setOrderInfo({...orderInfo, po: event.target.value})} value={orderInfo.po} />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column md={1}>Shipping:</Form.Label>
                        <Form.Group as={Col} md={4}>
                            <Form.Select onChange={event => setOrderInfo({...orderInfo, shipping: event.target.value})} defaultValue="Shipping">
                                <option value="Pickup">Pickup</option>
                                <option value="Shipping">Shipping</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <div className="uploadFile">
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Form.Group className="ml-2 mb-2" as={Col} md={7}>
                                            <Form.Control id="uploadButton" type="file" name="file" onChange={event => handleOnChange(event)} />
                                        </Form.Group>
                                        <Col className="mb-2 mr-2 text-align-right">
                                            <Button id="submitButton" variant="warning" type="submit" name="submit" value="Submit">Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <Form.Text id="uploadNote" muted>Upload a xlsx(excel) or csv(comma separated values) file to upload a premade order and submit. Follow the template provided. <strong>Note: There can be only 2 columns with a max of 200 items. The first line of the file is not read.</strong></Form.Text>
                                <a id="uploadTemplate" href={downloadTemplate} download="templateOrder.xlsx">Download Template</a>
                            </div>
                        </Col>
                        <div className='col-md-4 offset-md-3'>
                            <Notes updateNotes={updateNotes} />
                        </div>
                    </Row>
                </div>
                {isDup && <p>There duplicate items in the table</p>}
                <div className="divTable mb-3">
                    <Table key={instanceKey} striped>
                        <OrderLineTableHeaders />
                        <tbody>
                            {row}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="text-align-right" colSpan="6" id="grandTotal">Total:</td>
                                <td className="tot"><span id="priceGrandTotal">${Number(grandTotal).toFixed(2)}</span></td>
                            </tr>
                        </tfoot>
                    </Table>
                    <div className='button-left ml-3'>
                        <Button variant='secondary' className="mb-3" type="button" onClick={emptyTable}>Empty Order</Button>{' '}
                        <Button variant='secondary' className="mb-3" type="button" onClick={window.print}>Print</Button>{' '}
                        <Button variant='secondary' className="mb-3" type="button" onClick={checkNumRows}>Add 5 Lines</Button>{' '}
                        <Button variant='secondary' className="mb-3" type="button" onClick={postChange}>Save Draft</Button>
                    </div>
                </div>
                <div className="text-align-center">
                    <Button variant='warning' ref={previewButton} onClick={previewOrder}>
                        <Link id="previewButton" to="/preview-order">Preview Draft</Link>
                    </Button>
                </div>
            </div>
        </Container>
    )
}

export default OrderDraft;
