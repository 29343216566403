/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: August 19th, 2020
 * 
 *  Description: The component that will be used to allow the user to view thier order before ordering
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Navigate, useNavigate } from 'react-router-dom';
import { table_headers } from "../../../context/orderModels.js";
import { postOrderApiUrl, draftApiURL, getCouponId } from "../../../context/addresses.js";
import Loading from '../../../context/loading.js';
import { useAuth } from '../../../context/auth.js';
import './styles/preview-order.css';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';

function PreviewOrder() {
    let auth = useAuth();
    let navigate = useNavigate();
    const order = JSON.parse(localStorage.getItem("orderDraft"));
    const [coupon, setCoupon] = useState();
    const [isPromotion, setIsPromotion] = useState('');
    const [isLoading, setIsLoading] = useState(false); // If loading
    const [isError, setIsError] = useState(false); // If there is an error
    const [deleteCheck, setDeleteCheck] = useState(false);
    let promotionCheckRef = useRef();
    let submitButtonRef = useRef();
    const params = { "username": order.user, "custom_po": order.po, "ship_method": order.shipping, "notes": [order.myNotes, order.maxNotes], "coupon": [coupon], "items": order.table, status: isPromotion };

    useEffect(() => {
        document.title = 'Preview Order - Max Advanced Brakes Wholesale';
    }, []);

    // Delete the draft after it has been submitted
    async function handleDraftDelete() {
        setIsError(false);
        try {
            await axios.delete(`${draftApiURL}/${order.draftNum}`, { Session: {}, withCredentials: true });
            setDeleteCheck(true);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }

            console.log("handleDraftDelete() failed: " + error.message);
            setIsError(true);
            alert("There was an issue with delete")
        }
        setIsLoading(false);
        submitButtonRef.current.disabled = true;
    }

    // Post draft
    async function handleOrderSubmit() {
        setIsError(false);
        setIsLoading(true);
        try {
            const response = await axios.post(postOrderApiUrl, qs.stringify(params), { Session: {}, withCredentials: true });
            
            if (response.data === 'OK') {
                alert("Order Placed");
                handleDraftDelete();
            } else {
                setIsLoading(false);
                submitButtonRef.current.disabled = false;
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }

            console.log("handleOrderSubmit() failed: " + error.message);
            setIsError(true);
            alert("There was an error placing the order");
            setIsLoading(false);
            submitButtonRef.current.disabled = false;
        }
    }

    // On button press get coupon
    async function getCoupon() {
        try {
            const response = await axios.get(`${getCouponId}${coupon}`, { Session: {}, withCredentials: true });
            Object.defineProperty(response.data, 'couponcode', {
                value: coupon
            });

            setCoupon(response.data);
        } catch(error) {
            if (error.response && error.repsonse && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }
            
            console.log("getCouponCode failed: " + error.message);
        }
    }

    function handleCheckPromotion() {
        if (promotionCheckRef.current.checked) {
            setIsPromotion('promotion');
        } else {
            setIsPromotion('');
        }
    }

    // Makes the headers for the table
    const OrderLineTableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(table_headers).map(([_key, value], index) => {
                        return <th key={index}>{value}</th>;
                    })}
                </tr>
            </thead>
        )
    };

    function goBack() {
        navigate(-1);
    }

    return (
        <>
            {deleteCheck ? <Navigate to="/new-order" /> : null}
            <div id="preview" className="page">
                <Container>
                    <h1>Preview Order</h1>
                    <Table id="preview-table" className='table' striped>
                        <OrderLineTableHeaders />
                        <tbody>
                            {(isLoading) ? (
                                <tr><td><Loading /></td></tr>
                            ) : (
                                    order.table.map((item, index) => {
                                        return (
                                            (item === null || item.pcode === "" || (item.qty === 0 || item.qty === "0")) ? (null) : (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.product}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{item.dis}</td>
                                                    <td>${item.pri}</td>
                                                    <td>${item.tot.toFixed(2)}</td>
                                                </tr>
                                            )
                                        )
                                    }
                                )
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan='3'>Total</td>
                                <td>{order.table.map((item) => Number(item.qty)).reduce((acc, cur) => Number(acc) + Number(cur))}</td>
                            </tr>
                            <tr>
                                <td className="text-align-right" colSpan="6">Sub Total:</td>
                                <td>${order.grandTotal.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="text-align-right" colSpan="6">Tax:</td>
                                <td>${(auth.user.taxrate * order.grandTotal).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="text-align-right" colSpan="6">Total:</td>
                                <td>${((auth.user.taxrate * order.grandTotal) + order.grandTotal).toFixed(2)}</td>
                            </tr>
                            {(coupon && coupon.discount) ? ( 
                                <tr>
                                    <td colSpan="2">Discount:</td>
                                    <td>$
                                        {(((auth.user.taxrate * order.grandTotal) + order.grandTotal) - ((auth.user.taxrate * order.grandTotal) + order.grandTotal) * (coupon.discount / 100)).toFixed(2)}</td>
                                </tr>
                            ) : (null)}
                        </tfoot>
                    </Table>
                    <Row className="couponWrapper">
                        <Form.Label column md={1} className="coupon">Coupon:</Form.Label>
                        <Form.Group as={Col} md={2} controlId="coupon">
                            <Form.Control type="text" onChange={e => setCoupon(e.target.value)} />
                        </Form.Group>
                        <Col>
                            <Button variant='secondary' type="button" onClick={getCoupon}>Check Coupon</Button>
                        </Col>
                        {(auth.user.access !== 5) ? (
                            <Col className="text-align-right">
                                <Form.Check ref={promotionCheckRef} reverse type="checkbox" label="Promotion Order" onClick={handleCheckPromotion} />
                            </Col>
                        ) : (null)}
                    </Row>
                    {(isLoading) ? (<Loading />) : (
                        <Row>
                            <Col>
                                <Button className="block-level" variant='secondary' onClick={goBack}>Back</Button>
                            </Col>
                            <Col>
                                <Button ref={submitButtonRef} className="block-level" variant="warning" onClick={handleOrderSubmit}>Submit</Button>
                            </Col>
                        </Row>
                    )}
                    {isError && <div>There was an error submitting your order</div>}
                </Container>
            </div>
        </>
    )
}

export default PreviewOrder;
