/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date Created: July 22th, 2020
 * 
 *  Description: Component used to allow access to route based on access level
 * 
 *****************************************************************************************************/

import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from "./context/auth";

function PrivateRoute({ children }) {
    const auth = useAuth();
    let location = useLocation();
    const adminOnly = ["/accounts", "/accounts/new-user", "/coupon", "/promotion", "/global-settings", "/unshipped"];
    const limitedAdmin = [/*"/coupon", "/promotion",*/ "/global-settings"];

    if (!auth.user) return <Navigate to="/login" state={{ from: location }} replace />;
                    
    // Top admin level
    if (auth.user.status === "OK" && auth.user.access === 1) return children;
  
    // Second level admin
    if (auth.user.status === "OK" && auth.user.access === 3) {
        if (!limitedAdmin.includes(location.pathname)) {
            return children;
        } else {
            return <Navigate to="/notAuhtorized" />;
        }
    }

    // Customer
    if (auth.user.status === "OK" && auth.user.access === 5) {
        if (!adminOnly.includes(location.pathname)) {
            return children;
        } else {
            return <Navigate to="/notAuhtorized" />;
        }
    }

    if (auth.user.access !== 1 && auth.user.access !== 3 && auth.user.access !== 5) sessionStorage.clear();

    return <Navigate to="/login" state={{ from: location }} replace />;
}

export default PrivateRoute;
