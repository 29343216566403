/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The terms Logins and Cookies information
* 
*****************************************************************************************************/

 
function LoginsAndCookies() {
    return (
        <>
            <div className="terms-header">
                <h3>Logins And Cookies</h3>
            </div>
            <div className="terms-content row">
                <div className="terms-columns">
                    {/* <h6>Logins</h6>
                    <p>You can login manually or automatically. To manually login, type your username and password and click "Log In." You must enable your web browser to accept cookies to automatically login. If your web browser accepts cookies, the MaxBrakes.prozone website will recognize you and keep you logged in, and allow you to use our personalized services without logging in again for up to 24 hours. You may manually log out at any time by selecting "Log out" in the dropdown in the upper right area of the page,.</p>
                    <h6>Cookies</h6>
                    <p>To take advantage of many features of the MaxBrakes.com/prozone web site, you must first enable cookies in your web browser. A cookie is a small piece of information stored with your browser that helps web sites and multiple servers recognize you. In the case of the MaxBrakes.com/prozone, your cookie file allows you to surf all over our site without having to login every time you go to a different personalized service.</p>
                    <p>If cookies are not enabled on your computer when you visit MaxBrakes.com/prozone, you will need to login to MaxBrakes.com/prozone every time you visited the site.</p> */}
                </div>
            </div>
        </>
    )
}
 
 export default LoginsAndCookies;