/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: August 4th, 2020
 * 
 *  Description: The component shown when the page the user is trying to access does not exist
 * 
 *****************************************************************************************************/

import React from "react";

function NotFound() {
    return (
        <div id="not-found" className="page">
            <div className="container-page">
                <h1>Page Not Found 404</h1>
            </div>
        </div>
    )
}

export default NotFound;
