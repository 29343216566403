/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: The component that will be shown as the home page that the user sees when they login
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from "react";
import axios from 'axios';
import qs from 'qs';
import { Navigate } from "react-router-dom";
import './styles/home.css';
import { useAuth } from '../../../context/auth';
import { ordersApiURL, postPriceListApiURL } from '../../../context/addresses.js';
import Loading from '../../../context/loading.js';
import { home_table_headers } from "../../../context/orderModels.js";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RowItem from "./components/RowItem.js";

function Home() {
    const [isLoading, setIsLoading] = useState(false); // Check for loading
    const [query, setQuery] = useState(""); // quick search an item
    const [products, setProducts] = useState([]); // products from search
    const exisitingProducts = JSON.parse(localStorage.getItem("productId")); // check if there are already orders from e-cat
    const [product, setProduct] = useState((exisitingProducts) ? (exisitingProducts) : ([])); // add products to an array
    const [proId, setProId] = useState(""); // Use this to show that the query the user used did not find anything
    const order = { "ONZ": 1, "OCG": 2, "SCB": 3, "SNZ": 4, "CNZ": 5, "CDM": 6, "CDP": 7, "AFN": 8, "OCB": 9, "MD": 10,"TSU":11,"NCG":12 }; // Order for which items are placed on the table
    const [errorStatus, setErrorStatus] = useState();
    let auth = useAuth(); // Get the user session info
    let pattern = /ONZ|OCG|SCB|SNZ|CNZ|CDM|CDP|TSU|AFN|MD|8100|9100|9200|NCG|WHB/;

    useEffect(() => {
        document.title = 'Home - Max Advanced Brakes Wholesale';
    }, []);

    // Quick search api
    async function getProduct(event) {
        event.preventDefault();
        setIsLoading(true);
        setProId("");
        try {
            const response = await axios.get(ordersApiURL + query, { Session: {}, withCredentials: true });

            // concat the two arrays
            let productArr = response.data.int.concat(response.data.sub);

            // Filter productArr for duplicate values
            const removeOrFilterProducts = productArr.filter((item, index, arr) => {
                return arr.map(itemObj => itemObj.n).indexOf(item.n) === index && item.n.match(pattern) !== null;
            });

            productArr = removeOrFilterProducts;

            let newArr = { username: "", data: [] };

            // Get all product numbers and store them in newArr.data
            newArr.data = productArr.map(item => item.n);

            let prices = [];

            // Check that the array has items in it
            if (productArr.length !== 0 && productArr.length !== undefined && productArr.length !== null) {

                // Get the prices
                prices = await axios.post(postPriceListApiURL, qs.stringify(newArr), { Session: {}, withCredentials: true });
            
                // Find the product number that match the prices and add it to product info
                for (let i of productArr) {
                    let x = prices.data.find(item => item.product.toUpperCase() === i.n.toUpperCase());
                    x = (x.status === "OK") ? (x.price) : (0);
                    let productLine = i.n.match(pattern);
                    Object.defineProperties(i, { 
                        'price': { value: Number(x) },
                        'order': { value: order[productLine] }
                    });
                }

                // Sort the products 
                productArr = productArr.sort((a, b) => {
                    return a.order - b.order;
                })
            } else {
                console.log("There are no prices")
            }
            
            setProducts(productArr);
            if (productArr.length === 0) {
                setProId(query);
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("getProduct() failed: " + error.message);
        }

        setIsLoading(false);
    }

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(home_table_headers).map(([_key, value], index) => {
                        return <th key={index}>{value}</th>
                    })} 
                </tr>
            </thead>
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="home-page" className="page">
            <Container>
                <div id="quickSearchBar" className="mb-3">
                    <h1>Hello, {auth.user.username}!</h1>
                    <Form onSubmit={(event) => getProduct(event)}>
                        <Row>
                            <Col md={3}>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                        </svg>
                                    </span>
                                    <Form.Control type="text" defaultValue={query} onChange={e => setQuery(e.target.value)} id="query" />
                                </InputGroup>
                            </Col>
                            <Col md={1}>
                                <Button variant="warning" type="submit">Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {(isLoading) ? (<Loading />) : (null)}
                {(products.length !== 0) ? (
                    <Table striped responsive='sm'>
                        {TableHeaders()}
                        <tbody>
                            {products.map((item, index) =>
                                <RowItem item={item} index={index} product={product} setProduct={setProduct} />
                            )}
                        </tbody>
                    </Table>
                ) : (null)}
                {(proId !== "" && products.length === 0) ? (<p>Product {proId} was not found.</p>) : (null)}
                <hr className="background-color-gold" />
            </Container>
        </div>
    );
}

export default Home;
