/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: November 20th, 2020
 * 
 *  Description: Component that will be used to check interchange 
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from "react";
import axios from 'axios';
import './styles/interchange.css';
import Loading from '../../../context/loading.js';
import { getInterchange } from "../../../context/addresses.js";
import { interchange_table_headers } from "../../../context/orderModels.js";
import { Navigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import useSortableData from "../../sortTable/useSortableData";

function Interchange() {
    const [isLoading, setIsLoading] = useState(false); // Check for loading
    const [query, setQuery] = useState(""); // quick search an item
    const [products, setProducts] = useState([]); // products from search
    const [method, setMethod] = useState("contains"); // search method
    const [proId, setProId] = useState(""); // Shows the query when not found
    const [errorStatus, setErrorStatus] = useState();
    const { items, requestSort } = useSortableData(products);
    
    useEffect(() => {
        document.title = 'Interchange - Max Advanced Brakes Wholesale';
    }, []);

    // Get the interchange products based on the query and method of search
    async function getProductInterchange(event) {
        event.preventDefault();
        setIsLoading(true);
        setProducts([]);
        
        try {
            const response = await axios.get(`${getInterchange}${query}/${method}`, { Session: {}, withCredentials: true });
            
            if (response.data.length !== 0) {
                response.data = response.data.flatMap(item => {
                    // Get the max num
                    let temp = item.Max; 

                    let tempQuery = query;
                    if (method === "begins") tempQuery = `^${tempQuery}`; 
                    else if (method === "exact") tempQuery = `^${tempQuery}$`; 
                    else if (method === "ends") tempQuery = `${tempQuery}$`;
                    else tempQuery = `${tempQuery}*`;

                    return Object.entries(item).map((key) => {
                        let queryText = null;
                        if (key[1] !== null && key[1].match(tempQuery)) queryText = key[1];

                        // make object that shows query, brand name, max num
                        return { query: queryText, brand_name: key[0], maxnum: temp };
                    })
                })
                setProducts(response.data);
            }

            setProId(query);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not logged in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("getProductInterchange() failed: " + error.message);
        }
        setIsLoading(false);
    }

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(interchange_table_headers).map(([key, value]) => {
                        return <th key={key} className={"sort"} onClick={() => requestSort(value)}>{key}</th>
                    })} 
                </tr>
            </thead>
        )
    }

    // Display interchange table
    function interchangeTable() {
        if (items.length === 0) return;

        return (
            <Table id="quickSearchTable" striped responsive="sm">
                {TableHeaders()}
                <tbody>
                    {(items.length !== 0) ? (
                        items.map((product, index) => {
                            return (
                                (product.query !== null) ? (
                                    <tr key={`interchange-${index}`}>
                                        <td>{product.query}</td>
                                        <td>{product.brand_name}</td>
                                        <td>{product.maxnum}</td>
                                        <td>Disc Brake Rotor</td>
                                    </tr>
                                ) : (null)
                            )
                        })
                    ) : (null)}
                </tbody>
            </Table>
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="interchange-page" className="page">
            <Container>
                <div id="quickSearchBar">
                    <h1>Interchange</h1>
                    <Form className="mb-3" onSubmit={(event) => getProductInterchange(event)}>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputGroup.Text>Part Number:</InputGroup.Text>
                                    <Form.Control type="text" defaultValue={query} minLength="3" onChange={e => setQuery(e.target.value)} />
                                </InputGroup>
                            </Col>
                            <Form.Group as={Col} md={4}>
                                <Form.Select aria-label="Default select" defaultValue={method} onChange={e => setMethod(e.target.value)}>
                                    <option value="exact">Exact</option>
                                    <option value="begins">Begins with</option>
                                    <option value="contains">Contains</option>
                                    <option value="ends">Ends with</option>
                                </Form.Select>
                            </Form.Group>
                            <Col>
                                <Button variant="warning" type="submit">Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <hr />
                {(proId !== "" && items.length === 0) ? (<p>Product {proId} was not found.</p>) : (null)}
                {(isLoading) ? (<Loading />) : (interchangeTable())}
            </Container>
        </div>
    );
}

export default Interchange;
