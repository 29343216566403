/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 9th, 2021
* 
*  Description: The terms and conditions
* 
*****************************************************************************************************/

function TermsAndConditions() {
    return (
        <>
            <div className="terms-header">
                <h3>Terms And Conditions</h3>
            </div>
            <div className="terms-content row">
                <div className="terms-columns">
                    <p>If you (the User) places an order through www.maxbrakes.com (“Web Site”), or any other website or app in which we present these Terms (referred to as the “Platform”), upon confirmation that such order is accepted, a contract of sale will be executed between you and Max Advanced Brakes, a privately owned business formed under the laws of Canada.
                    <br /><br />
                    The Platform is owned and operated by Max Advanced Brakes. If you choose to continue to use or access this Platform after having the opportunity to read these Terms, you recognize that Max Advanced Brakes has provided valuable consideration by offering this Platform free of charge, and in exchange for that valuable consideration, you agree to the Terms hereof. If you do not agree to these Terms, please do not use the Platform and exit immediately.
                    <br /><br />
                    We reserve the right to modify or amend the Terms from time to time without notice, subject to applicable laws. Your continued use of our Platform (as applicable) following the posting of changes to these terms will mean you accept those changes. Additional terms and conditions may apply to the purchase of products, such as our shipping and return policies. By using this Platform, you agree to such terms and conditions, as well as these Terms and our Privacy Policy (referred to as the “Agreement”)</p>
                    <h5>1.	Copyright and Trademarks</h5>
                    <p>All rights in the content featured or displayed on or through the Platform, including, but not limited to, logos, icons, trademarks, text, graphics, photographs, images, moving images, sound, illustrations, software, and other information (“Content”) are and remain owned by Max Advanced Brakes. its licensors or its content providers, as applicable. Except as may be otherwise indicated within the Platform, you are authorized to view, play, print and download Content found on the Platform for personal, informational, and non-commercial purposes only. You may not modify any of the Content and you may not copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any Content. You may not re-use any Content without first obtaining the written consent of Max Advanced Brakes. The use of any Content on any other website or networked computer environment is prohibited. You may not remove any copyright, trademark or other proprietary notices from Content found on the Platform.</p>
                    <h5>2.	Products, Content, and Specification</h5>
                    <p>All elements of the Platform including, but not limited to, the general design and the Content, may be protected by copyright, moral rights, database rights, trade mark and other laws relating to intellectual property or other rights. Except as explicitly permitted under these Terms or another agreement with Max Advanced Brakes, no portion or element of the Platform or its Content may be copied or retransmitted via any means. The Platform, its Content and all related rights shall remain the exclusive property of Max Advanced Brakes, its affiliated companies, or its content providers, as applicable, unless otherwise expressly agreed. All such rights are reserved.
                    <br /><br />
                    Content on this Platform may include images of people working on their vehicle. Please be aware that we are not familiar with your individual physical characteristics and health. Before you begin an installation, it is your responsibility to obtain all information necessary to perform the task. If you are uncertain of the task for any reason, please seek assistance from a licenced mechanic. You assume all inherent risks when you engage in self-installations.</p>
                    <h5>3.	Purchase & Shipping Terms</h5>
                    <p>You may only purchase or order items for personal, non-commercial purposes. When an order is placed, it will be shipping to an address designated by the purchaser as long as that shipping address is compliant with the shipping restrictions contained on this Platform. Items purchased on this Platform cannot be delivered to any address outside of Canada and the U.S. Further, all purchases from this Platform are made pursuant to a shipment contract. As a result, risk of loss and title for items purchased from this Platform pass to you upon delivery of the items to the carrier. You are responsible for filing any claims with carriers for damaged and/or lost shipments.
                    <br /><br />
                    Max Advanced Brakes, acting reasonably and in good faith and in accordance with applicable law, reserves the right to (a) limit the quantities of items you may purchase, and (b) reject, correct, cancel or refuse your order, if (i) we believe you have breached the Terms; (ii) we are unable to verify or authenticate any information you provide to us; (iii) an unauthorized or non-sanctioned discount code was used on the order (as determined by Max Advanced Brakes, acting reasonably), (iv) we believe that your actions may be improper, fraudulent, or otherwise cause financial loss or legal liability for you, our users or us, (v) if Product(s) are shown on the Platform but are not or no longer available or (vi) if shipping restrictions apply to a Product.
                    <br /><br />
                    In order to help protect you and Max Advanced Brakes from potentially fraudulent transactions, Max Advanced Brakes may provide your transaction-related information to third party organizations to perform an address verification, in accordance with the terms of our Privacy Policy. This address verification is to help ensure that the "bill to" address provided matches your credit card address. In the event that Max Advanced Brakes determines that it cannot (or is unable to) to ship an item, you will be notified by email and any amount charged to your credit card will be refunded.</p>
                    <h5>4.	Accuracy of Information</h5>
                    <p>We attempt to ensure that information on this Platform is complete, accurate and current. Despite our efforts, the information on this Platform may occasionally be inaccurate, incomplete or out of date.  We make no representation as to the completeness, accuracy or currency of any information on this Platform. For example, products included on this Platform may be unavailable, may have different attributes than those listed, or may actually carry a different price than that stated on this Platform. In addition, we may make changes in information about price and availability without notice. While it is our practice to confirm orders by email, the receipt of an email order confirmation does not constitute our acceptance of an order or our confirmation of an offer to sell a product or service. We reserve the right, without prior notice, to limit the order quantity on any product or service and/or to refuse service to any customer. We also may require verification of information prior to the acceptance and/or shipment of any order.</p>
                    <h5>5.	Third Party Links</h5>
                    <p>From time to time, this Platform may contain links to Platforms that are not owned, operated or controlled by Max Advanced Brakes or its affiliates. All such links are provided solely as a convenience to you. If you use these links, you will leave this Platform. Neither we nor any of our respective affiliates are responsible for any content, materials or other information located on or accessible from any other Platform. Neither we nor any of our respective affiliates endorse, guarantee, or make any representations or warranties regarding any other Platforms, or any content, materials or other information located or accessible from any other Platforms, or the results that you may obtain from using any other Platforms. If you decide to access any other Platforms linked to or from this Platform, you do so entirely at your own risk.
                    <br /><br />
                    Please note that these other sites may send their own cookies to users, collect data or solicit personal information, and you are therefore advised to check the terms of use, privacy policies, or any other terms and conditions on those sites prior to using them.</p>
                    <h5>6.	Unauthorized Use of Computer System</h5>
                    <p>You are prohibited from posting or sending any unlawful, threatening, defamatory, libelous, obscene, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense or give rise to civil liability, or otherwise violate any law. Such violations may subject the sender and his or her agents to civil and criminal penalties. You further understand and agree that sending unsolicited email advertisements to any user of the Platform or the Platform or through Voice computer systems is expressly prohibited by these Terms. Any such unauthorized use of our computer systems is a violation of these Terms and applicable “anti-spam” laws. In addition to any remedies that we may have at law or in equity, if we determine, in our sole discretion, that you have violated or are likely to violate the foregoing prohibitions, we may take any action we deem necessary to cure or prevent the violation, including without limitation, the immediate removal of the related materials from this Platform. We will fully cooperate with any law enforcement authorities or court order or subpoena requesting or directing us to disclose the identity of anyone posting such materials.</p>
                    <h5>7.	Account Security</h5>
                    <p>You are entirely responsible for the security and confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to immediately notify us of any unauthorized use of your account or any other breach of security of which you become aware. You are responsible for taking precautions and providing security measures best suited for your situation and intended use of the Services and Platform.</p>
                    <h5>8.	Fees</h5>
                    <p>For all charges for any products and services sold on the Platform, Max Advanced Brakes will bill your credit card or alternative payment method offered by Max Advanced Brakes, as applicable. In the event legal action is necessary to collect on balances due, you agree to reimburse Max Advanced Brakes for all expenses incurred to recover sums due, including attorneys' fees and other legal expenses. You are responsible for purchase of, and payment of charges for, all Internet access services and telecommunications services needed for use of this Platform.</p>
                    <h5>9.	Access and Interference / No “BOT” Use</h5>
                    <p>You agree that you will not without the prior written permission of Max Advanced Brakes, use any computer code, data mining software, “robot”, “bot”, “spider”, “scraper” or other automatic device, or program, algorithm or methodology having similar processes or functionality, or any manual process to: (i) monitor or copy any of the Software, web pages, data or Content found on this Platform or accessed through this Platform; (ii) engage in illegal activities pertaining to methods of credit card fraud; or (iii) place orders for or purchase any Max Advanced Brakes product(s). You agree that you will not engage in the mass downloading of files from this Platform; use the computer processing power of this Platform for purposes other than those permitted; or flood this Platform with electronic traffic designed to slow or stop its operation. You agree that you will not establish links to or from other websites to this Platform without the prior written consent of Max Advanced Brakes. Max Advanced Brakes reserves the right, in its sole and absolute discretion, to cancel orders placed by and/or block you or other users of the Platform who engage in any activity which Max Advanced Brakes believes is not in accordance with these Terms.</p>
                    <h5>10.	Limitation of Liability </h5>
                    <p>Your use of the Platform is at your own risk. You agree that our sole obligation to you is to provide the Platform “as is.” Except as prohibited by applicable law, neither Max Advanced Brakes nor any of its employees, officers, directors nor any of its agents or any other party involved in creating, producing or delivering the Platform shall be liable to you or to any third party for your use of, or the inability to use, the Platform and its Content except in cases of (a) gross negligence, recklessness, or an act of knowing or intentional willful misconduct; or (b) a violation of a consumer protection statute in connection with the Platform.
                    <br /><br />
                    Except as prohibited by applicable law, in no event will Max Advanced Brakes or any of their respective officers, directors, employees, affiliates, agents, successors, nor any party involved in the creation, production or transmission of this Platform, be liable to you or anyone else for any indirect, special, punitive, incidental or consequential damages (including, without limitation, those resulting from lost profits, lost data or business interruption) arising out of the use, inability to use, or the results of use of the Platform, any Platforms linked to this Platform, and its Content, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages.
                    <br /><br/>
                    In the event of any problem with this Platform or any Content, you agree that your sole remedy is to cease using this Platform. In the event of any problem with the products or services that you have purchased on or through this Platform, you agree that your sole remedy, if any, is from the manufacturer of such products or supplier of such services, in accordance with such manufacturer's or supplier's warranty, or to seek a return and refund for such product or services in accordance with the returns and refunds policies posted on this Platform. In no event shall Max Advanced Brakes’ total liability to you for all damages, losses, and causes of action whether in contract, tort (including, but not limited to, negligence), or otherwise exceed the greater of (a) fifty dollars ($50.00) or (b) the value of your purchase on the Platform. Please note that some jurisdictions may not allow the exclusion of certain damages under mandatory law, so some of the above exclusions and limitations may not apply to you.</p>
                    <h5>11.	Indemnity</h5>
                    <p>You agree to defend, indemnify and hold Max Advanced Brakes and any affiliated company or individual harmless from any and all liabilities, costs, and expenses, including reasonable attorneys' fees, related to any violation of these Terms by you or your authorized users, or in connection with the use of the Platform or the Internet or your purchases or the placement or transmission of any message or information on this Platform by you or your authorized users or your violation of any law or the rights of a third party.</p>
                    <h5>12.	Release</h5>
                    <p>In the event that you have a dispute with one or more other users of the Platform, you release Max Advanced Brakes (and its officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</p>
                    <h5>13.	Termination</h5>
                    <p>You or we may suspend or terminate your account or your use of this Platform at any time, for any reason or for no reason. You are personally liable for any orders placed or charges incurred through your account prior to termination. We may also block your access to our Platform in the event that (a) you breach these Terms of Service; (b) we are unable to verify or authenticate any information you provide to us; or (c) we believe that your actions may cause financial loss or legal liability for you, our users or us.</p>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions;