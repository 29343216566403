import { Link } from 'react-router-dom';
import { getStatementInvoice } from '../../../../context/addresses';
import download from '../../../../styles/pictures/download.png';
import paid from '../../../../styles/pictures/right.png';

function TableRow({item, index}) {
    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.statement_id}</td>
            <td>
                <a href={getStatementInvoice + item.statement_id} target="_blank" rel="noopener noreferrer">
                    <img width="20" height="22" src={download} alt="download" />
                </a>
            </td>
            <td>{(item.fully_paid === 1) ? (<img src={paid} alt="1" />) : (null)}</td>
            <td>{item.date_created}</td>
            <td style={{color: ((Number(item.balance) - Number(item.paid_amount)) > 0 && item.fully_paid !== 1) ? ("red") : ("black")}}>${(item.fully_paid === 1) ? ("0.00") : (Number(item.balance) - Number(item.paid_amount)).toFixed(2).replace(/-/, "")}</td>
            <td>{item.username}</td>
            <td>
                <Link to={`/statements/${item.statement_id}`}>Details</Link>
            </td>
        </tr>
    )
}

export default TableRow;