/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The terms for Return Policy
* 
*****************************************************************************************************/

function ReturnPolicy() {
    return (
        <>
            <div className="terms-header">
                <h3>Return Policy</h3>
            </div>
            <div className="terms-content row">
                <div className="terms-columns">
                    {/* <ul>
                        <li>
                            <h5>Gift Card Sales<br /></h5>
                            <p>All gift card sales are final and gift cards cannot be returned.</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h5>Customized/Configured Items</h5>
                            <p>All sales of items such as floor mats that have had logos, custom embroidery and or trim added are final and items cannot be returned.</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h5>How to return a product purchased from MaxBrakes.com:<br /></h5>
                            <h5>Product Return to an MaxBrakes store</h5>
                                <p>To return a product to an MaxBrakes store, simply take the product, in its original packaging and/or box, and your receipt to the store of your choice. You must drain all fluids, if any, from the product before returning it.* Also, if possible, take the credit card used to purchase the part and the store will immediately credit it for the price of the part. All returns to an MaxBrakes store are subject to the in-store return policy.</p>
                                <p>You will be reimbursed for shipping costs only if MaxBrakes.com made a shipping error or if the product was defective when shipped from MaxBrakes.com's Distribution Center.</p></li>
                            <li>
                                <h5>Product Return to MaxBrakes.com's Distribution Center</h5>
                                <p>To return a product to MaxBrakes.com, first drain all fluids, if any, from the product.* Then fill out the Return Form (Section B of your shipping invoice) and include it with the product in its original packaging and/or box.</p>		
                                <p>If you are shipping from Canada and if either (i) MaxBrakes.com made a shipping error or (ii) the product was defective when shipped from MaxBrakes.com's Distribution Center, contact MaxBrakes Customer Support at 877.200.2280 to request a return shipping label. Upon receipt of the shipping label, re-package the item(s) and attach the shipping label to the packaging for shipment back to the MaxBrakes Distribution Center.</p>
                                <p>If you are shipping from overseas or a Canadian Territory and if (i) MaxBrakes.com made a shipping error or (ii) the product was defective when shipped from MaxBrakes.com's Distribution Center, ship items back to MaxBrakes's Distribution Center using the most economical means available.  Include with your package, a copy of the receipt for your shipping charges. Upon receiving your returned item(s), MaxBrakes will process your return and reimburse you for your return shipping charges by issuing a check for the shipping dollar amount as it appears on the carrier pre-printed label and receipt. </p>
                                <p>Otherwise, MaxBrakes recommends using a carrier that provides package tracking to ensure your product gets back to MaxBrakes.com safely and quickly. Your credit card will be credited for the full price of the product.</p>
                                <p>In all cases listed above, you are responsible for verifying and complying with proper shipment requirements of the carrier.</p>
                                <p>NOT ALL PRODUCTS ARE ELIGIBLE FOR RETURNS. ALL SALES OF CUSTOMIZED ITEMS ARE FINAL AND NOT ELIGIBLE FOR RETURNS. SEE THE MAXBRAKES STORE'S IN-STORE RETURN POLICY FOR OTHER RESTRICTIONS.</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h5>How to return the core for a product purchased from MaxBrakes.com:<br /></h5>
                            <h5>Core return to an MaxBrakes store</h5>
                            <p>To return your product core to an MaxBrakes store, simply take the core and your receipt to any MaxBrakes store. You must drain all fluids, if any, out of the part before returning it.* If you have the box in which the new product was shipped, please use it to return the core. If you take the credit card used to purchase the new product, the store will immediately credit your credit card for the value of the returned core.</p>
                            <p>All returns to an MaxBrakes store are subject to the in-store return policy. </p></li>
                            
                        <li>
                            <h5>Core return to to MaxBrakes.com's Distribution Center</h5>
                            <p>To return your product core to MaxBrakes.com's Distribution Center for credit, first drain all fluids, if any, from the product.* Then fill out the Return Form (Section B of your shipping invoice) and include it with the product in the box in which your order was sent. Place the return shipping label on the outside of the box and ship it. You are responsible for verifying and complying with proper shipment requirements of the carrier.</p>
                            <p>MaxBrakes recommends using a carrier that provides package tracking to ensure your product gets back to MaxBrakes.com safely and quickly. You will not be reimbursed for shipping costs incurred in shipping the core to MaxBrakes.com's Distribution Center.</p>
                            <p>The credit card used for the purchase of the product giving rise to the core return will be credited with the core's value after inspection.</p>
                            <p>Your shipment or other return of any core or other product to MaxBrakes is considered a transfer of ownership, and MaxBrakes will be unable to return the core or other product to you.</p>
                            <p><em>*Please recycle or properly dispose of all fluids drained from returned part. The parts must be free of all flammable and hazardous fluids before shipment. Many MaxBrakes stores have a free recycling program. If you do not live near an MaxBrakes store or the MaxBrakes in your area does not have a recycling program, contact your local recycling agency for instructions on proper disposal.</em></p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h5>IN-STORE RETURN POLICY</h5>
                            <p>To return a product to an MaxBrakes store, return an item in its original condition and packaging, with receipt, within 90 days of the purchase date to request a refund. Return a defective item within the warranty period. Requests for refunds may be denied if the item has been used or installed.</p>
                            <p>MaxBrakes reserves the right to require a valid government-issued photo ID for all returns that will be recorded at the time of the return. Information from you ID will be retained in a company-wide database of customer return activity that MaxBrakes accepts the following IDs for returns: U.S. or Canadian Driver's License, U.S. State ID, Canadian Province ID, U.S. Military ID, Mexican Voter Registration Card, Passport, U.S. Laser Visa.</p>
                            <p>If you are returning an item(s) and you no longer have the receipt(s), contact MaxBrakes Customer Support at 877.200.2280 to request the transaction information for your order. The customer service representative will also explain the process for returning your item(s) to the nearest MaxBrakes store.</p>
                        </li>
                    </ul> */}
                </div>
            </div>
        </>
    )
}
 
 export default ReturnPolicy;