import Form from 'react-bootstrap/Form';

function SignUpInputOptional(props) {
    return (
        <fieldset>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control type={props.type} name={props.name} value={props.value} min={props.min} max={props.max} onChange={(e) => props.setNewUser({...props.newUser, [props.name]: e.target.value})} />
        </fieldset>
    )
}

export default SignUpInputOptional;