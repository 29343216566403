/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: The component that will show statements or account-payable based on access level
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import StatementDetails from "../statement-details/statement-details";
import { usersApiURL, userApiURL, statementsApiURL } from '../../../context/addresses';
import Loading from '../../../context/loading.js';
import { accRePay_table_headers } from "../../../context/orderModels.js";
import './styles/statements.css';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useSortableData from "../../sortTable/useSortableData";
import TableRow from "./components/TableRow";

export const StatementsDetails = () => { 
    let location = useLocation();
    return <StatementDetails statement={location.pathname.split('/')[2]} />;
}

function Statement() {
    let auth = useAuth(); // Get the current user data
    const [accounts, setAccounts] = useState([]); // Get the list of accounts for filter
    const [clear, setClear] = useState(false); // when the clear button is clicked
    const [isLoading, setIsLoading] = useState(false); // loading data
    const [isError, setIsError] = useState(false); // There was an error getting api
    const [errorStatus, setErrorStatus] = useState([]); // Get the error status
    const [username, setUsername] = useState(""); // The username used for filter
    const [user, setUser] = useState("");
    const [statements, setStatements] = useState([]);
    const { items, requestSort } = useSortableData(statements);

    useEffect(() => {
        document.title = 'Statements - Max Advanced Brakes Wholesale';
    }, []);

    useEffect(() => {
        if (auth.user.access !== 5) {
            const fetchAccount = async () => {
                setIsError(false);
                setIsLoading(true);

                try {
                    const response = await axios(`${usersApiURL}?perpage=200`, { Session: {}, withCredentials: true });
                
                    response.data = response.data.sort((a, b) => {
                        return ("" + a.company_name).localeCompare("" + b.company_name);
                    })
                    setAccounts(response.data);
                } catch(error) {
                    if (error.response && error.response.status === 401) {
                        console.log("User is not signed in: " + error.message);
                        setErrorStatus(error.response.status);
                    }
                        
                    console.log("fetchAccount() failed: " + error.message);
                    setIsError(true);
                }

                setIsLoading(false);
            }

            fetchAccount();
        }
    }, [auth]);

    useEffect(() => {
        if (auth.user.access === 5) {
            const fetchUserStatements = async () => {
                setIsError(false);
                setIsLoading(true);

                try {
                    const response = await axios.get(`${statementsApiURL}?username=${auth.user.username}`, { Session: {}, withCredentials: true });

                    setStatements(response.data);
                } catch(error) {
                    if (error.response && error.response.status === 401) {
                        console.log("User is not signed in: " + error.message);
                        setErrorStatus(error.response.status)
                    }
                        
                    console.log("fetchUserStatements() failed: " + error.message);
                    setIsError(true);
                }

                setIsLoading(false);
            }

            fetchUserStatements();
        }
    }, [auth.user.username])

    useEffect(() => {
        setClear(false);
        setUsername("");
        setUser("");
        setStatements([]);
        if (auth.user.access !== 5) document.getElementById('cusName').value = '';
    }, [clear, auth.user.access]);

    async function fetchUserStatementsByUsername() {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(statementsApiURL, { 
                params: {
                    username: username
                },
                Session: {},
                withCredentials: true 
            });
        
            setStatements(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("fetchUserStatementsByUsername() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function fetchUser() {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(userApiURL + username, { Session: {}, withCredentials: true })
        
            setUser(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("fetchUser() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    function handleSubmit(event) {
        event.preventDefault();
        fetchUserStatementsByUsername();
        fetchUser();
    }

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(accRePay_table_headers).map(([key, value], index) => {
                        return <th key={index} className={(value === "Date") ? ("sort") : ("")} onClick={() => requestSort(value)}>{key}</th>
                    })}
                </tr>
            </thead>
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="statements" className="page">
            <Container>
                {(auth.user.access !== 5) ? (<h1>Account Receivable</h1>) : (<h1>Account Payable</h1>)}
                {isError && <div>There was an error</div>}
                <Form onSubmit={(event) => handleSubmit(event)}>
                    {(auth.user.access !== 5) ? (
                        <Row>
                            <Form.Label column md={1}>Customer:</Form.Label>
                            <Form.Group as={Col} controlId="cusName">
                                <Form.Select id="cusName" placeholder="Customer Name" onChange={(e) => { setUsername(e.target.value); }}>
                                    <option value=""></option>
                                    {accounts.map((item, index) => <option value={item.username} key={index}>{item.company_name} ({item.firstname} {item.lastname})</option> )}
                                </Form.Select>
                            </Form.Group>
                            <Col>
                                <Button variant="warning" type="submit">Search</Button>{' '}
                                <Button variant="secondary" type="button" onClick={() => setClear(true)}>Clear</Button>   
                            </Col>
                        </Row>
                    ) : (null)}
                </Form>
                <Table className="orderInfo mt-3">
                    <tbody className="text-align-left">
                        <tr>
                            <th>Customer Id</th>
                            <td>{user.id}</td>
                        </tr>
                        <tr>
                            <th>Customer Name</th>
                            <td>{user.firstname} {user.lastname}</td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td>{user.address}, {user.city}, {user.state}, {user.country}</td>
                        </tr>
                        <tr>
                            <th>Postal Code</th>
                            <td>{user.zip}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="ar-table mb-3">
                    <Form.Label className="ml-3">A/R Term 10 - 2% 10, 30 Statement</Form.Label>
                    <Table>
                        <thead className="thead-primary">
                            <tr>
                                <th>1 - 30 Days</th>
                                <th>30 - 60 Days</th>
                                <th>60 - 90 Days</th>
                                <th>90 - 120 Days</th>
                                <th>120+ Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>$
                                    {(items && items.length > 0) ? (
                                        items.map(item => 
                                            (item.fully_paid !== 1 && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) < 30)) ? (
                                                Number(item.balance) - Number(item.paid_amount)
                                            ) : (0.00)
                                        ).reduce((acc, cur) => acc + cur).toFixed(2).replace(/-/, "")
                                    ) : ("0.00")}
                                </td>
                                <td>$
                                    {(items && items.length > 0) ? (
                                        items.map(item => 
                                            (item.fully_paid !== 1 && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) > 30) && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) < 60)) ? (
                                                Number(item.balance) - Number(item.paid_amount)
                                            ) : (0.00)
                                        ).reduce((acc, cur) => acc + cur).toFixed(2).replace(/-/, "")
                                    ) : ("0.00")}
                                </td>
                                <td>$
                                    {(items && items.length > 0) ? (
                                        items.map(item => 
                                            (item.fully_paid !== 1 && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) > 60) && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) < 90)) ? (
                                                Number(item.balance) - Number(item.paid_amount)
                                            ) : (0.00)
                                        ).reduce((acc, cur) => acc + cur).toFixed(2).replace(/-/, "")
                                    ) : ("0.00")}
                                </td>
                                <td>$
                                    {(items && items.length > 0) ? (
                                        items.map(item => 
                                            (item.fully_paid !== 1 && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) > 90) && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) < 120)) ? (
                                                Number(item.balance) - Number(item.paid_amount)
                                            ) : (0.00)
                                        ).reduce((acc, cur) => acc + cur).toFixed(2).replace(/-/, "")
                                    ) : ("0.00")}
                                </td>
                                <td>$
                                    {(items && items.length > 0) ? (
                                        items.map(item => 
                                            (item.fully_paid !== 1 && ((new Date(Date.now()).getTime() - new Date(item.date_created).getTime()) / (1000 * 60 * 60 * 24) > 120)) ? (
                                                Number(item.balance) - Number(item.paid_amount)
                                            ) : (0.00)
                                        ).reduce((acc, cur) => acc + cur).toFixed(2).replace(/-/, "")
                                    ) : ("0.00")}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Row>
                        <Form.Label className="ml-3" column>Deferred: $0.00</Form.Label>
                    </Row>
                    <Row>
                        <Form.Label className="ml-3" column>Total A/R Balance: $
                            {(items && items.length > 0) ? (
                                items.map(item => {
                                    return (
                                        (item.fully_paid !== 1) ? (
                                            Number(item.balance) - Number(item.paid_amount)
                                        ) : (0.00)
                                    )
                                }).reduce((acc, cur) => acc + cur).toFixed(2).replace(/-/, "")
                            ) : ("0.00")}
                        </Form.Label>
                    </Row>
                </div>
                <div className="statement-table">
                    <Table striped>
                        {TableHeaders()}
                        <tbody>
                            {((username !== "" || auth.user.access === 5) && items) ? (
                                (isLoading) ? (
                                    <tr><td><Loading /></td></tr>
                                ) : (items.map((item, index) => <TableRow item={item} index={index} />))
                            ) : (null)}
                        </tbody>
                    </Table>
                </div>
            </Container>
        </div>
    );
}

export default Statement;
