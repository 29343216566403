/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: The component that will show the e-catalouge
 * 
 *****************************************************************************************************/

import { useEffect } from "react";
import PrivateRoute from "../../../PrivateRoute";
import { GetAllYears, GetMakeByYear, GetModelByMake, GetCategoryByModel, GetProductByCategory } from './components';
import './styles/e-cat.css';
import { e_cat_table_headers } from "../../../context/orderModels.js";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup"
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";

// Data for make by year
const Year = () => {
    let location = useLocation();
    return <GetMakeByYear year={location.pathname.split('/')[2]} url={location.pathname} /> 
}

// Data for model by make
const Make = () => {
    let location = useLocation();
    return <GetModelByMake year={location.pathname.split('/')[2]} make={location.pathname.split('/')[3]} url={location.pathname} /> 
}

// Data for category by model 
const Model = () => {
    let location = useLocation();
    return <GetCategoryByModel year={location.pathname.split('/')[2]} make={location.pathname.split('/')[3]} model={location.pathname.split('/')[4]} url={location.pathname} />
}

// Data for product by category
const Category = () => {
    let location = useLocation();
    return <GetProductByCategory year={location.pathname.split('/')[2]} make={location.pathname.split('/')[3]} model={location.pathname.split('/')[4]} category={location.pathname.split('/')[5]} url={location.pathname} />
}

// table headers
function ECatCategoryRow() {
    return (
        <thead>
            <tr>
                {Object.entries(e_cat_table_headers).map(([_key, value], index) => {
                    return (
                        <th key={index} className={"col" + (index + 1)}>
                            <span>{value}</span>
                        </th>
                    )
                })}
            </tr>
        </thead>
    );
}

function ECat() {
    let location = useLocation();

    useEffect(() => {
        document.title = 'E-Catalogue - Max Advanced Brakes Wholesale';
    }, []);

    return (
        <div id="e-cat" className="page">
            <Container>
                <div id="table">
                    <Table className="catalog-filters">
                        <ECatCategoryRow />
                        <tbody>
                            <tr>
                                <td>
                                    <ListGroup id="year">
                                        <GetAllYears url={location.pathname} />
                                    </ListGroup>
                                </td>
                                <td>
                                    <ListGroup id="make">
                                        <Outlet />
                                        <Routes>
                                            <Route
                                                path=":year/*"
                                                element={
                                                    <PrivateRoute>
                                                        <Year />
                                                    </PrivateRoute>
                                                }
                                            />
                                        </Routes>
                                    </ListGroup>
                                </td>
                                <td>
                                    <ListGroup id="model">
                                        <Outlet />
                                        <Routes>
                                            <Route
                                                path=":year/:make/*"
                                                element={
                                                    <PrivateRoute>
                                                        <Make />
                                                    </PrivateRoute>
                                                }
                                            />
                                        </Routes>
                                    </ListGroup>
                                </td>
                                <td>
                                    <ListGroup id="category">
                                        <Outlet />
                                        <Routes>
                                            <Route
                                                path=":year/:make/:modal/*"
                                                element={
                                                    <PrivateRoute>
                                                        <Model />
                                                    </PrivateRoute>
                                                }
                                            />
                                        </Routes>
                                    </ListGroup>
                                </td>
                                <td>
                                    <ListGroup id="product">
                                        <Outlet />
                                        <Routes>
                                            <Route
                                                path=":year/:make/:modal/:category/*"
                                                element={
                                                    <PrivateRoute>
                                                        <Category />
                                                    </PrivateRoute>
                                                }
                                            />
                                        </Routes>
                                    </ListGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Container>
        </div>
    );
}

export default ECat;
