import { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { orderItemApiURL } from '../../../../context/addresses';
import Loading from '../../../../context/loading';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

const pattern = /ONZ|OCG|SNZ|SCB|AFN|CDM|CDP|MD|CNZ|8100|CASTROLE|CASTROLG|EVER|TIGER|MOBI1|BFD|LAURENTIDE|KLEENFLO|CERTIFIED|ABSORBENT|ACDELCO|DMGLOVES-M|DMGLOVES-L|ROTORCLOCK|EMZONE|9100001|9100002|9100005|9100006|9100007|NCG|WHB/i;

function TableRows({ username, rowid, rowProductArray, setRowProductArray, productsToReturnArray, setProductsToReturnArray, customer }) {
    const [query, setQuery] = useState(""); // query
    const [productLines, setProductLines] = useState([]); // Get query product lines
    const [usersOrderItems, setUsersOrderItems] = useState([]); // Results of query
    const [selectedProduct, setSelectedProduct] = useState(""); // Selected Product
    const [totalShipped, setTotalShipped] = useState(0); // Get the total shipped
    const [totalReturn, setTotalReturn] = useState(0); // Get the total returned
    const [returnInput, setReturnInput] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const selectRef = useRef(); // Ref to the select element
    let showmenu = productLines.length === 0 ? false : true;

    // Use query to get products
    async function searchOrderItems(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            setProductLines([]);
            setSelectedProduct('');
            setUsersOrderItems([]);
            setTotalShipped(0);
            setTotalReturn(0);

            let line = query.match(pattern);

            if (line === null) line = '';

            const response = await axios.get(orderItemApiURL, {
                params: {
                    username: (customer && customer !== '') ? (customer) : (username),
                    product: query,
                    line: line.toString()
                },
                Session: {},
                withCredentials: true
            });

            if (response.data.length === 0) {
                alert("You have no shipped items with that product code");
                setIsLoading(false);
                return;
            }

            // Check for duplicate items from other rows
            let newArr = response.data;
            newArr = newArr.filter((item) => {
                let temp = true;
                for (let key in rowProductArray) {
                    if (item.product === rowProductArray[key].product) temp = false;
                }
                if (!temp) return temp;
                return item;
            });

            // Get product lines
            let tempArr = [...new Set(newArr.map(item => item.product))];

            setProductLines(tempArr);
            setUsersOrderItems(newArr);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }
            
            console.log("searchorderItems failed: " + error.message);
        }
        setIsLoading(false);

        if (selectRef.current !== null && selectRef.current !== undefined && selectRef.current.nextElementSibling !== null && selectRef.current.nextElementSibling.firstElementChild !== null) {
            selectRef.current.nextElementSibling.firstElementChild.focus();
        }
    }

    // When the user selects a product
    function handleSelectProduct(product) {
        let tempArr = usersOrderItems;
        let total_shipped = 0;
        let total_return = 0;
        tempArr.map((item) => { 
            if (item.product === product) {
                total_shipped += item.qty_shipped;
                total_return += item.qty_returned;
            } 
        });

        setSelectedProduct(product);
        setTotalShipped(total_shipped);
        setTotalReturn(total_return);
        setRowProductArray([...rowProductArray, { id: rowid, product: product }]);
    }

    // Check if user is typing 
    useEffect(() => {
        const timeoutId = setTimeout(() => handleQuantityToReturn(returnInput), 1000);
        return () => clearTimeout(timeoutId);
    }, [returnInput]);

    // The return quantity will subtract from items that are the same as selected product
    // The value will be save
    function handleQuantityToReturn(e) {
        if (e === null) return;
        let value = Number(e.target.value); // Make sure that value is a number
        let tempReturn = productsToReturnArray; // Get array of items to be added to cart
        
        if (value > Number(e.target.max)) {
            value = Number(e.target.max);
        } else if (value < Number(e.target.min)) {
            value = Number(e.target.min);
        } else {
            console.log("")
        }
        
        // Check if row is already in array
        let tempCheck = tempReturn.findIndex((item) => {
            return item.rowid === rowid;
        });

        let count = value;
        let price = 0;
        let return_array = usersOrderItems.map((item) => {
            let tempClone = { ...item };
            if (tempClone.product === selectedProduct && tempClone.qty_shipped !== null && tempClone.qty_shipped !== 0 && count !== 0) {
                while(tempClone.qty_shipped !== 0 && count !== 0) {
                    price += Number(tempClone.price_discounted);
                    tempClone.qty_shipped -= 1;
                    count -= 1;
                }
            }
            return tempClone;
        });

        /* If product is alredy in array overwrite the data 
        If product is not in array push product*/
        if (tempCheck !== -1) {
            tempReturn[tempCheck] = { rowid: rowid, product: selectedProduct, query_array: usersOrderItems, return_array: return_array, return_qty: value, return_amount: price };
        } else {
            tempReturn.push({ rowid: rowid, product: selectedProduct, query_array: usersOrderItems, return_array: return_array, return_qty: value, return_amount: price });
        }

        setProductsToReturnArray(tempReturn);
    }

    return (
        <tr>
            <td>
                <Form onSubmit={(e) => searchOrderItems(e)}>
                    <Form.Control placeholder="eg. 3295" onChange={(e) => setQuery(e.target.value)} />
                </Form>
            </td>
            <td>
                {(isLoading) ? (<Loading />) : (
                    <Dropdown className={"panel-dropdown"}>
                        <Dropdown.Toggle ref={selectRef}>
                            {selectedProduct}
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showmenu}>
                            {productLines.map((item) =>
                                <Dropdown.Item onClick={() => handleSelectProduct(item)}>{item}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </td>
            <td>{(totalShipped !== 0) ? (totalShipped) : (0)}</td>
            <td>{(totalReturn !== 0) ? (totalReturn) : (0)}</td>
            <td>
                <Form.Control type="number" min="0" max={totalShipped} onChange={(e) => setReturnInput(e)} />
            </td>
        </tr>
    )
}

export default TableRows;