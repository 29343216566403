/**************************************************************************************
* Author: Adam Khan
* Date Created: November, 30th, 2020
*
* Description: The component that allow an admin to create an account for another user
*
***************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import { postNewUser } from '../../../context/addresses.js';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

function NewUser() {
    const [newUserInfo, setNewUserInfo] = useState({ username: "", password: "", confirmPassword: "", firstname: "", lastname: "", company_name: "", phone_primary: "", title: "", email: "", confirmEmail: "", active: 1, currentsupplier: "", partexp: 0, stockinv: 0, estimatedvol: 0, yearestablished: 0, state: "ON" }); // New user info
    const [terms, setTerms] = useState(false); // agree to the terms and conditions
    const [isErrorUserName, setIsErrorUserName] = useState(false); // When another user already has the username
    const [signUpCheck, setSignUPCheck] = useState(false); // When the post to sign up goes through

    useEffect(() => {
        document.title = 'New User - Max Advanced Brakes Wholesale';
    }, []);

    // account info to be sent to the server
    async function postSignUp(event) {
        event.preventDefault();
        setIsErrorUserName(false);

        if (newUserInfo.password === newUserInfo.confirmPassword && newUserInfo.email === newUserInfo.confirmEmail) {
            try {
                const response = await axios.post(postNewUser, qs.stringify(newUserInfo), { Session: {}, withCredentials: true });
                alert("Sign Up Request Sent");

                if (response.data === "OK") setSignUPCheck(true);
            } catch(error) {
                if (error.response && error.response.status === 401) console.log("User not signed in: " + error.message);
                console.log("postSignUp() failed: " + error.message);
                alert("Failed to send new user data");
            }
        } else {
            if (newUserInfo.password !== newUserInfo.confirmPassword || newUserInfo.email !== newUserInfo.confirmEmail) alert("Email or Paswsword do not match");
        }
    }

    return (
        <>
            {signUpCheck ? <Navigate to="/accounts" /> : null}
            <div id="newUser" className="page">
                <Container>
                    <h3>New User</h3>
                    <Form id="signUpForm" onSubmit={event => postSignUp(event)}>
                        <div id="accountUser">
                            <h5 className="mt-2">Account User Information</h5>
                            <hr />
                            <p>Required fields are indicated by <span>*</span></p>
                            <hr />
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control id="firstName" type="text" name="firstName" value={newUserInfo.firstName} onChange={e => setNewUserInfo({ ...newUserInfo, firstname: e.target.value })} required />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>    
                                    <Form.Control id="lastName" type="text" name="lastName" value={newUserInfo.lastName} onChange={e => setNewUserInfo({ ...newUserInfo, lastname: e.target.value })} required />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Company or Agency Legal Name<span className="text-danger">*</span></Form.Label>
                                    <Form.Control id="company" type="text" name="company" value={newUserInfo.company} onChange={e => setNewUserInfo({ ...newUserInfo, company_name: e.target.value })} required />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Business Phone <span className="text-danger">*</span></Form.Label>
                                    <Form.Control id="phone_primary" type="tel" name="phone_primary" value={newUserInfo.phone_primary} onChange={e => setNewUserInfo({ ...newUserInfo, phone_primary: e.target.value })} required />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Title <span className="text-danger">*</span></Form.Label>
                                    <Form.Control id="title" type="text" name="title" value={newUserInfo.title} onChange={e => setNewUserInfo({ ...newUserInfo, title: e.target.value })} required />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Province/State <span className="text-danger">*</span></Form.Label>    
                                    <Form.Control id="state" type="text" name="state" value={newUserInfo.state} onChange={e => setNewUserInfo({ ...newUserInfo, state: e.target.value })} required />
                                </Form.Group>
                            </Row>
                            <Row>
                                <div className="fieldset">
                                    <fieldset className="legendBorder">
                                        <legend>E-mail Address</legend>
                                        <Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>E-mail <span className="text-danger">*</span></Form.Label>
                                                <Form.Control id="email" type="email" name="email" value={newUserInfo.email} onChange={e => setNewUserInfo({ ...newUserInfo, email: e.target.value })} required />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Confirm E-mail <span className="text-danger">*</span></Form.Label>
                                                <Form.Control id="confirm-email" type="email" name="confirm-email" value={newUserInfo.confirmEmail} onChange={e => setNewUserInfo({ ...newUserInfo, confirmEmail: e.target.value })} required />
                                            </Form.Group>
                                        </Row>
                                    </fieldset>
                                </div>
                            </Row>
                        </div>
                        <div id="userAccessInfo">
                            <h5 className="mt-2">User Access Information</h5>
                            <hr />
                            <Row>
                                <div className="fieldset">
                                <fieldset className="legendBorder">
                                    <legend>User Name <span className="text-danger">*</span></legend>
                                    <Form.Group as={Col}>
                                        <Form.Label>Username:</Form.Label>
                                        <Form.Control id="username" type="username" name="username" value={newUserInfo.userName} onChange={e => setNewUserInfo({ ...newUserInfo, username: e.target.value })} required />
                                    </Form.Group>
                                    {isErrorUserName && <div>This username is already in use.</div>}
                                </fieldset>
                                </div>
                                <div className="fieldset">
                                    <fieldset className="legendBorder">
                                        <legend>Password <span className="text-danger">*</span></legend>
                                        <Form.Group as={Col}>
                                            <Form.Label>Password:</Form.Label>
                                            <Form.Control id="password" type="password" name="password" value={newUserInfo.password} onChange={e => setNewUserInfo({ ...newUserInfo, password: e.target.value })} required />
                                            
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Confirm Password:</Form.Label>
                                            <Form.Control id="confirm-password" type="password" name="confirm-password" value={newUserInfo.confirmPassword} onChange={e => setNewUserInfo({ ...newUserInfo, confirmPassword: e.target.value })} required />
                                        </Form.Group>
                                    </fieldset>
                                </div>
                            </Row>
                        </div>
                        <div id="agree">
                            <h5 className="mt-2">Agreement</h5>
                            <hr />
                            <Row>
                                <Form.Group as={Col} md={1} className="text-align-right">
                                    <Form.Check aria-label='terms' onChange={e => setTerms(e.target.value)} required />
                                </Form.Group>
                                <Form.Label column>I have read and agree to the <Link to="/terms/termsAndConditions">Terms & Conditions</Link> <span className="text-danger">*</span></Form.Label>
                                <Col className="text-align-center">
                                    <Button variant="warning" type="submit">Submit</Button>
                                </Col>
                            </Row>
                            <hr />
                            <p>Max Brakes respects your privacy and your concerns about safety. Read more about our security and our privacy policy for more information.</p>
                        </div>
                    </Form>
                    <div id="cancelButton">
                        <Link to="/accounts">Cancel</Link>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default NewUser;