import React, { useState, useEffect } from "react";
/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: Aug 3rd, 2020
 * 
 *  Description: A component that will ping to check if the user is still logged in
 * 
 *****************************************************************************************************/

import axios from "axios";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { pingApiURL } from '../../context/addresses.js';
import { useAuth } from "../../context/auth.js";

function IdleTimer() {
    let auth = useAuth()
    const [errorStatus, setErrorStatus] = useState([]);
    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(async () => {
            try {
                const response = await axios.get(pingApiURL, { Session: {}, withCredentials: true })
                setErrorStatus(response);
                if (auth === null) {
                    setErrorStatus(401);
                    auth.signout(() => navigate("/login"));
                }
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                } else {
                    console.log("pingApiUrl Api call failed: " + error.message);
                }
            }
        }, 5000)

        return () => clearInterval(timer);
    }, [auth, navigate])

    return (
        <>
            {(errorStatus === 401 && (location.pathname !== "/login" && location.pathname !== "/request-access" && location.pathname !== "/sign-up" && location.pathname !== "/password/reset" && (location.pathname.match(/\/password\/reset\/*/gi) && location.pathname.match(/\/password\/reset\/*/gi)[0] !== "/password/reset/"))) ? (
                <Navigate to="/login" />
            ) : (null)}
        </>
    )
}

export default IdleTimer;
