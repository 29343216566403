import Form from 'react-bootstrap/Form';

function UserDetails({ dataField, currentUserSetting, type, id, name, setUserMod, userMod, defaultVal, disabled }) {
    return (
        <>
            <td>{dataField}</td>
            <td>{currentUserSetting}</td>
            <td>
                <Form.Control type={type} id={id} name={name} onChange={e => setUserMod({...userMod, [name]: e.target.value})} defaultValue={defaultVal} disabled={(disabled !== null && disabled !== undefined) ? (disabled) : (false)} />
            </td>
        </>
    )
}

export default UserDetails;