import { useCallback, useEffect, useRef, useState } from "react";
import axios from 'axios';
import qs from 'qs';
import { Navigate } from 'react-router-dom';
import removeImg from '../../../../../styles/pictures/close.png';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { ordersApiURL, postPriceListApiURL } from '../../../../../context/addresses.js';
import getProductLineFromProduct from "./getProductLineFromProduct";
import Loading from "../../../../../context/loading";

// Makes the lines and their handlers
function TableRow({ id, proindex, desindex, qtyindex, auth, username, upBatch, updateGrandTotals, updateLineValues, updateDup, dup, grandTotals, lineValues }) {
    const [instanceKey, setInstanceKey] = useState(0); // When the remove button is pressed fields are emptied in the row
    const [query, setQuery] = useState(upBatch !== undefined ? upBatch.product : ""); // Item to search for
    const [products, setProducts] = useState([]); // object with two array of objects
    const [selectedProduct, setSelectedProduct] = useState(); // The selected product
    const [qty, setQty] = useState(upBatch !== undefined && Number(upBatch.qty) !== 0 ? upBatch.qty : 0); // Qty the user wants
    const [total, setTotal] = useState(0.00); // Total = qty * selectedProduct price
    const productSort = { "ONZ": 1, "OCG": 2, "SCB": 3, "SNZ": 4, "CNZ": 5, "CDM": 6, "CDP": 7, "AFN": 8, "Bearing": 9, "MD": 10, "Other": 11 }; // The order for which the items will show in dropdown
    const productMax = { "ONZ": auth.user.maxonz, "OCG": auth.user.maxocg, "SCB": auth.user.maxscb, "SNZ": auth.user.maxsnz, "AFN": auth.user.maxafn, "CDM": auth.user.maxcdm, "CDP": auth.user.maxcdp, "8100": auth.user.maxbearing, "CNZ": auth.user.maxcnz, "MD": auth.user.maxmd, "Other": auth.user.maxother,"TSU":auth.user.maxother ,"NCG":auth.user.maxncg};
    const [na, setNa] = useState(false); // The item is not available
    const [isLoading, setIsLoading] = useState(false); // Check if loading
    const [isError, setIsError] = useState(false); // Check if there was an error
    const [errorStatus, setErrorStatus] = useState(""); // Get Error Code
    const lineRow = useRef(); // ref for the line Row
    const inputPcode = useRef(); // ref to productId input field
    const inputProduct = useRef(); // ref to description input field
    const inputQty = useRef(); // ref to quantity input field
    const tdDis = useRef(); // ref to change color of availability
    let show_drop = query !== "" && selectedProduct !== "" ? false : true;

    const fetchProduct = useCallback(async () => {
        let newArr = { username: "", data: [] };
        setIsLoading(true);
        setIsError(false);
        setNa(false);

        try {
            // Make sure that values are set to default
            setProducts([]);
            setQty(0);
            setTotal(0);
            setSelectedProduct("");

            // Checks for empty query
            if (query === null || query === undefined || query === "") {
                setIsLoading(false);
                return;
            }
            
            // API call with query as the search value
            await axios.get(ordersApiURL + query, { Session: {} }).then(async (response) => {
                // Concat the two arrays
                let productsArr = response.data.int.concat(response.data.sub);

                // Checks if the array is empty
                if (checkProductsArrayLength(productsArr.length)) return;

                // Filter productsArr for duplicate values
                productsArr = productsArr.filter((item, index, arr) => {
                    return arr.map(itemObj => itemObj.n).indexOf(item.n) === index;
                });

                // Get the customer username if there is one
                newArr.username = username;
                
                // Get all product numbers and store them in newArr.data
                newArr.data = productsArr.map(item => item.n);
                
                // Get the prices
                await axios.post(postPriceListApiURL, qs.stringify(newArr), { Session: {}, withCredentials: true }).then((prices) => {
                    // Add properties price and order to items and select exact query
                    productsArr = addproperties(productsArr, prices);
                                    
                    // Filter out products that do not have a qty or price
                    productsArr = productsArr.filter((item) => {
                        return Number(item.q) > 0 && item.price !== "N/A";
                    })

                    // Checks if the array is empty
                    if (checkProductsArrayLength(productsArr.length)) return;

                    // Filter for duplicate value from other rows
                    productsArr = productsArr.filter(checkDuplicates);

                    // If there is only one item left than set as selected product
                    if (productsArr.length === 1) {
                        handleChangeProduct(productsArr[0]);
                        setQty(upBatch !== undefined ? upBatch.qty : 0);
                    }

                    setProducts(productsArr);
                });
            });
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("fetchProduct() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }, [query])

    // useEffect for when the query changes
    useEffect(() => {
        fetchProduct();
    }, [fetchProduct])

    // useEffect focus on the next field on enter when products change
    useEffect(() => {
        if (isError === false && (inputProduct.current !== null && inputProduct.current !== undefined) && (inputProduct.current.nextElementSibling !== null) && (inputProduct.current.nextElementSibling.firstElementChild !== null)) {
            inputProduct.current.nextElementSibling.firstElementChild.focus();
        }
    }, [products])

    useEffect(() => {
        let productsArr = products;
        productsArr = productsArr.filter(checkDuplicates);

        setProducts(productsArr);
    }, [dup])

    useEffect(() => {
        if (na === true) lineRow.current.style.backgroundColor = "yellow";
        else lineRow.current.style.backgroundColor = "initial";
    }, [na])

    // When the qty or selected product changes calculate the new total
    useEffect(() => {
        if (selectedProduct) {
            let result = getProductLineFromProduct(selectedProduct.n);

            let temp_maxqty = productMax[result];
            let temp_price = selectedProduct.price;
            let dis_qty = (qty < temp_maxqty) ? (qty) : (temp_maxqty);
            let temp_product_qty = Number(selectedProduct.q);
            let temp_max = temp_maxqty;
            let line_qty = 0;
            let line_price = 0;

            if (Number(qty) < temp_maxqty && Number(qty) < temp_product_qty) {
                line_qty = qty;
                line_price = Number(temp_price) * qty;
                tdDis.current.style.backgroundColor = "initial";
            } else if (Number(qty) >= temp_product_qty && Number(qty) <= temp_maxqty) {
                line_qty = selectedProduct.q;
                line_price = Number(temp_price) * temp_product_qty;
                tdDis.current.style.backgroundColor = "#eda4a4";
            } else {
                line_qty = temp_maxqty;
                line_price = Number(temp_price) * temp_maxqty;
                tdDis.current.style.backgroundColor = "#eda4a4";
            }

            setTotal(line_price);
            setQty(line_qty);

            if (temp_product_qty < Number(temp_maxqty)) temp_max = temp_product_qty;
            
            inputQty.current.max = temp_max;

            // Save the line values
            let orderline = { pcode: query, product: selectedProduct.n, qty: line_qty, dis: dis_qty, pri: temp_price, tot: line_price, description: selectedProduct.s };
            updateLineValues(id, orderline);
            updateGrandTotals(id, line_price);
        }
    }, [qty, selectedProduct, total])

    useEffect(() => {
        if (selectedProduct && (lineValues[id] === undefined)) {
            let result = getProductLineFromProduct(selectedProduct.n);

            let temp_maxqty = productMax[result];
            let temp_price = selectedProduct.price;
            let dis_qty = (qty < temp_maxqty) ? (qty) : (temp_maxqty)
            let temp_product_qty = Number(selectedProduct.q);
            let temp_max = temp_maxqty;
            let line_qty = 0;
            let line_price = 0;

            if (Number(qty) < temp_maxqty && Number(qty) < temp_product_qty) {
                line_qty = qty;
                line_price = Number(temp_price) * qty;
                tdDis.current.style.backgroundColor = "initial";
            } else if (Number(qty) >= temp_product_qty && Number(qty) <= temp_maxqty) {
                line_qty = selectedProduct.q;
                line_price = Number(temp_price) * temp_product_qty;
                tdDis.current.style.backgroundColor = "#eda4a4";
            } else {
                line_qty = temp_maxqty;
                line_price = Number(temp_price) * temp_maxqty;
                tdDis.current.style.backgroundColor = "#eda4a4";
            }

            setTotal(line_price);
            setQty(line_qty);

            if (temp_product_qty < Number(temp_maxqty)) temp_max = temp_product_qty;
            
            inputQty.current.max = temp_max;

            let orderline = { pcode: query, product: selectedProduct.n, qty: line_qty, dis: dis_qty, pri: temp_price, tot: line_price, description: selectedProduct.s };
            updateLineValues(id, orderline);
            if (selectedProduct && (grandTotals[id] === undefined || grandTotals[id] === 0)) updateGrandTotals(id, line_price);
        }
    }, [qty, selectedProduct, total, lineValues])

    function checkProductsArrayLength(productArrayLength) {
        if (productArrayLength === 0) {
            setIsLoading(false);
            setNa(true);
            return true;
        }
        return false;
    }

    // Add propterties price and order to item, select exact query and remove item if qty is 0 or price is N/A
    function addproperties(productsArr, prices) {
        let c=false;
        for (let i of productsArr) {
            let x = prices.data.find(item => item.product.toUpperCase() === i.n.toUpperCase());
            let productLine = getProductLineFromProduct(i.n);

            Object.defineProperties(i, {
                'order': {
                    value: productSort[productLine]
                },
                'price': {
                    value: (x.status === "OK") ? (Number(x.price).toFixed(2)) : ("N/A")
                }
            })

            // If the product number is the same as the one in the draft auto select it
            if (upBatch !== undefined && (i.n === upBatch.description || i.s === upBatch.product) && !c) {
                c=true;
                let tempQty = (upBatch.qty !== 0 && upBatch.qty !== "0") ? upBatch.qty : 0;
                handleChangeProduct(i);
                setQty(tempQty);
            }
        }

        // Order the array
        productsArr = productsArr.sort((a, b) => {
            return a.order - b.order;
        })
        
        return productsArr;
    }

    function checkDuplicates(item) {
        let temp = true;
        for (let key in dup) {
            if (dup[key] !== undefined && item.n === dup[key].split(" ")[0] && item.n !== selectedProduct.n) temp = false;
        }
        if (!temp) return temp;
        return item;
    }

    // set the query on enter and if empty string focus qty
    function handleQuery(e) {
        if (e.key === 'Enter' && e.target.value !== "") setQuery(e.target.value);

        if (e.key === 'Enter' && e.target.value === "") inputQty.current.focus();
    }

    // set the query on unfocus
    function handleBlur(e) {
        if (e.target.value !== "") setQuery(e.target.value);
    }

    // When the remove button is clicked reset row
    function handleRemove() {
        setInstanceKey(instanceKey + 1);
        setQuery("");
        setProducts([]);
        setQty(0);
        setTotal(0.00);
        setSelectedProduct("");
        lineRow.current.style.backgroundColor = "initial";
        tdDis.current.style.backgroundColor = "initial";
        let orderline = { pcode: "", product: "", qty: 0, dis: 0, pri: 0, tot: 0.00 };
        updateLineValues(id, orderline);
        updateGrandTotals(id, 0);
        updateDup(id, "");
    }

    function showAvailability() {
        let display_value = '';
        if (selectedProduct) {
            let result = getProductLineFromProduct(selectedProduct.n);

            if (qty > productMax[result]) {
                display_value = productMax[result];
            } else if (qty > Number(selectedProduct.q)) {
                display_value = selectedProduct.q;
            } else {
                display_value = qty;
            }
            
            return display_value;
        }
        
        return '';
    }

    // set selected product
    const handleChangeProduct = (product) => {
        setSelectedProduct(product);
        updateDup(id, product.n);
        inputQty.current.focus();
    }

    // Show description column
    function description() {
        let switchVal = 0;
        if (products.length === 0 && na) {
            switchVal = 1;
        } else if (products.length === 1) {
            switchVal = 2;
        } else if (products.length > 1) {
            switchVal = 3;
        } else if (selectedProduct !== '') {
            switchVal = 2;
        } else {
            switchVal = 4;
        }
        
        switch(switchVal) {
            case 1:
                return "Out of Stock";
            case 2:
                if (selectedProduct && products.length === 0) return `${selectedProduct.n} - ${selectedProduct.d}`;
                else return products.map(item => `${item.n} - ${item.d}`);
            case 3:
                return (
                    <Dropdown className={`desInput`}>
                        <Dropdown.Toggle id={"product-" + id} ref={inputProduct} tabIndex={desindex}>
                            {(selectedProduct !== '') ? (`${selectedProduct.n} - ${selectedProduct.d}`) : ('')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu show={show_drop}>
                            {products.map((item, index) => {
                                return <Dropdown.Item key={"pro-" + id + index} onClick={() => handleChangeProduct(item)}>{item.n} - {item.d}</Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                )
            default:
                return "";
        }
    }

    if (errorStatus === 401) return <Navigate to="/login" /> 
    
    return (
        <tr id={id} ref={lineRow} key={instanceKey + id}>
            <td className="rem">
                <img src={removeImg} onClick={handleRemove} alt="Remove" />
            </td>
            <td className="pcode">
                <Form.Control ref={inputPcode} tabIndex={proindex} type="text" defaultValue={"" || query} onKeyDown={(e) => handleQuery(e)} onBlur={(e) => handleBlur(e)} />
            </td>
            <td className="product">
                {isLoading ? ( <Loading /> ) : (null)}
                {description()}
            </td>
            <td className="qty">
                <Form.Control ref={inputQty} tabIndex={qtyindex} type="number" min="0" onChange={e => setQty(e.target.value)} onKeyDown={event => (event.key === 'Enter') ? (document.querySelector(`input[tabindex="${qtyindex + 1}"]`).focus()) : (null)} value={qty} />
            </td>
            <td className="dis" ref={tdDis}>{showAvailability()}</td>
            <td className="pri">{selectedProduct ? "$" + selectedProduct.price : ""}</td>
            <td className="tot">{Number.isNaN(total) ? "" : "$" + total.toFixed(2)}</td>
        </tr>
    )
}

export default TableRow;