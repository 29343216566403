/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: Get all the makes for the e-catalouge based on the year chosen
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link, useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import { catalogApiURL } from '../../../../context/addresses.js';
import Loading from '../../../../context/loading.js';
import ListGroup from 'react-bootstrap/ListGroup';

function GetMakeByYear(props) {
    const [makes, setMakes] = useState([]); // Get make by year url
    const [isLoading, setIsLoading] = useState(false); // Check loading
    const [isError, setIsError] = useState(false); // Check error
    const [errorStatus, setErrorStatus] = useState();
    const location = useLocation(); // get the pathname

    // Api url for make by year
    useEffect(() => {
        const fetchMake = async () => {
            setIsError(false)
            setIsLoading(true);

            try {
                const response = await axios.get(catalogApiURL + "?year=" + props.year, { Session: {} });
                response.data.sort();
                setMakes(response.data);
            } catch(error)  {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }

                console.log("fetchMake failed: " + error.message);
                setIsError(true);
            }
            
            setIsLoading(false);
        };
        
        fetchMake();
    }, [props.year]);

    // The column header will change to the make clicked
    function handleClick(event) {
        document.getElementsByClassName('col2')[0].childNodes[0].innerHTML = event.currentTarget.innerHTML;
        document.getElementsByClassName('col3')[0].childNodes[0].innerHTML = "Model";
        document.getElementsByClassName('col4')[0].childNodes[0].innerHTML = "Category";
        document.getElementsByClassName('col5')[0].childNodes[0].innerHTML = "Product";
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <>
            {isError && <div>Something went wrong ...</div>}
            {isLoading ? (
                <Loading />
            ) : (
                makes.map((make, index) => (
                    <ListGroup.Item id={`/e-cat/${props.year}/${make}`} className={`e-cat-item list-group-item-action ${(location.pathname.split("/").slice(3, 4).toString() === make) ? ("active") : ("")}`} key={index + 1}>
                        <Link to={`/e-cat/${props.year}/${make}`} onClick={event => handleClick(event)}>{make}</Link>
                    </ListGroup.Item>
                ))
            )}
        </>
    );
}

export default GetMakeByYear;
