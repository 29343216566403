/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: Sept 9th, 2020
 * 
 *  Description: A component to show a spinner when loading
 * 
 *****************************************************************************************************/

import React from 'react';

export default function Loading() {
    return <div className="spinner-border" role="status"></div>
}
