/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: Get the category for the e-catalouge based on the model chosen
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { catalogApiURL } from '../../../../context/addresses.js';
import Loading from '../../../../context/loading.js';
import { Link, Navigate, useLocation } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';

function GetCategoryByModel(props) {
    const [categories, setCategories] = useState([]); // Categories from the api
    const [isLoading, setIsLoading] = useState(false); // Check if loading
    const [isError, setIsError] = useState(false); // Check if error
    const [errorStatus, setErrorStatus] = useState();
    const location = useLocation(); // Get pathname
    const order = { "Disc Brake Rotor F": 1, "Disc Brake Rotor R": 2, "Disc Brake Pad Set F": 3, "Disc Brake Pad Set R": 4, "Wheel Bearing Assembly F": 5, "Wheel Bearing Assembly R": 6 };

    // Get all categories
    useEffect(() => {
        const fetchCategory = async () => {
            setIsError(false)
            setIsLoading(true);

            try {
                const response = await axios.get(catalogApiURL + "?year=" + props.year + "&make=" + props.make + "&model=" + encodeURIComponent(props.model), { Session: { Session: {} } });
            
                setCategories(response.data);
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }
                
                console.log("fetchCategory failed: " + error.message);
                setIsError(true);
            }

            setIsLoading(false);
        };
        
        fetchCategory();
    }, [props.year, props.make, props.model]);

    // The column header will change to the model clicked
    function handleClick(event) {
        document.getElementsByClassName('col4')[0].childNodes[0].innerHTML = event.currentTarget.innerHTML;
    }

    // Count duplicates and return as a category 
    const countDuplicates = () => {
        let newArr = [];
        let counter = {};
        categories.forEach(function(obj) {
            let key = obj.itemtype + " " + obj.axle.slice(0, 1); 
            counter[key] = (counter[key] || 0) + 1;
        })
        for (let category in counter) {
            newArr.push({ category, "amount": counter[category], "order": order[category] })
        }

        // Sort array by order number
        newArr.sort((a, b) => a.order - b.order);

        return (
            newArr.map((item, index) => {
                let textCategory = '';
                if (item.category.match(/F/)) textCategory = "Front ";
                else if (item.category.match(/R/)) textCategory = "Rear ";

                return (
                    <ListGroup.Item id={`/e-cat/${props.year}/${props.make}/${props.model}/${item.category}`} className={`e-cat-item list-group-item-action ${(decodeURIComponent(location.pathname.split("/")[5]) === item.category) ? ("active") : ("")}`} key={index}>
                        <Link to={`/e-cat/${props.year}/${props.make}/${props.model}/${item.category}`} onClick={event => handleClick(event)}>
                            {(textCategory !== '') ? (textCategory) : (null)} 
                            {item.category.match(/Rotor|Pad|Bearing|Caliper/)}
                        </Link>
                    </ListGroup.Item>
                )
            })
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <>
            {isError && <div>Something went wrong ...</div>}
            {isLoading ? (<Loading />) : (countDuplicates())}
        </>
    );
}

export default GetCategoryByModel;
