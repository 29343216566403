/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: August 27th, 2020
 * 
 *  Description: The component that will show the statement details 
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from "qs";
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { statementItemsApiURL } from '../../../context/addresses';
import Loading from '../../../context/loading.js';
import { statement_table_headers } from "../../../context/orderModels.js";
import './styles/statement-details.css';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function StatementDetails(props) {
    let auth = useAuth();
    const [orders, setOrders] = useState(); // Items in the statement
    const [statement, setStatement] = useState(); // The statement
    const [isLoading, setIsLoading] = useState(false); // loading data
    const [isError, setIsError] = useState(false); // There was an error getting api
    const [errorStatus, setErrorStatus] = useState([]); // Get the error status
    const [newPaid, setNewPaid] = useState();
    const paid_discount = (statement !== undefined) ? (statement.balance_discounted) : (null);
    let showbalance = (statement) ? (statement.balance) : (null);

    useEffect(() => {
        document.title = `${props.statement} Statement Details - Max Advanced Brakes Wholesale`;
    }, []);

    useEffect(() => {
        getStatement();
    }, [props.statement])

    async function getStatement() {
        setIsLoading(true);
        setIsError(false);

        try {
            const response = await axios.get(`${statementItemsApiURL}${props.statement}`, { Session: {}, withCredentials: true });
            console.log(response.data)
            setOrders(response.data.orders);
            setStatement(response.data.statement[0]);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status)
            } else {
                console.log("fetchStatements failed: " + error.message);
            }
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function updatePaidAmount() {
        try {
            await axios.post(statementItemsApiURL, qs.stringify({ statement_id: props.statement, paid_amount: newPaid, type: 'normal' }), { Session: {}, withCredentials: true });
            alert('Payment recorded');
            await getStatement();
        } catch(error)  {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status)
            } else {
                console.log("updatePaidAmount() failed: " + error.message);
            }
        }
    }

    async function updatePaidAmountDiscount() {
        try {
            axios.post(statementItemsApiURL, qs.stringify({ statement_id: props.statement, paid_amount: paid_discount, type: 'discount' }), { Session: {}, withCredentials: true });
            alert("Payment recorded: Discount amount");
            await getStatement();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                 console.log("updatePaidAmountDiscount() failed:" + error.message);
                 setErrorStatus(error.response.status)
             } else {
                 console.log("Something went wrong: " + error.message);
             }
        }
    }

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(statement_table_headers).map(([_key, value], index) => {
                        return <th key={index}>{value}</th>
                    })}
                </tr>
            </thead>
        )
    }

    if (errorStatus === 401) {
        return <Navigate to="/login" />
    }

    return (
        <div id="statementItem" className="page">
            <Container>
                <h1>{props.statement}</h1>
                {isError && <tr><td>There was an error getting the invoices</td></tr>}
                {isLoading ? (<tr><td><Loading /></td></tr>) : (null)}
                {(orders) ? (
                    <Table className='table'>
                        {TableHeaders()}
                        <tbody>
                            {orders.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.date}</td>
                                        <td>{item.otype}</td>
                                        <td>{item.invoiceid}</td>
                                        <td>${item.ototal}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                ) : (null)}
                {(auth.user.access !== 5) ? (
                    <div id="payment-container">
                        <p>Final cost for this statement: ${showbalance} (or {(statement && statement.balance_discounted !== null) ? (`(${statement.balance_discounted})`) : (null)} if paid within 10 days)</p>
                        {(statement && statement.fully_paid) ? (
                            <div id="paymentInfo">
                                <p>Statement has been paid! ({statement.paid_amount}) </p>
                                <p>Last payment received on [{(statement) ? (statement.date_paid) : (null)}] by user [{(statement) ? (statement.markpaid_user) : (null)}] </p>
                            </div>
                        ) : (
                            <>
                                <p>Payment Received?</p>
                                <div id="paymentInfo">
                                    <p>Previous payment(s) for this statement: ${(statement) ? (statement.paid_amount) : (0.00)}</p>
                                    <p>Remaining balance: ${(statement) ? ((Number(statement.balance) - Number(statement.paid_amount)).toFixed(2)): (null)} (or {(statement && statement.balance_discounted !== null) ? ("$" + (Number(statement.balance_discounted) - Number(statement.paid_amount)).toFixed(2)) : (null)} if paid within 10 days)</p>
                                    <p>Enter the new amount paid. If there is a previous payment on record, it will be added to the current payment automatically.</p>
                                    <Row id="newPay">
                                        <Form.Label column md={2}>Amount Paid ($):</Form.Label>
                                        <Form.Group as={Col} md={3} controlId="amount-paid">
                                            <Form.Control type="text" id="amount-paid" onChange={e => setNewPaid(e.target.value)} defaultValue={"0.00"} />
                                        </Form.Group>
                                        <Col md={6}>
                                            <Button id="paidButton" variant="primary" onClick={updatePaidAmount}>Paid</Button>{' '}
                                            <Button id="paidDiscountButton" variant="success" onClick={updatePaidAmountDiscount}>Paid within 10 days</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                    </div>
                ) : (null)}
                <br />
                <button className='btn btn-secondary'>
                    <Link to="/statements">Back</Link>
                </button>
            </Container>
        </div>
    )
}

export default StatementDetails;
