/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: September 25th, 2020
 * 
 *  Description: The component that will show the user what they requested to return and the return status
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Navigate, Link, useLocation } from 'react-router-dom';
import axios from "axios";
import qs from 'qs';
import * as XLSX from 'xlsx';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../../../context/auth.js';
import Returns from '../returns/returns';
import Loading from '../../../context/loading.js';
import { returns_table_headers, returns_table_headers_old } from "../../../context/orderModels.js";
import { usersApiURL, getPendingReturnApiURL, postPendingReturnApiURL, postMultiPendingReturn, postPendingReturnUpload } from '../../../context/addresses.js';
import './styles/return-page.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import useSortableData from '../../sortTable/useSortableData.js';
import downloadTemplate from './download_files/Max Advanced Brakes_RGR form_GTA_fillable_blank.pdf';

export const ReturnBatch = () => {
    let location = useLocation();
    return <Returns username={location.pathname.split('/')[3]} />;
}

function ReturnPage() {
    let auth = useAuth(); // Get the current user data
    const [accounts, setAccounts] = useState([]); // Get the list of accounts for filter
    const [returns, setReturns] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // loading data
    const [isError, setIsError] = useState(false); // There was an error getting api
    const [errorStatus, setErrorStatus] = useState([]); // Get the error status
    const [page, setPage] = useState(0); // What the page number is
    const [username, setUsername] = useState(""); // The username used for filter
    const { items, requestSort } = useSortableData(returns);
    const [show, setShow] = useState(false); //When the user clicks the close or reject button for modal
    const handleClose = () => setShow(false); // When the user clicks the close or reject button
    const handleShow = () => setShow(true); // When the user clicks the reject button in the table
    const [returnReason, setReturnReason] = useState(""); // The reason for the return
    const [returnOrderId, setReturnOrderId] = useState(""); // The order id of the pending return item
    const params = { reason: returnReason };
    const [checkedList, setCheckList] = useState([]);
    const [checkedListUser, setCheckListUser] = useState('');
    const [returnFile, setReturnFile] = useState({ data: [], headers: [] });
    const prevButtonRef = useRef();
    const nextButtonRef = useRef();
    
    useEffect(() => {
        document.title = 'Returns - Max Advanced Brakes Wholesale';
        setUsername("");
        getReturns();
    }, []);

    // Get all users
    useEffect(() => {
        if (auth.user.access !== 5) fetchAccount();
    }, [auth]);

    const fetchAccount = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios(`${usersApiURL}?perpage=200`, { Session: {}, withCredentials: true });
        
            response.data = response.data.sort((a, b) => {
                return ("" + a.company_name).localeCompare("" + b.company_name);
            })

            setAccounts(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("fetchAccount() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    // Update the table after a change in the data from the users end
    async function getReturns() {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(`${getPendingReturnApiURL}?page=${page}&&perpage=${30}`, { Session: {}, withCredentials: true });

            response.data = response.data.sort((a, b) => {
                return a - b;
            });

            buttonToDisable(response.data.length, page);

            setReturns(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("getReturns() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function getReturnsByAccounts() {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(getPendingReturnApiURL, { 
                params: {
                    username: username,
                    page: page,
                    perpage: 30
                },
                Session: {},
                withCredentials: true 
            });

            response.data = response.data.sort((a, b) => {
                return a - b;
            });

            buttonToDisable(response.data.length, page);

            setReturns(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("getReturnsByAccounts() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    // Filter returns
    function handleSubmit(event) {
        event.preventDefault();
        getReturnsByAccounts()
    }

    // When the clear button is clicked
    function handleClear() {
        setUsername("")
        setPage(0);
    }

    // When the next button is clicked
    function handleNext() {
        if (1 <= page) setPage(page + 1);
        else setPage(1);
    }

    // When the previous button is clicked
    function handlePrevious() {
        if (page <= 0) return;
        else setPage(page - 1);
    }

    // What buttons to disable depending on conditions
    function buttonToDisable(arrLength, pageNum) {
        if (pageNum === 0 && arrLength < 30) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = true;
        } else if (pageNum === 0) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = false;
        } else if (arrLength < 30) {
            prevButtonRef.current.disabled = false;
            nextButtonRef.current.disabled = true;
        } else {
            nextButtonRef.current.disabled = false;
            prevButtonRef.current.disabled = false;
        }
    }

    // When the user accepts a return
    async function handleReturnActionAccept(order_id, action) {
        try {
            await axios.post(postPendingReturnApiURL + order_id + "/" + action, {}, { Session: {}, withCredentials: true });
            await getReturns();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User not signed in: " + error.message);
            }

            console.log("handleReturnActionAccept() failed: " + error.message);
        }
        alert("Return Accepted!");
    }

    // When the user rejects a return
    async function handleReturnActionReject() {
        try {
            await axios.post(postPendingReturnApiURL + returnOrderId + "/reject", qs.stringify(params), { Session: {}, withCredentials: true });
            await getReturns();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User not signed in: " + error.message);
            }

            console.log("handleReturnActionReject() failed: " + error.message);
        }
    }

    function handleCheckList(pendingId, customer, status, el) {
        let temp = checkedList;

        if (status !== 'Pending') {
            el.target.checked = false;
            return;
        }
        
        if (checkedListUser === '') setCheckListUser(customer);

        if (temp.length === 0) {
            temp.push(pendingId);
            setCheckList(temp);
            return;
        }

        if (checkedListUser !== customer) {
            el.target.checked = false;
            return;
        }

        if (temp.includes(pendingId) === false) {
            temp.push(pendingId);
        } else {
            temp = temp.filter((id) => {
                if (id !== pendingId) {
                    return id;
                } else {
                    el.target.checked = false;
                }
            });
        }

        if (temp.length === 0) {
            setCheckListUser('');
        }
        
        setCheckList(temp);
    }

    // When user accepts multiple credits at once
    async function handleMultiActionAccept() {
        try {
            if (checkedList.length !== 0) {
                await axios.post(postMultiPendingReturn, checkedList, { Session: {}, withCredentials: true });
                alert("Items Credited");
                setCheckList([]);
                setCheckListUser('');
                await getReturns();
            } else {
                alert("No Items checked for credit");
            }
        } catch(error) {
            console.log(error);
        }
    }

    function handleUploadReturns(event) {
        let file = event.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';
        
        let reader = new FileReader();
        reader.onload = function(evt) {
            let data = evt.target.result;
            let parsed = '';
            let headers = '';
            let lines = [];

            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type:'binary' });
                parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
                headers = parsed[0];
            } else {
                parsed = data.trim().split(/\r\n|\n/);

                for (let index in parsed) {
                    parsed[index] = parsed[index].split(',');
                }

                headers = parsed[0];
            }
            
            lines = returnFileLines(parsed, headers, lines);
            setReturnFile({ data: lines, headers: headers });
        }

        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
    }

    function returnFileLines(parsed, headers, lines) {
        for (let lineNum in parsed) {
            if (parsed[lineNum].length !== headers.length) continue;
            if (lineNum === "0") continue;

            let temp = [];
            for (let header in headers) {
                temp.push({ [headers[header]]: parsed[lineNum][header] });
            }

            temp = Object.assign({}, ...temp);
            lines.push(temp);
        }

        return lines;
    }
    
    async function fileUpload() {
        try {
            console.log(returnFile)
            if (!returnFile || returnFile.data.length === 0) return;

            const response = await axios.post(postPendingReturnUpload, { data: returnFile.data, headers: returnFile.headers, username: username }, { Session: {}, withCredentials: true });
            alert(response.data);
            document.getElementById('pendingReturnUpload').value = '';
            setReturnFile({ data: [], headers: [] });
            getReturns();
        } catch(error) {
            console.log(error);
        }
    }

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(returns_table_headers_old).map(([key, value], index) => {
                        return <th key={index} className="sort" onClick={() => requestSort(value)}>{key}</th>
                    })}
                </tr>
            </thead>
        )
    }

    function accountList() {
        return (
            isLoading ? (
                <option><Loading /></option>
            ) : (
                accounts.map((item, index) =>
                    <option value={item.username} key={index}>
                        {item.company_name} ({item.firstname} {item.lastname})
                    </option>
                )
            )
        )
    }

    function returnFileCheckSubmit() {
        return (
            (returnFile && returnFile.data.length === 0) ? (
                <Button variant="secondary">Submit</Button>
            ) : (
                <Button variant="warning" type="button" onClick={fileUpload}>Submit</Button>
            )
        )
    }

    function adminStatusField(item, statusText) {
        return (
            (item.status === 'Pending') ? (
                <td>
                    <Button className="returnAcceptReject" variant="success" onClick={e => handleReturnActionAccept(e.target.value, "accept")} value={item.id}>Accept</Button>{' '}
                    <Button className="returnAcceptReject" variant="danger" onClick={e => { setReturnOrderId(e.target.value); handleShow(); }} value={item.id}>Reject</Button>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Reject Reason</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>What is the reason for rejecting return?</p>
                            <Form.Control type="text" onChange={e => setReturnReason(e.target.value)} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleReturnActionReject}>
                                Return
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </td>
            ) : (<td>{statusText}</td>)
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="return-page" className="page">
            <Container>
                <h2>Returns</h2>
                {isError && <div>There was an error</div>}
                <Row className="mb-3">
                    <Col>
                        <Link to={"/return-page/returns/" + auth.user.username}>
                            <Button variant="secondary">Batch Returns</Button>
                        </Link>
                    </Col>
                    <Col>
                        <a href={downloadTemplate} download="RGR_Form.pdf">Download Return Request Form</a>
                    </Col>
                    <Form.Label className="text-align-right" column md={2}>Return Multiple Products:</Form.Label>
                    <Form.Group as={Col} md={3}>
                        <Form.Control id="pendingReturnUpload" className="uploadButton" type="file" onChange={(e) => handleUploadReturns(e)} />
                    </Form.Group>
                    <Col md={1} className="text-align-right">
                        {returnFileCheckSubmit()}
                    </Col>
                </Row>
                <Form className="mb-3" onSubmit={(event) => handleSubmit(event)}>
                    {(auth.user.access !== 5) ? (
                        <Row className="border-black pb-2 background-color-lightgrey">
                            <Form.Label column md={1}>Customer:</Form.Label>
                            <Form.Group as={Col} md={4} controlId="cusName">
                                <Form.Select id="cusName" placeholder="Customer Name" onChange={(e) => { setUsername(e.target.value); }}>
                                    <option value=""></option>
                                    {accountList()}
                                </Form.Select>
                            </Form.Group>
                            <Col md={3}>
                                <Button variant="warning" type="submit">Search</Button>{' '}
                                <Button variant="secondary" type="submit" onClick={handleClear}>Clear</Button>  
                            </Col>
                            <Col md className="text-align-right">
                                <Button variant="success" onClick={handleMultiActionAccept}>Accept Multiple Checked Invoice</Button>
                            </Col>
                        </Row>
                    ) : (null)}
                    <Row>
                        <Col md={6}>
                            <Button variant="secondary" ref={prevButtonRef} type="submit" onClick={handlePrevious}>Previous</Button>
                        </Col>
                        <Col md={6} className="text-align-right">
                            <Button variant="secondary" ref={nextButtonRef} type="submit" onClick={handleNext}>Next</Button>
                        </Col>
                    </Row>
                </Form>
                <Table striped>
                    {TableHeaders()}
                    <tbody>
                        {isLoading ? (
                            <tr><td><Loading /></td></tr>
                        ) : (
                            items.map((item, _index) => {
                                let statusText = (item.status === "accept") ? ("Credit Issued") : (item.status);

                                return (
                                    <tr className="check" key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.order_id}</td>
                                        <td>{item.product}</td>
                                        <td>{item.username}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.order_created}</td>
                                        <td>{item.date_created}</td>
                                        {(auth.user.access !== 5) ? (adminStatusField(item, statusText)) : (null)}
                                        {(auth.user.access === 5) ? (<td>{statusText}</td>) : (null)}
                                        <td>{item.reason}</td>
                                        <td>
                                            {(auth.user.access !== 5) ? (
                                                <div>
                                                    <Form.Check type={'checkbox'} onClick={(e) => handleCheckList(item.id, item.username, item.status, e)} />
                                                </div>
                                            ) : (null)}
                                        </td>
                                    </tr>
                                )
                            }))}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default ReturnPage;