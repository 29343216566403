/*****************************************************************************************************
*  Author: Adam Khan
*  Date: August 12th, 2021
* 
*  Description: The user will be able to do batch returns
* 
*****************************************************************************************************/

import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { returnsResults_table_headers, returnsCart_table_headers } from '../../../context/orderModels';
import './styles/returns.css';
import { returnsApiURL, usersApiURL } from '../../../context/addresses';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableRows from "./components/TableRows";
import CartTableRows from "./components/CartTableRows";
import { useAuth } from "../../../context/auth";

function Returns(props) {
    const auth = useAuth();
    const [customerList, setCustomerList] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [instanceKey, setInstanceKey] = useState(0);
    const [rowProductArray, setRowProductArray] = useState([]); // Array of selected products
    const [productsToReturnArray, setProductsToReturnArray] = useState([]); // Array of products in returns cart
    const [returnCart, setReturnCart] = useState([]); // Copy of items to return
    const [numRows, setNumRows] = useState(5); // Number of query table rows
    const [numRows2, setNumRows2] = useState(0); // Number of return cart table rows
    let customerSelectRef = useRef();
    let rows = []; 
    let rows2 = [];

    useEffect(() => {
        document.title = 'Batch Returns - Max Advanced Brakes Wholesale';
    }, []);

    useEffect(() => {
        if (auth.user.access !== 5) {
            async function fetchCustomerList() {
                try {
                    let response = await axios.get(`${usersApiURL}?perpage=200`, { Session: {}, withCredentials: true });
                    
                    response.data = response.data.sort((a, b) => {
                        return ("" + a.company_name).localeCompare("" + b.company_name);
                    })
        
                    setCustomerList(response.data);
                } catch(error) {
                    console.log(error);
                }
            }

            fetchCustomerList();
        }
    }, [])

    // Add items to return cart
    function handleAddToCart() {
        if (returnCart.length !== 0) {
            let temp = returnCart;
            temp.push(...productsToReturnArray);
            setReturnCart(temp);
            setNumRows2(temp.length);
        } else {
            setReturnCart(productsToReturnArray);
            setNumRows2(productsToReturnArray.length);
        }
        handleClear();
    }

    // POST the items that will be returned
    async function postCart() {
        try {
            let tempCloneReturn = [];
            returnCart.map((item) => {
                let tempClone = item;
                for (let i = 0; i < tempClone.query_array.length; i++) {
                    let amount = 0;
                    if (tempClone.query_array[i].orderitemid === tempClone.return_array[i].orderitemid && tempClone.query_array[i].qty_shipped !== tempClone.return_array[i].qty_shipped) {
                        amount = tempClone.query_array[i].qty_shipped - tempClone.return_array[i].qty_shipped;
                        tempCloneReturn.push({ orderitemid: tempClone.return_array[i].orderitemid, qty: amount });
                    }
                }
            });

            // Check if the return cart is empty
            if (tempCloneReturn.length !== 0) {
                await axios.post(returnsApiURL, tempCloneReturn, { Session: {}, withCredentials: true });
                alert("Return Accepted");
                handleClear();
                setNumRows2(0);
                setReturnCart([]);
            } else {
                alert("No items to return");
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }
                
            console.log("postCart failed: " + error.message);
            alert(error.response.data)
        }
    }

    // Clear the table
    function handleClear() {
        setInstanceKey(instanceKey + 1);
        setRowProductArray([]);
        setNumRows(5);
        setProductsToReturnArray([]);
        console.log('clear');
    }

    function clearCustomer() {
        setInstanceKey(instanceKey + 1);
        setRowProductArray([]);
        setNumRows(5);
        setProductsToReturnArray([]);
        setCustomer(null);
        customerSelectRef.current.value = '';
    }

    // Table headers for query table
    const TableHeadersResults = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(returnsResults_table_headers).map(([key, _value], index) => {
                        return <th key={index}>{key}</th>
                    })}
                </tr>
            </thead>
        )
    }

    // Table headers for return cart
    const TableHeadersCart = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(returnsCart_table_headers).map(([key, _value], index) => {
                        return <th key={index}>{key}</th>
                    })}
                </tr>
            </thead>
        )
    }

    // Create rows for the query table
    for (let i = 0; i < numRows; i++) {
        rows[i] = <TableRows username={props.username} rowid={i} rowProductArray={rowProductArray} setRowProductArray={setRowProductArray} productsToReturnArray={productsToReturnArray} setProductsToReturnArray={setProductsToReturnArray} customer={customer} />;
    }

    // Create rows for the cart table
    for (let i = 0; i < numRows2; i++) {
        rows2[i] = <CartTableRows index={i} item={returnCart[i]} returnCart={returnCart} setReturnCart={setReturnCart} setNumRows2={setNumRows2} />;
    }

    return (
        <div id="returns" className="page">
            <Container>
                <div className="panel panel-warning">
                    {(auth.user.access !== 5) ? (
                        <div className="panel-heading">
                            <Form.Label>Return products for: <b>{(customer !== null && customer !== '') ? (customer) : (props.username)}</b></Form.Label>
                            <Row>
                                <Form.Label column md={1}>Customer:</Form.Label>
                                <Form.Group as={Col} md={4} controlId="cusName">
                                    <Form.Select ref={customerSelectRef} placeholder="Customer Name" onChange={(e) => { setCustomer(e.target.value); }}>
                                        <option value=""></option>
                                        {customerList.map((item, index) => {
                                            return (
                                                <option value={item.username} key={index}>
                                                    {item.company_name} ({item.firstname} {item.lastname})
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Col md={4}>
                                    <Button id="clearButton" variant="secondary" onClick={clearCustomer}>Clear</Button>
                                </Col>
                            </Row>
                        </div> 
                    ) : (
                        <div className="panel-heading">
                            <Form.Label>Return products for: <b>{props.username}</b></Form.Label>
                        </div>
                    )}
                    <div className="panel-body">
                        <div>
                            <Table key={instanceKey}>
                                {TableHeadersResults()}
                                <tbody>
                                    {rows}
                                </tbody>
                            </Table>
                            <br />
                            <Row>
                                <Col>
                                    <Button variant="secondary" className="block-level" type="button" onClick={handleClear}>Clear</Button>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="block-level" type="button" onClick={() => setNumRows(numRows + 5)}>Add 5 Rows</Button>
                                </Col>
                                <Col>
                                    <Button variant="secondary" className="block-level" type="button" onClick={handleAddToCart}>Add to cart</Button>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div>
                            <Form.Label>Your returns cart:</Form.Label>
                            <Table>
                                {TableHeadersCart()}
                                <tbody>
                                    {rows2}
                                </tbody>
                            </Table>
                            <Row>
                                <Col className="text-align-center">
                                    <Button className="panel-button" variant="warning" type="button" onClick={postCart}>Return</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Returns;