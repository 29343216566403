/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The terms for Pricing and Invoices
* 
*****************************************************************************************************/

function PricingAndInvoices() {
    return (
        <>
            <div className="terms-header">
                <h3>Pricing And Invoices</h3>
            </div>
            <div className="terms-content row">
                <div className="terms-columns">
                    {/* <h5>PRODUCT PRICING</h5>
                    <p>Information about Maxbrakes products on the Web Sites attempts to be as accurate as possible. However, Maxbrakes does not warrant that product descriptions or other content of the Web Sites is accurate, complete, reliable, current, or error-free. Despite Maxbrakes's efforts, it is possible due to computer or other error or cause that a product offered on any of the Web Sites may be mispriced or contain an inaccuracy in its description. In the event Maxbrakes determines that a product is mispriced or contains an inaccurate description, Maxbrakes reserves the right to take any action it deems reasonable and necessary, in its sole discretion, to rectify the error. You agree to notify Maxbrakes immediately if you become aware of any pricing or descriptive errors or inconsistencies with any products. When you place an order through any of the Web Sites, you agree to comply with any corrective action taken by Maxbrakes.</p>
                    <h5>Invoices</h5>
                    <p>MaxBrakes invoices on the Web Sites attempt to be as accurate as possible. However, MaxBrakes does not warrant that invoices or other content of the Web Sites is accurate, complete, reliable, current, or error-free. Despite MaxBrakes's efforts, it is possible due to computer or other error or cause that an invoice provided on one of the Web Sites may be incorrect or contain an inaccuracy. In the event MaxBrakes determines that an invoice is incorrect or contains an inaccuracy, MaxBrakes reserves the right to take any action it deems reasonable and necessary, in its sole discretion, to rectify the error. You agree to notify MaxBrakes immediately if you become aware of any errors or inconsistencies with any invoices provided to you through any of the Web Sites and to comply with any corrective action taken by MaxBrakes.</p>
                    <h5>Disclaimers</h5>
                    <p>MAXBRAKES DOES NOT REPRESENT OR WARRANT THAT PRICING OR INVOICES ON ANY OF THE WEB SITES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, SUBJECT TO CORRECTION. MAXBRAKES IS NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS RELATING TO PRICING, YOUR ACCOUNT INVOICES, TEXT, OR PHOTOGRAPHY.</p>
                    <p>YOU ACKNOWLEDGE BY ACCESSING ANY OF THE WEB SITES THAT THE PRICING MAY NOT BE ACCURATE AND THAT YOU NEED TO CONTACT YOUR MAXBRAKES COMMERCIAL REPRESENTATIVE OR RETAIL STORE MANAGER, AS APPLICABLE, FOR CORRECT PRICING. YOU FURTHER ACKNOWLEDGE BY ACCESSING ANY OF THE WEB SITE THAT YOUR ACCOUNT INVOICES MAY NOT BE ACCURATE AND THAT YOU NEED TO CONTACT YOUR MAXBRAKES COMMERCIAL REPRESENTATIVE OR RETAIL STORE MANAGER, AS APPLICABLE, FOR CORRECT ACCOUNT INFORMATION.</p>
                    <h5>Your Responsibility and Risk</h5>
                    <p>It is solely your responsibility to evaluate the accuracy, completeness, and usefulness of all opinions, advice, services, merchandise, and other information provided on or through the Web Sites or on the Internet generally. The Internet contains unedited materials, some of which are sexually explicit or may be offensive to you. You access such materials at your risk. MaxBrakes has no control over and accepts no responsibility whatsoever for such materials.</p> */}
                </div>
            </div>
        </>
    )
}
 
 export default PricingAndInvoices;