/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The terms page
* 
*****************************************************************************************************/

import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Navigate, Route, Routes, Outlet, useLocation } from "react-router";
import { TermNavigation, GoToTerm } from "./components";
import "./styles/terms.css";

function Terms() {
    const location = useLocation();

    useEffect(() => {
        document.title = 'Terms and Conditions - Max Advanced Brakes Wholesale';
    }, []);

    if (location.pathname === "/terms") {
        <Navigate to={`/terms/termsAndConditions`} />;
    }

    return (
        <div id="terms" className="page">
            <Container>
                <TermNavigation />
                <Outlet />
                <Routes>
                    <Route path=":termid" element={<GoToTerm />} />
                </Routes>
            </Container>
        </div>
    )
}
 
export default Terms;