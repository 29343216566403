import { useLocation } from "react-router";
import { LoginsAndCookies, TermsAndConditions, RulesAndRegulations, PrivacyPolicy, Warranties, PricingAndInvoices, ReturnPolicy, } from "./index.js";

const  GoToTerm = () => {
    let location = useLocation();
    const termList = [ 
        { id: "termsAndConditions", component: <TermsAndConditions /> },
        { id: "loginsAndCookies", component: <LoginsAndCookies /> },
        { id: "rulesAndRegulations", component: <RulesAndRegulations /> },
        { id: "privacyPolicy", component: <PrivacyPolicy /> },
        { id: "warranties", component: <Warranties /> },
        { id: "pricingAndInvoices", component: <PricingAndInvoices /> },
        { id: "returnPolicy", component: <ReturnPolicy /> }
    ];

    let item = termList.find((item) => {
        return item.id === location.pathname.split('/')[2];
    })

    return (item !== undefined) ? (item.component) : (null);
}

export default GoToTerm;