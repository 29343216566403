import { pattern } from '../../../../../context/orderModels.js';

function getProductLineFromProduct(productNumber) {
    let result = '';
    if (productNumber !== '' && productNumber !== undefined && productNumber !== null) result = productNumber.match(pattern);
    if (result === null) result = 'Other';
    if (Array.isArray(result) && result.length > 1) result = result[1];
    return result;
}

export default getProductLineFromProduct;