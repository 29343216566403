/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: July 22th, 2020
 * 
 *  Description: Store user information when they login
 * 
 *****************************************************************************************************/

import axios from 'axios';
import qs from 'qs';
import { useState, createContext, useContext } from 'react';
import { loginApiURL, logoutApiURL, postCheckSession } from './addresses';

export const AuthContext = createContext(null);

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}

export function useProvideAuth() {
    const [user, setUser] = useState(null);

    let checkSession = async () => {
        try {
            let sessionUser = sessionStorage.getItem("username");
            let sessionToken = sessionStorage.getItem("token");

            const response = await axios.post(postCheckSession, {}, { params: { sessionUser, sessionToken }, withCredentials: true })
            
            if (response.data.status === "OK") {
                console.log("has session")
                setUser(response.data);
                sessionStorage.setItem("token", JSON.stringify(response.data.token));
                sessionStorage.setItem("username", JSON.stringify(response.data.username));

                return response.data.status;
            }

            return response.data.status;
        } catch(error) {
            console.log("Check session:", error);
            return "No session";
        }
    }

    let signin = async (username, password) => {
        if (username && password) {
            try {
                let params = { 'username': username, "password": password };
                const response = await axios.post(loginApiURL, qs.stringify(params), { withCredentials: true });
                
                if (response.data.status === "WRONG USER" || response.data.status === "WRONG PASSWORD" || response.data.status === "NO USERNAME") {
                    alert(response.data.status);
                    throw response.data.status;
                }

                setUser(response.data);
                sessionStorage.setItem("token", JSON.stringify(response.data.token));
                sessionStorage.setItem("username", JSON.stringify(response.data.username));
            } catch(error) {
                if (error && error.response) alert(error.response.data.status);
                console.log("Sign in:", error);
            }
        }
    }

    let signout = async () => {
        try {
            sessionStorage.clear();
            await axios.get(logoutApiURL, { withCredentials: true });
            setUser(null);
        } catch(error) {
            console.log("Sign out", error);
        }
    }

    return { user, signin, signout, checkSession };
}