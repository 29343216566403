/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: Get model for the e-catalouge based on the make chosen
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link, useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import { catalogApiURL } from '../../../../context/addresses.js';
import Loading from '../../../../context/loading.js';
import ListGroup from 'react-bootstrap/ListGroup';

function GetModelByMake(props) {
    const [models, setModels] = useState([]); // models from api url
    const [isLoading, setIsLoading] = useState(false); // Check is loading
    const [isError, setIsError] = useState(false); // Check is error
    const [errorStatus, setErrorStatus] = useState();
    const location = useLocation(); // Get pathname

    // Get the model from api url
    useEffect(() => {
        const fetchModel = async () => {
            setIsError(false)
            setIsLoading(true);

            try {
                const response = await axios.get(catalogApiURL + "?year=" + props.year + "&make=" + props.make, { Session: {} });

                response.data.sort();
                
                setModels(response.data);
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }
                
                console.log("fetchModel failed: " + error.message);
                setIsError(true);
            }

            setIsLoading(false);
        };
        
        fetchModel();
    }, [props.make, props.year]);

    // The column header will change to the model clicked
    function handleClick(event) {
        document.getElementsByClassName('col3')[0].childNodes[0].innerHTML = event.currentTarget.innerHTML;
        document.getElementsByClassName('col4')[0].childNodes[0].innerHTML = "Category";
        document.getElementsByClassName('col5')[0].childNodes[0].innerHTML = "Product";
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <>
            {isError && <div>Something went wrong ...</div>}
            {isLoading ? (
                <Loading />
            ) : (
                models.map((model) => (
                    <ListGroup.Item id={`/e-cat/${props.year}/${props.make}${model}`} className={`e-cat-item list-group-item-action ${(location.pathname.split("/").slice(4, 5).toString() === model) ? ("active") : ("")}`} key={model}>
                        <Link to={`/e-cat/${props.year}/${props.make}/${model}`} onClick={event => handleClick(event)}>{model}</Link>
                    </ListGroup.Item>
                ))
            )}
        </>
    );
}

export default GetModelByMake;
