/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: November 23th, 2020
 * 
 *  Description: The component that will be used when the user wants to change their password
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { postForgotPassword, postPasswordReset } from "../../../context/addresses.js";
import "./styles/password.css";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

// When the url location is password/change
function PasswordChange() {
    let location = useLocation();
    let navigate = useNavigate();
    let token = location.pathname.split('/')[3]; // The hash of the page
    const [verification, setVerification] = useState("") // The verification code is the code given in the email 
    const [password, setPassword] = useState("") // New password that the user will use
    const [confirmPassword, setConfirmPassword] = useState("") // Confirm New password that the user will use    
    const params = { code: verification, hash: token, password: confirmPassword }; // Parameters to be sent to change password

    // Post changes that will be sent to the server
    async function handleSubmit(event) {
        event.preventDefault();

        if (password === confirmPassword) {
            alert("Password and confirm password do not match");
            return;
        }
        
        try {
            const response = await axios.post(postPasswordReset, qs.stringify(params), { withCredentials: true });

            if (response.data === "OK") {
                alert("Password reset and Changed");
                navigate('/login');
            }
        } catch(error) {
            if (error.response && error.response.status === 400) {
                alert("A bad request was sent: " + error.message);
                console.log("A bad request was sent: " + error.message);
            } else {
                alert("handleSubmit failed: " + error.message);
                console.log("handleSubmit failed: " + error.message);
            }
        }
    }

    return (
        <Form id="passwordChangeForm" onSubmit={e => handleSubmit(e)}>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Verification Code</Form.Label>
                    <Form.Control type="text" value={verification} onChange={e => setVerification(e.target.value)} required/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} required/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required/>
                </Form.Group>
            </Row>
            <Row>
                <Button type="submit">Submit new Password</Button>
            </Row>
        </Form>
    )
}

// When the url location is password/reset
function PasswordReset() {
    const [email, setEmail] = useState(""); // The email that the user will give for the password reset
    const [username, setUsername] = useState(""); // The username associated with the email
    const params = { "email": email, "username": username }; // Email and associated username that the email reset will be sent to

    // Post to send email to the user for email reset
    function handleSubmit(event) {
        event.preventDefault();
        try {
            axios.post(postForgotPassword, qs.stringify(params), { /*withCredentials: true*/ });
            alert("Check email for reset link and verification code");
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }
            
            console.log("handleSubmit failed: " + error.message);
        }
    }

    return (
        <Form id="passwordResetRequestForm" onSubmit={e => handleSubmit(e)}>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>E-Mail Address</Form.Label>
                    <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} required/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} required/>
                </Form.Group>
            </Row>
            <Row>
                <Button type="submit">Send Password Reset Link</Button>
            </Row>
        </Form>
    )
}

function Password() {
    let location = useLocation();
    let titleText = (location.pathname === "/password/reset") ? (" Reset") : (location.pathname === location.pathname.match(/\/password\/reset\/[a-zA-Z0-9-]*/gi)[0]) ? (" Change") : (null);

    useEffect(() => {
        document.title = 'Password - Max Advanced Brakes Wholesale';
    }, []);

    return (
        <>
            <div id="password-page" className="page">
                <Container>
                    <h3>
                        Password
                        {titleText}
                    </h3>
                    <div className="passwordForm">
                        <Outlet />
                        <Routes>
                            <Route path=":reset" exact element={<PasswordReset />} />
                            <Route path=":reset/:resetHash" element={<PasswordChange />} />
                        </Routes>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Password;