/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: March 12th, 2021
 * 
 *  Description: The component shows the details of the credit given
 * 
 *****************************************************************************************************/

import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getReturnsById } from "../../../context/addresses.js";
import { creditDetails_table_headers } from "../../../context/orderModels.js";
import { Navigate } from 'react-router-dom';
import Loading from '../../../context/loading.js';
import "./styles/credit-details.css"
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

function CreditDetails(props) {
    const [credit, setCredit] = useState({ data: [], refund_subtotal: 0, refund_total: 0 });
    const [isError, setIsError] = useState(false);
    const [errorStatus, setErrorStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const getCredit = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(`${getReturnsById}${props.creditId}`, { Session: {}, withCredentials: true });

            let returns = response.data[0].order_ids.split(",");
            let products = response.data[0].products.split(",");
            let quantity = response.data[0].qtys.split(",");
            let unit_cost = response.data[0].prices_discounted.split(",");
            let newArr = { data: [], refund_subtotal: response.data[0].refund_subtotal, refund_total: response.data[0].refund_total };

            for (let i = 0; i < returns.length; i++) {
                newArr.data.push({ order_ids: returns[i], products: products[i], prices_discounted: Number(unit_cost[i]).toFixed(2), qtys: quantity[i] });
            }

            setCredit(newArr);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("getCredit() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }, [props.creditId])

    useEffect(() => {
        document.title = `${props.creditId} Credit details - Max Advanced Brakes Wholesale`;
        getCredit();
    }, [getCredit, props.creditId]);

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(creditDetails_table_headers).map(([key, _value], index) => {
                        return <th key={index}>{key}</th>
                    })}
                </tr>
            </thead>
        )
    }

    if (errorStatus === 401) {
        return <Navigate to="/login" />
    }

    return (
        <div id="creditDetails" className="page">
            <Container>
                <h2>{props.creditId}</h2>
                <div>
                    <Table striped>
                        {TableHeaders()}
                        <tbody>
                            {(isError) ? (<tr><td>There was an error...</td></tr>) : (null)}
                            {(isLoading) ? (
                                <tr><td><Loading /></td></tr> 
                            ) : (
                                credit.data.map((item, index) => 
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.order_ids}</td>
                                        <td>{item.products}</td>
                                        <td>${item.prices_discounted}</td>
                                        <td>{item.qtys}</td>
                                        <td>${(Number(item.prices_discounted) * Number(item.qtys)).toFixed(2)}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="5" style={{textAlign: "end"}}>Subtotal:</td>
                                <td>${credit.refund_subtotal}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{textAlign: "end"}}>Tax:</td>
                                <td>${(Number(credit.refund_total) - Number(credit.refund_subtotal)).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{textAlign: "end"}}>Credit:</td>
                                <td>${credit.refund_total}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </Container>
        </div>
    )
}

export default CreditDetails;