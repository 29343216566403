/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: August 4th, 2020
 * 
 *  Description: This component is used to show the user they are not authorized for the page they are trying to access
 * 
 *****************************************************************************************************/

import React from 'react';

function NotAuthorized() {
    return (
        <div id="not-authorized" className="page">
            <div className="container-page">
                <h1>Not Authorized 401</h1>
            </div>
        </div>
    )
}

export default NotAuthorized;
