/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: The component that will show a list of drafts that can create and delete drafts
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useCallback } from "react";
import { OrderDraft } from './components';
import { Routes, Route, Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import PrivateRoute from "../../../PrivateRoute";
import { v4 as uuidv4 } from 'uuid';
import './styles/new-order.css';
import axios from "axios";
import { getDraftsApiURL, draftApiURL } from '../../../context/addresses.js';
import qs from 'qs';
import { useAuth } from '../../../context/auth.js';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../../../context/loading";

// Switch to the draft that was clicked
export const SwitchDraft = () => {
    let location = useLocation();
    return (
        <OrderDraft key={location.pathname.split('/')[2]} draftNum={location.pathname.split('/')[2]} />
    )
}

function NewOrder() {
    let auth = useAuth(); // user info
    let location = useLocation();
    const [draftNum, setDraftNum] = useState(); // Get the drafts
    const [errorStatus, setErrorStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // When there is noDrafts casued by first time login or deletion of a draft after order make a new empty draft
    const noDraft = useCallback(async () => {
        setIsLoading(true);

        try {
            console.log('here')
            await axios.post(draftApiURL, qs.stringify({ user: auth.user.username, drafthash: uuidv4(), shipto: '', shipping: '', po: '', coupon:'', mynotes: '', maxnotes: '', draftitems: [{ products: "", des: "", qty: 0 }] }), { Session: {}, withCredentials: true });
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("noDraft() failed: " + error.message);
        }

        setIsLoading(false);
    }, [auth.user.username])

    // Get the user's drafts
    const fetchData = useCallback(async () => {
        setIsLoading(true);

        try {
            await axios.get(getDraftsApiURL, { Session: {}, withCredentials: true }).then(async (response) => {
                console.log(response)
                if (response.data.length > 0) {
                    setDraftNum(response.data);
                } else {
                    console.log('here', response.data.length)
                    await noDraft();
                    console.log("refresh")
                    await fetchData();
                }
            });
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("getDraftsApiURL Api call failed: " + error.message);
        }

        setIsLoading(false);
    }, [noDraft])

    useEffect(() => {
        document.title = 'New Order - Max Advanced Brakes Wholesale';
        fetchData();
    }, [fetchData]);

    // checks for the draft that matches the url
    function findDraftHash(item) { 
        return item.drafthash === location.pathname.slice(11); 
    }
    
    // Delete the current draft
    async function deleteDraftUrl() {
        if (draftNum && (draftNum !== undefined || draftNum.length > 1)) {
            try {
                await axios.delete(`${draftApiURL}/${location.pathname.slice(11)}`, { Session: {}, withCredentials: true });
                // Redirect after delete draft
                if (draftNum && draftNum.indexOf(findDraftHash) === -1) await fetchData();
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }

                console.log("deleteDraftUrl() failed: " + error.message);
            }
        }
    }

    // Create a new draft
    async function postDraft() {
        if (draftNum && (draftNum === undefined || draftNum.length < 5)) {
            try {
                console.log('test')
                await axios.post(draftApiURL, qs.stringify({ user: auth.user.username, drafthash: uuidv4(), shipto: '', shipping: '', po: '', coupon:'', mynotes: '', maxnotes: '', draftitems: [{ products: "", des: "", qty: 0 }] }), { withCredentials: true });
            
                await fetchData();
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }

                console.log("postDraft() failed: " + error.message);
            }
        }
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="new-order-cart" className="page">
            <Container>
                <Row className="text-align-right mb-3">
                    <Col md={10}>
                        <Button variant="secondary" type="button" onClick={postDraft} disabled={(onclick) ? (true) : (false)}>Start Another draft</Button>
                    </Col>
                    <Col>
                        <Button variant="danger" className={`${(draftNum && draftNum.length === 1) ? ("d-none") : ("d-block")}`} type="button" onClick={deleteDraftUrl}>
                            <Link className="text-white" to="/new-order">Delete draft</Link>
                        </Button>
                    </Col>
                </Row>
                <div id="drafts">
                    <table>
                        <tbody>
                            <tr id="newOrderDrafts">
                                {(isLoading) ? (
                                    <td><Loading /></td>
                                ) : (
                                    draftNum && draftNum.map((item, index) => {
                                        let active = location.pathname === `/new-order/${item.drafthash}` ? 'active' : '';
                                        return (
                                            <td className={`draftTab`} key={"draft-" + index}>
                                                <Link className={`${active}`} to={`/new-order/${item.drafthash}`}>
                                                    <span>{item.date_created}</span>
                                                </Link>
                                            </td>
                                        )
                                    })
                                )}
                            </tr>
                        </tbody>
                    </table>
                    <hr className="mt-0" />
                    <h4 className={`text-align-center ${(location.pathname.match(/\/new-order\//) && location.pathname.match(/\/new-order\//)[0] === "/new-order/") ? ("d-none") : ("d-block")}`}>Please Select a Draft</h4>
                    <Outlet />
                    <Routes>
                        <Route
                            path=":draftNum" 
                            element={
                                <PrivateRoute>
                                    <SwitchDraft />
                                </PrivateRoute>
                            } 
                        />
                    </Routes>
                </div>
            </Container>
        </div>
    );
}

export default NewOrder;
