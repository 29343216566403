/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: The component that will show the users credit
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Navigate, Link, useLocation } from "react-router-dom";
import CreditDetails from '../credit-details/credit-details';
import './styles/credit.css';
import { useAuth } from '../../../context/auth';
import { usersApiURL, creditUserAPI, creditApiURL, returninvoice } from '../../../context/addresses.js';
import Loading from '../../../context/loading.js';
import { credit_table_headers } from "../../../context/orderModels.js";
import download from '../../../styles/pictures/download.png';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import useSortableData from "../../sortTable/useSortableData";
import { useCallback } from "react";

export const CreditsDetails = () => {
    let location = useLocation();
    return <CreditDetails creditId={location.pathname.split('/')[2]} />;
}

function Credit() {
    let auth = useAuth(); // Get the current user data
    const [accounts, setAccounts] = useState([]); // Get the list of accounts for filter
    const [credit, setCredit] = useState([]); // The list of credit
    const [isLoading, setIsLoading] = useState(false); // loading data
    const [isError, setIsError] = useState(false); // There was an error getting api
    const [errorStatus, setErrorStatus] = useState([]); // Get the error status
    const [page, setPage] = useState(0); // What the page number is
    const [username, setUsername] = useState(""); // The username used for filter
    const { items, requestSort } = useSortableData(credit);
    const [creditBalance, setCreditBalance] = useState(0);
    const prevButtonRef = useRef();
    const nextButtonRef = useRef();
    const customerRef = useRef();

    const fetchCredit = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);
        setUsername("");

        try {
            const response = await axios.get(creditApiURL + 0 + `&&perpage=${30}`, { Session: {}, withCredentials: true });

            buttonToDisable(response.data.length, 0)

            setCredit(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("fetchCredit() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        document.title = 'Credit History - Max Advanced Brakes Wholesale';
        fetchCredit();
    }, [fetchCredit]);

    useEffect(() => {
        if (auth.user.access !== 5) fetchAccount();
    }, [auth])

    const fetchAccount = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios(`${usersApiURL}?perpage=200`, { Session: {}, withCredentials: true });

            response.data = response.data.sort((a, b) => {
                return ("" + a.company_name).localeCompare("" + b.company_name);
            })
            setAccounts(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
             
            console.log("fetchAccount() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    const fetchUserCredit = async () => {
        try {
            const response = await axios.get(creditUserAPI, { 
                params: {
                    username: username,
                    page: page,
                    perpage: 30
                },
                Session: {},
                withCredentials: true 
            });
        
            buttonToDisable(response.data.length, page);

            setCredit(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("fetchUserCredit() failed: " + error.message);
            setIsError(error);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        fetchUserCredit();
    }

    // What buttons to disable depending on conditions
    function buttonToDisable(arrLength, pageNum) {
        if (pageNum === 0 && arrLength < 30) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = true;
        } else if (pageNum === 0) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = false;
        } else if (arrLength < 30) {
            prevButtonRef.current.disabled = false;
            nextButtonRef.current.disabled = true;
        } else {
            nextButtonRef.current.disabled = false;
            prevButtonRef.current.disabled = false;
        }
    }

    // When the clear button is clicked
    function handleClear() {
        setUsername('');
        customerRef.current.value = "";
        setPage(0);
    }

    function handleNext() {
        if (1 <= page) setPage(page + 1);
        else setPage(1);
    }

    function handlePrevious() {
        if (page <= 0) return;
        else setPage(page - 1);
    }

    // Get the credit balance
    useEffect(() => {
        let bal = credit.reduce((sum, item) => sum + Number(item.refund_total), 0)
        setCreditBalance(bal);
    }, [credit])

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(credit_table_headers).map(([key, value], index) => {
                        return <th key={index} className={(value !== "") ? ("sort") : ("value")} onClick={() => requestSort(value)}>{key}</th>
                    })}
                </tr>
            </thead>
        )
    }

    function customerSelectOptions() {
        return (
            isLoading ? (
                <option><Loading /></option>
            ) : (
                accounts.map((item, index) => <option value={item.username} key={index}>{item.company_name} ({item.firstname} {item.lastname})</option>)
            )
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="credit-returns" className="page">
            <Container>
                <h1>Credit</h1>
                <br />
                {isError && <div>There was an error</div>}
                <Form className="mb-3" onSubmit={(event) => handleSubmit(event)}>
                    {(auth.user.access !== 5) ? (
                        <Row>
                            <Form.Label column md={1}>Customer:</Form.Label>
                            <Form.Group as={Col} md={6} controlId="cusName">
                                <Form.Select id="cusName" ref={customerRef} placeholder="Customer Name" onChange={(e) => { setUsername(e.target.value); }}>
                                    <option value=""></option>
                                    {customerSelectOptions()}
                                </Form.Select>
                            </Form.Group>
                            <Col>
                                <Button variant="warning" type="submit">Search</Button>{' '}
                                <Button variant="secondary" type="submit" onClick={handleClear}>Clear</Button>
                            </Col>
                        </Row>
                    ) : (null)}
                    <Row>
                        <Col>
                            <Button variant="secondary" ref={prevButtonRef} type="submit" onClick={handlePrevious}>Previous</Button>
                        </Col>               
                        <Col className="text-align-right">         
                            <Button variant="secondary" ref={nextButtonRef} type="submit" onClick={handleNext}>Next</Button>
                        </Col>
                    </Row>
                </Form>
                {(auth.user.access === 5 || (auth.user.access !== 5 && username !== "")) ? (
                    <Form.Label>Credit Balance: ${(creditBalance).toFixed(2)}</Form.Label>
                ) : (null)}
                <Table striped>
                    {TableHeaders()}
                    <tbody>
                        {(isError && (errorStatus === 401 || errorStatus === 403)) ? (
                            <Navigate to="/notAuhtorized" />
                        ) : (
                            isError && <tr><td>There was an error getting orders</td></tr>
                        )}
                        {isLoading ? (
                            <tr><td><Loading /></td></tr>
                        ) : (
                            items.map((item, index) => {
                                let lineIndex = (Number(page) * 30) + index + 1;

                                return (
                                    <tr key={index}>
                                        <td>{lineIndex}</td>
                                        <td>
                                            <Link to={`/credit/${item.return_id}`}>{item.return_id}</Link>
                                        </td>
                                        <td>
                                            <a href={returninvoice + item.return_id} target="_blank" rel="noopener noreferrer">
                                                <img width="20" height="22" src={download} alt="download" />
                                            </a>
                                        </td>
                                        <td>{item.username}</td>
                                        <td>{item.date_created}</td>
                                        <td>${item.refund_total}</td>
                                    </tr>
                                )
                            })
                        )}
                    </tbody>
                </Table>
            </Container>
        </div>
    );
}

export default Credit;
