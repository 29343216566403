import React, { useState, useEffect,useRef,forwardRef } from 'react';
import axios from 'axios';
import {serverAPI} from '../../../context/addresses.js'
import './styles/couponpage.css';
// import  * as models  from '../../../context/js';
import {coupon_fields, html_to_db, db_to_html,  mapper}  from '../../../context/es6models.js';
// const models = require('../../../context/js');

// const models = require('../../../context/models.js');

function CouponOverlay(){
    const [select,setSelect]=useState({})
}

const CouponRow= forwardRef((props,ref)=> {
    const [couponState, setCouponState] = useState(() => {

        return mapper(props.coupon, coupon_fields, db_to_html);
    });
    useEffect(()=>{
        if(props.upcb){
            props.upcb(couponState);
        }
    },[couponState]);

    function deleteHandler(evt) {
        props.remcb(couponState.id);
    }

    function addHandler(evt) {
        // Random coupon is generated serverside if coupon is blank
        couponState.enabled=true;
        props.addcb(mapper(couponState, coupon_fields, html_to_db));
        // Reset to empty
        setCouponState(Object.keys(coupon_fields).reduce((a, c) => { a[c] = ''; return a; }, {}));
    }
    function changeHandler(evt) {
        let o = {};
        if(evt.target.type==='checkbox'){
            o[evt.target.dataset.key]=evt.target.checked;
            couponState.enabled=evt.target.checked;
            props.addcb(mapper(couponState, coupon_fields, html_to_db));
        } else{
            o[evt.target.dataset.key] =  evt.target.value;
        }

        setCouponState({ ...couponState, ...o });
    }
    return (
        <tr ref={ref}>{
            Object.keys(couponState).map((key) => {
                if (key in coupon_fields && key !== 'id' ) {
                    if(props.add && key==='enabled'){
                        return <td key={key}></td>
                    }
                    // type="{coupon_fields[key]}"
                    return <td key={key}>
                        <input placeholder={(key=='coupon')?'auto':''} disabled={(props.add || key=='enabled')?false:true} min={0} className={key} type={coupon_fields[key]} data-key={key} checked={couponState[key]} value={couponState[key]} onChange={changeHandler} ></input>
                    </td>
                }
                else return null;
            }

            )

        }
            <td><button onClick={(props.add) ? addHandler : deleteHandler}>{(props.add) ? 'Add' : 'X'}</button></td>
            </tr>
    );
})
const CouponTableHeaders = () => {
    return <thead><tr>
        {Object.keys(coupon_fields).map((k) => {
            if (k === 'id') return null;
            let name = k.replace('_', ' ').split(' ').map((e) => { return e.substr(0, 1).toUpperCase() + e.substr(1) }).join(' ');

            return <th key={name}>{name}</th>
        })}
    </tr></thead>
}

function CouponPage() {
    const [coupons, setCoupons] = useState([]);
    const [total, setTotal] = useState(0);
    const addComponent=useRef();

    useEffect(() => {
        getCoupons();
    }, []);

    useEffect(()=>{
        setTotal(coupons.length?coupons.reduce((a,c)=>{return a+parseFloat(c.discount)},0):0);
    },[coupons]);

    function updateCoupon(val){
        console.log(val);
        setCoupons(coupons.map((item)=>{
            return (item.id===val.id)?val : item;
        }));
    }

    function getCoupons() {
        axios.get(
            serverAPI+'coupons', {
            withCredentials: true
        })
            .then((res) => {
                console.log(res);
                if (res.data)
                    setCoupons(res.data);
            }).catch((err) => {
                console.log(JSON.stringify(err));
            });
    }
    function addCoupon(data) {
        axios.post(
            serverAPI+'coupon',
            data
            , {
                withCredentials: true
            }).then((res) => {
                if (res.status < 400) {
                    getCoupons();
                    addComponent.current.classList.remove('error');
                } else{
                    addComponent.current.classList.add('error');
                }
            }).catch((err) => {
                addComponent.current.classList.add('error');
                console.log(JSON.stringify(err));
            });
    }

    function removeCoupon(id) {
        axios.delete(serverAPI+'coupon/' + id, { withCredentials: true }).then((res) => {
            if (res.status < 400) {
                getCoupons();
            }
        }).catch((err) => {
            console.log(JSON.stringify(err));
        });
    }
    function handleAdd(data) {
        delete data.id;

        addCoupon(data);
    }


    return (
        <div id="couponpage" className="page">
            <div className="container">

                <table className='coupontable'>
                    <CouponTableHeaders></CouponTableHeaders>
                    <tbody>
                        <CouponRow ref={addComponent} coupon={Object.keys(coupon_fields).reduce((a, c) => { a[c] = ''; return a; }, {})} addcb={handleAdd} add={1}></CouponRow>
                        {coupons.length ? coupons.map((coup) => {
                            return <CouponRow key={coup.id} coupon={coup} remcb={removeCoupon} addcb={addCoupon} upcb={updateCoupon}></CouponRow>
                        }) : null
                        }
                        <tr><td>{total}</td></tr>

                    </tbody>
                </table>

            </div>
        </div>

    );

}

export default CouponPage;
