import Form from 'react-bootstrap/Form';

function GlobalInputAddAccessory({ objectValues, setObjectValues, property, min, type, rowid }) {
    
    function handleChange(e, rowid) {
        const newArr = [...objectValues];
        newArr.forEach((accessory, index) => {
            if (index === rowid) {
                accessory[property] = (isNaN(e.target.value)) ? (e.target.value) : (Number(e.target.value));
            }
        })
        console.log(newArr)
        setObjectValues(newArr);
    }

    return (
        <Form.Control className="form-control" type={type} min={min} onChange={(e) => handleChange(e, rowid)} />
    )
}

export default GlobalInputAddAccessory;