/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The terms for Rules and Regulation
* 
*****************************************************************************************************/

function RulesAndRegulations() {
    return (
        <>
            <div className="terms-header">
                <h3>Rules And Regulations</h3>
            </div>
            <div className="terms-content row">
                <div className="terms-columns">
                    {/* <div className="terms-column row">
                        <p className="align-self-start">MaxBrakes's web sites and web site services are provided to you subject to the following notices, terms, and conditions. In addition, when you use any MaxBrakes web site or web site service (e.g., parts purchasing), you will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service.  By accessing and using any of the web sites owned or controlled by MaxBrakes.com or its subsidiaries or affiliates (the "Web Sites"), you agree to be legally bound by all of the terms and conditions of use. If you do not agree with any of the terms and conditions set forth herein, do not use any of Web Sites and do not place orders through any of Web Sites.</p>
                    </div>
                    <ul>
                        <li><span href="#copyright">Copyright</span></li>
                        <li><span href="#use">Use of Site</span></li>
                        <li><span href="#notices">Notices Regarding Software‚ Documents and Services Available on this Web Site</span></li>
                        <li><span href="#noticesand">Notices and Procedure	for Making Claims of Copyright Infringement</span></li>
                        <li><span href="#reviews">Reviews‚ Feedback and Comments</span></li>
                        <li><span href="#links">Links to Third Party Sites</span></li>
                        <li><span href="#framing">Framing</span></li>
                        <li><span href="#unsolicited">Unsolicited Idea	Submission Policy</span></li>
                        <li><span href="#offers">Online Offers &amp; Specials</span></li>
                        <li><span href="#trademarks">Trademarks</span></li>
                        <li><span href="#applicable">Applicable Law</span></li>
                        <li><span href="#our">Our Address</span></li>
                        <li><span href="#notice">Copyright Notice</span></li>
                    </ul>
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>COPYRIGHT</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div>
                        <p>All content included on the Web Sites, such as text, graphics, logos, button icons, images, audio clips, and software, is the owned or licensed property of MaxBrakes.com/prozone, or its affiliates (collectively, "MaxBrakes") or its content suppliers and protected by Canada and international copyright laws. The compilation (meaning the collection, arrangement, and assembly) of all content on the Web Sites is the exclusive property of MaxBrakes and is protected by Canada and international copyright laws as well as by provincial and federal trade dress laws. All software used on the Web Sites is the property of MaxBrakes or its software suppliers and protected by Canada and international copyright laws. The content and software on the Web Sites may be used as a shopping and automotive information resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the content on the Web Sites is strictly prohibited. You may not copy, download, reproduce, modify, publish, distribute, transmit, transfer or create derivative works from the content, nor any portion thereof, without first obtaining written permission from MaxBrakes. MaxBrakes neither warrants nor represents that your use of the content and materials displayed on the Web Sites will not infringe rights of third parties.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>Use of Site</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">Neither the Web Sites nor any portion of the Web Sites may be reproduced, duplicated, copied, sold, resold, or otherwise exploited for any commercial purpose that is not expressly permitted by MaxBrakes. MaxBrakes reserves the right to refuse service, terminate accounts, and/or cancel orders in its discretion, including, without limitation, if MaxBrakes believes that customer conduct violates applicable law or is harmful to the interests of MaxBrakes. Any framing of the contents of any of the Web Sites without the prior written permission of MaxBrakes is strictly prohibited.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>NOTICES REGARDING SOFTWARE‚ DOCUMENTS AND SERVICES AVAILABLE ON THIS WEB SITE</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">MaxBrakes and its suppliers make no representation about the suitability of the information contained in the documents, information and related graphics published on this server for any purpose. All documents, information and related graphics are provided "as is" without warranty of any kind. MaxBrakes and its suppliers hereby disclaim all warranties and conditions with regard to this information, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement. In no event shall MaxBrakes or suppliers be liable for any special, indirect or consequential damages or any damages whatsoever resulting from loss of use, data or profits, whether in an action of contract, negligence or other tortious action, arising out of or in connection with the use or performance of software, documents, provision of or failure to provide services, or information available from the Web Sites or the servers which host the Web Sites. In the event that relevant applicable provincial law does not allow the limitation of liability as set forth above (so that this limitation of liability does not apply to you), then MaxBrakes's aggregate liability under such circumstances for liabilities that otherwise would have been limited shall not exceed sixty three dollars and twenty cents of the currency of Canada (CA$63.20).</p>
                        <p className="align-self-start">The documents, information and related graphics published on the Web Sites or the servers which host the Web Sites could include technical inaccuracies or typographical errors. Changes are periodically added to the information herein. MaxBrakes and its suppliers may make improvements or changes in the product(s) and/or service(s) described on the Web Sites at any time. Further, MaxBrakes assumes no responsibility and shall not be liable for any damages to, or resulting from any viruses that may infect, your computer equipment or other property on account of your access to, use of, or browsing in the Web Sites, or your downloading of any materials, data, text, images, video, or audio from the Web Sites.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>NOTICES AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">Not available</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>REVIEWS‚ FEEDBACK AND COMMENTS</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">MaxBrakes may allow visitors to its site to post reviews, comments, and other content to the Web Sites. If you post reviews, comments, or other content on any of the Web sites or send feedback, questions or comments to MaxBrakes, you grant MaxBrakes a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such reviews, comments, or other content throughout the world in any media. You also grant MaxBrakes and sublicensees the right to use the name that you submit with any review, comment, or other content, if any, in connection with such review, comment, or other content. You represent and warrant that you own or otherwise control all of the rights to the reviews, comments and other content that you post on this site and that use of your reviews, comments, or other content by MaxBrakes will not infringe upon or violate the rights of any third party.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>LINKS TO THIRD PARTY SITES</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">Some links will let you leave the Web Sites. The linked sites are not under the control of Maxbrakes and Maxbrakes is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. Maxbrakes is not responsible for webcasting or any other form of transmission received from any linked site. Maxbrakes is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Maxbrakes of the site.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>FRAMING</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">MaxBrakes expressly does not permit the framing, in whole or in part, of any content contained within the Web Sites.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>UNSOLICITED IDEA SUBMISSION POLICY</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">MaxBrakes does not accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions, new products or technologies, processes, materials, marketing plans or new product names. Please do not send any original creative artwork, samples, demos, or other works. The sole purpose of this policy is to avoid potential misunderstandings or disputes when MaxBrakes's products or marketing strategies might seem similar to ideas submitted to MaxBrakes. Please do not send your unsolicited ideas to MaxBrakes or anyone at MaxBrakes. If, despite this request, you still send any of your ideas or concepts, MaxBrakes makes no assurances that your ideas and materials will be treated as confidential or proprietary.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>ONLINE OFFERS & SPECIALS</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">The terms and conditions of the offers and specials on the Sites may vary. Terms and conditions for individual offers and specials on each Site will be found (i) as part of the offer on the Site, (ii) for retail store offers, as part of the store circular, or (iii) in the offer collateral that is available from the Commercial Sales Representative or the local commercial store.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>TRADEMARKS</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">Max Advanced Brakes, MaxBrakes, and other MaxBrakes graphics, logos, and service names referenced herein are either service marks or trademarks or registered trademarks or service marks which are owned by or licensed to MaxBrakes. These trademarks may not be used in connection with any product or service that is not MaxBrakes's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits MaxBrakes. All other trademarks not owned by MaxBrakes or its subsidiaries or affiliates and which appear on the Web Sites are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by MaxBrakes. Google Play and the Google Play logo are trademarks of Google LLC. Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc., registered in the U.S. and other countries. All other trademarks and service marks appearing on the Web Sites are the property of their respective owners.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>APPLICABLE LAW</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">This site is created and controlled by MaxBrakes.com/prozone in the Province of Ontario, CA. As such, the laws of the Province of Ontraio will govern these disclaimers, terms, and conditions, without giving effect to any principles of conflicts of laws. MaxBrakes.com/prozone reserves the right to make changes to this site and these disclaimers, terms, and conditions at any time.</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>OUR ADDRESS</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">
                            MaxBrakes.com <br/>
                            Markham, ON L6C3A1 <br />
                            <a href="https://maxbrakes.com" target="_blank" rel="noreferrer">
                                https://maxbrakes.com
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                                    <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                    <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                                </svg>
                            </a>
                        </p>
                    </div>
                    <div className="terms-column row">
                        <p>Any rights not expressly granted herein are reserved.</p>
                    </div>
                    <div className="terms-column row">
                        <p>Contact <a target="_blank" rel="noreferrer" href="mailto:info.ws@maxbrakes.com">info.ws@maxbrakes.com
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                                    <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                    <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                                </svg>
                            </a> with any questions or problems, as appropriate.</p>
                    </div>
                    <div className="terms-column row">
                        <p>Any references to "MaxBrakes" on this page shall include MaxBrakes.com, and its affiliates and subsidiaries, including, but not limited to, MaxBrakes, as the context may require. MaxBrakes.com is a separate and unique entity existing apart from MaxBrakes  As used herein, an affiliate of MaxBrakes.com is defined as any entity that is controlled by, controls or is under common control of MaxBrakes.com</p>
                    </div>
                    <hr />
                    <div className="terms-column row">
                        <span className="col-md align-self-start"><h5>Copyright Notice</h5></span>
                        <span className="col-md-2 align-self-start">Back to Top</span>
                    </div>
                    <div className="terms-column row">
                        <p className="align-self-start">©2020-2021 MaxBrakes.com, Markham, ON L6C3A1 CA. All rights reserved.</p>
                    </div> */}
                </div>
            </div>
        </>
    )
}
 
 export default RulesAndRegulations;