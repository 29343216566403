import { useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// get the note for orderinfo
function Notes({ updateNotes }) {
    const displayMyNotes = useRef();
    const displayNoteToMax = useRef();

    function handleClick(event) {
        if (event.target.id !== "my-notes") {
            displayMyNotes.current.style.display = "none";
            displayNoteToMax.current.style.display = "block"
        } else {
            displayMyNotes.current.style.display = "block";
            displayNoteToMax.current.style.display = "none";
        }
    }

    return (
        <>
            <div className="notes-tab">
                <Button id="my-notes" variant="secondary" onClick={event => handleClick(event)}>My Notes</Button>{' '}
                <Button id="notes-to-max-brakes" variant="secondary" onClick={event => handleClick(event)}>Notes to Max Brakes</Button>
            </div>
            <hr />
            <div ref={displayMyNotes} id="my-notes-textarea" style={{display: "block"}}>
                <textarea className='form-control' maxLength="960" onKeyUp={event => updateNotes('MyNotes', event.target.value)}></textarea>
                <Form.Text muted>This note <u>will not</u> be read by Max Brakes and will be printed on all documents related to this order.</Form.Text>
            </div>
            <div ref={displayNoteToMax} id="notes-to-max-brakes-textarea" style={{display: "none"}}>
                <textarea className='form-control' maxLength="1000" onKeyUp={event => updateNotes('MaxNotes', event.target.value)}></textarea>
                <Form.Text muted>Max Brakes <u>will acknowledge</u> this note and take suitable actions. This note won't be printed on any document. The nature of the note may cause a <u>delay</u>.</Form.Text>
            </div>
        </>
    )
}

export default Notes;