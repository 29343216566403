import Form from 'react-bootstrap/Form';

function GlobalInputAccessory({ objectValues, setObjectValues, property, min, type, rowid, defaultValue }) {

    function handleChange(e, rowid) {
        const newArr = [...objectValues];
        newArr.forEach((accessory, index) => {
            if (index === rowid) {
                accessory.parts.forEach((x) => {
                    x[property] = Number(e.target.value);
                });
            }
        })
        setObjectValues(newArr);
    }

    return (
        <Form.Control type={type} min={min} defaultValue={defaultValue} onChange={(e) => handleChange(e, rowid)} />
    )
}

export default GlobalInputAccessory;