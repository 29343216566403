/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date Created: June 25th, 2020
 * 
 *  Description: Entry Point for App
 * 
 *****************************************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';

console.log(`${process.env.REACT_APP_VERSION}`)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
