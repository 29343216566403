const order_fields = {
    'username': 'text', 'custom_po': 'text','ship_method':'text', 'notes': 'text', coupon: 'array',
    items: { order_id: 'number', product: 'text', qty: 'number', price: 'number', price_discounted: 'number', pcode: 'text',
    qty_shipped:'number',qty_returned:'number',description:'text' }
};
const order_old_fields = {
    username: (x) => { return x.username; }, custom_po: (x) => { return x.custom_po; },
    ship_method: (x) => { return x.ship_method; },
    notes: (x) => { return x.notes; }, date_created: (x) => { return x.date_created },
    cost_subtotal: (x) => { return x.price_subtotal; }, cost_total: (x) => { return x.price_total; },
    cost_final: (x) => { return x.price_final; },
    price_subtotal: (x) => { return x.price_subtotal; }, price_total: (x) => { return x.price_total; },
    price_final: (x) => { return x.price_final; },
    pcodes: (x) => { return x.items.map((v, i) => { return v.pcode }).join(","); },
    products: (x) => { return x.items.map((v, i) => { return v.product }).join(","); },
    qtys: (x) => { return x.items.map((v, i) => { return v.qty }).join(","); },
    qtys_shipped: (x) => { return x.items.map((v, i) => { return v.qty_shipped }).join(","); },
    qtys_returned: (x) => { return x.items.map((v, i) => { return v.qty_returned }).join(","); },
    prices: (x) => { return x.items.map((v, i) => { return v.price }).join(","); },
    prices_discounted: (x) => { return x.items.map((v, i) => { return v.price_discounted }).join(","); },
    rebate_applied: (x) => { return x.rebate_applied; },
    'new':(x)=>{return 1;}
};
const return_old_fields = {
    order_ids: (x) => { return x.order_id },
    username: (x) => { return x.username },
    date_created: (x) => { return x.date_created },
    refund_subtotal: (x) => { return x.refund_subtotal },
    refund_total: (x) => { return x.refund_total },
    products: (x) => { return x.items.map((v, i) => { return v.product }).join(","); },
    qtys: (x) => { return x.items.map((v, i) => { return v.qty }).join(","); },
    prices_discounted: (x) => { return x.items.map((v, i) => { return v.price_discounted }).join(","); }
};
const draft_fields = {
    user: 'text', drafthash: 'text', shipto: 'text', shipping: 'text', po: 'text', coupon: 'text', mynotes: 'text', maxnotes: 'text',
    items: { draftid: 'number', product: 'text', des: 'text', qty: 'number' }
};
const return_fields = {
    username: 'text', refund_subtotal: 'number', refund_total: 'number',order_ids:'text',date_created:'text',
    products:'text',qtys:'text',prices_discounted:'number',
    items: { returnid: 'number', orderitemid: 'number', qty: 'number', price_discounted: 'number' },
    pending: { orderitemid: 'number', qty: 'number',status:'text' }
};
const prices_fields={
    product:'text',net_price:'number',accel_price:'number',price_01:'number',price_02:'number',price_03:'number'
    ,price_04:'number',price_05:'number',price_06:'number',price_07:'number'
    ,price_08:'number',price_09:'number',price_10:'number',price_11:'number',enabled:'number'
    ,maxnumber:'text'
};
const exclusions_fields = { username: 'text', type: 'text', line: 'text', item: 'text' };
const coupon_fields = { id: 'number', enabled: 'checkbox', start_time: 'datetime-local', 
end_time: 'datetime-local', coupon: 'text', discount: 'number', part_type: 'text', part_finish: 'text',
use_limit:'number',group_id:'number' };
const user_fields = {
    username: 'text', password: 'text', firstname: 'text', lastname: 'text', phone_primary: 'text',
    fax: 'text', address: 'text', city: 'text', state: 'text', zip: 'text', country: 'text', email: 'text',
    access: 'number', active: 'number', lang: 'text', company_name: 'text', special_info: 'text',
    terms: 'text', max_qty: 'number', instant_rebate: 'number', credit_limit: 'number', sales_rep: 'text',
    price_set: 'text',balance:'number'
};
const group_settings={
    gid:'number',username:'text',percent:'number',flat:'number'
};
const price_group={
    id:'id',description:'text',
    items:{groupid:'number',gproduct:'text',gprice:'number',gtype:'number'}
};
const discount_fields={
    username: 'text',user_id:'number', ONZ: 'number', SYS: 'number', SCB: 'number',
    SNZ: 'number', OCG: 'number', CDM: 'number', CDP: 'number', MD: 'number', AFN: 'number', bearing: 'number',NCG:'number'}
const prop_fields = {
    username: 'text', ONZ: 'number', SYS: 'number', SCB: 'number',
    SNZ: 'number', OCG: 'number', CNZ: 'number', CDM: 'number', CDP: 'number', MD: 'number', AFN: 'number', maxpad: 'number', bearing:'number',
    maxrotor: 'number', maxbearing: 'number',currentsupplier:'text',partexp:'number',stockinv:'number',estimatedvol:'number',
    yearestablished:'number',termdiscount:'number',maxother:'number',maxonz: 'number', maxsys: 'number', maxscb: 'number',
    maxsnz: 'number', maxocg: 'number', maxcdm: 'number', maxcdp: 'number', maxmd: 'number', maxafn: 'number', maxcnz: 'number',maxncg:'number'
};
const statement_fields={
   username:'text',date_created:'text',invoice_dates:'array',types:'array',invoice_ids:'array',
   cost_totals:'array',balance_subtotal:'number',balance:'number'
}
const newstatement_fields={
    username:'text',date_created:'text',balance_discounted:'number',invoice_dates:'array',types:'array',invoice_ids:'array',
    cost_totals:'array',
    balance_subtotal:'number',balance:'number'
 }
 const statement_item_fields={
    statementid:'number',osubtotal:'number',otax:'number',ototal:'number',invoiceid:'number',otype:'number'
}
const accessrequest_fields={
     firstname: 'text', lastname: 'text', 'company': 'text', "phone": 'text', 'title': 'text', "email": 'text',  currentsupplier: 'text', partexp: 'number', stockinv: 'number', estimatedvol: 'number', yearestablished: 'number', state: 'text' 
}
const oil_fields={
    bucket:'number',part:'string',discount:'number',qty:'number'
}
const OIL_FIRST={
    'CAS':{30:-3,
        // 60:-1.5
    },
    'MOB':{30:-3,
        // 60:-1.5
    }
}
const db_to_html = { text: v => v, 'datetime-local': v => v.replace(' ', 'T'), 'number': v => v || 0, 'checkbox': v => v ? 1 : 0 }
const html_to_db = { text: v => v, 'datetime-local': v => v.replace('T', ' '), 'number': v => v || 0, 'checkbox': v => v ? 1 : 0 }
const html_val = { text: e => e.value, 'datetime-local': e => e.value, 'number': e => (e.value || 0), 'checkbox': e => e.checked }
const mapper = function (obj, fields, map) {
    let o = {};
    for (let k in obj) {
        if (fields[k])
            o[k] = map[fields[k]](obj[k]);
    }
    return o;
}
const ENUM_ACTIVE = { NOT_ACTIVE: 0, ACTIVE: 1, PENDING: 2 };
const ENUM_ACCESS = { ADMIN: 1, DEALER: 3, USER: 5, CSR: 7 };
const PART_TYPES = { pad: 'pads', rotor: 'rotor', bearing: 'bearing', shoe: 'shoe' };
const PART_FINISHES = {
    ONZ: 'Rb', SYS: 'Rb', SCB: 'Rb',
    SNZ: 'Rb', OCB: 'Rb', CDM: 'Pb', CDP: 'Pb', MD: 'S', AFN: 'Pf'
};
// if (typeof module === 'object'){
// module.exports = {
//     order_fields, coupon_fields, user_fields, prop_fields, exclusions_fields,prices_fields,statement_fields,discount_fields,group_settings,
//     price_group,
//     db_to_html, html_to_db, html_val, mapper, ENUM_ACTIVE, ENUM_ACCESS, return_fields, draft_fields, order_old_fields, return_old_fields,
//     PART_TYPES, PART_FINISHES
// }
// } else{
    export {
        order_fields, coupon_fields, user_fields, prop_fields, exclusions_fields,prices_fields,statement_fields,discount_fields,group_settings,
        price_group,statement_item_fields,newstatement_fields,OIL_FIRST,oil_fields,accessrequest_fields,
        db_to_html, html_to_db, html_val, mapper, ENUM_ACTIVE, ENUM_ACCESS, return_fields, draft_fields, order_old_fields, return_old_fields,
        PART_TYPES, PART_FINISHES
    };
// }

