/**************************************************************************************
* Author: Adam Khan
* Date Created: July, 31st, 2020
*
* Description: The component that will show the account details 
*
***************************************************************************************/

import React, { useState, useEffect, useCallback } from 'react';
import axios from "axios";
import { Link, Navigate, useLocation } from 'react-router-dom';
import { userApiURL, exclusionsApiUrl, groupsApiURL, groupSetApiURL, postUserPasswordReset } from '../../../context/addresses';
import qs from 'qs';
import * as XLSX from 'xlsx';
import { useAuth } from '../../../context/auth';
import Loading from '../../../context/loading';
import './styles/account-details.css';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AdminTable from './components/AdminTable';
import SubAdminTable from './components/SubAdminTable';
import AccountsDetailsTable from './components/AccountsDetailsTable';

function AccountDetails() {
    let auth = useAuth();
    let location = useLocation();
    let username = location.pathname.split('/')[3];
    const [user, setUser] = useState([]); // User information
    const [userMod, setUserMod] = useState(user); // Modification to the user
    const [isLoading, setIsLoading] = useState(false); // Check if it is Loading
    const [isError, setIsError] = useState(false); // Check for an error
    const [errorStatus, setErrorStatus] = useState(); // Get error response code
    const [exType, setExType] = useState(""); // Exclusion type
    const [exProductLine, setExProductLine] = useState(""); // Exclusion line
    // const [exProductNum, setExProductNum] = useState(""); // Exclusion product number
    const [exclustionsList, setExclusionsList] = useState(); // Exclusion list
    const [userNotFound, setUserNotFound] = useState(false); // If the user is not found set to true else false
    const [deleteCheck, setDeleteCheck] = useState(false); // Check if account deletion was successful 
    const [groupId, setGroupId] = useState(0);
    const [inputList, setInputList] = useState([{ gid: 0, username: user.username }]); // input list for item discounts group manager
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [globalGroups, setGlobalGroups] = useState(); // Get the global groups
    const params = userMod;

    // Get account info after modification 
    const getUser = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(`${userApiURL}${username}`, { Session: {}, withCredentials: true })

            setUser(response.data);
            setUserMod(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("fetchUser failed: " + error.message);
            setUserNotFound(true);
            setIsError(true);
        }

        setIsLoading(false);
    }, [username])

    // Get exclusions after exclusion for accounts update
    const getExclusions = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await axios.get(`${exclusionsApiUrl}${username}`, { Session: {}, withCredentials: true });
        
            setExclusionsList(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status)
            }

            console.log("FetchExclusions failed: " + error.message);
        }

        setIsLoading(false)
    }, [username])

    const fetchUserGroupSet = useCallback(async () => {
        setIsLoading(true);
        try {
            const responseUserGroups = await axios.get(groupSetApiURL + username, { Session: {}, withCredentials: true });
            const responseGlobalGroups = await axios.get(`${groupsApiURL}`, { Session: {}, withCredentials: true });

            setGlobalGroups(responseGlobalGroups.data);

            if (responseUserGroups.data.length !== 0) setInputList(responseUserGroups.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }
            
            console.log("handleGetGroupSet failed: " + error.message);
        }

        setIsLoading(false);
    }, [username])

    useEffect(() => {
        document.title = `${username} | Account Details - Max Advanced Brakes Wholesale`;
        getUser();
        getExclusions();
    }, [username, getUser, getExclusions]);

    useEffect(() => {
        fetchUserGroupSet();
    }, [user.username, fetchUserGroupSet]);

    // Get exclusions when there is a deletion from exclusion list 
    async function deleteExclusionFromList(id) {
        try {
            await axios.delete(`${exclusionsApiUrl}${id}`, { Session: {}, withCredentials: true });
            await getExclusions();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status)
            }

            console.log("deleteExclusionFromList() failed: " + error.message);
        }
    }

    // Post changes made to exclusion list
    async function postExclusion() {
        if (exType !== "") {
            try {
                await axios.post(exclusionsApiUrl, qs.stringify({ username: user.username, type: exType, line: exProductLine/*, item: exProductNum*/ }), { Session: {}, withCredentials: true });
                // setExProductNum("");
                setExProductLine("");
                await getExclusions();
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status)
                }
                    
                console.log("postExclusion() failed: " + error.message);
            }
        } else {
            alert("Type or Product line is empty");
        }
    }

    // When there is a file to upload
    async function postExclusionBatch(batchLine) {
        if (batchLine && batchLine.type !== '' && batchLine.product !== '') {
            try {
                await axios.post(exclusionsApiUrl, qs.stringify(batchLine), { Session: {}, withCredentials: true });
                await getExclusions();
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status)
                }
                    
                console.log("postExclusionBatch() failed: " + error.message);
            }
        } else {
            alert("Type or Product line is empty");
        }
    }

    // Post modification to account
    async function handleSubmit(event) {
        event.preventDefault();
        setIsError(false);
        setIsLoading(true);
        
        if (params.yearestablished === null) params.yearestablished = 0; 
        if (params.maxpad === null) params.maxpad = 0;
        if (params.maxrotor === null) params.maxrotor = 0;
        if (params.partexp === null) params.partexp = 0;
        if (params.estimatedvol === null) params.estimatedvol = 0;
        if (params.stockinv === null) params.stockinv = 0;

        try {
            await axios.post(userApiURL, qs.stringify(params), { Session: {}, withCredentials: true, });
            await getUser();
            alert("Saved Changes!");
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status)
            }
                
            console.log("handleSubmit() failed: " + error.message);
        }
        setIsLoading(false);
    }

    // Delete account
    async function deleteUser() {
        try {
            await axios.delete(`${userApiURL}${username}`, { Session: {}, withCredentials: true });
            alert("Account Deleted!");
            setDeleteCheck(true);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status)
            }
            
            console.log("deleteUser() failed: " + error.message);
        }
    }

    // Delete group set changes
    async function handleDeleteGroupSet() {
        try {
            const response = await axios.delete(`${groupSetApiURL}/${groupId}`, { Session: {}, withCredentials: true });
            
            if (response.data === "OK") {
                alert("GroupSet Deleted");
                fetchUserGroupSet();
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }
            
            console.log("handleDeleteGroupSet failed: " + error.message);
        }
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    
    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { gid: 0, username: user.username }]);
    };

    // Post group set changes
    async function handleSubmitGroupSet(group) {
        if (group.username === undefined || group.username === "" || group.username === null) group.username = user.username;
        
        try {
            const response = await axios.post(groupSetApiURL, qs.stringify(group), { Session: {}, withCredentials: true });

            if (response.data === "OK") {
                alert("Changes saved");
                fetchUserGroupSet();
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }

            console.log("handleSubmitGroupSet failed: " + error.message);
        }
    }

    async function handleExclusionUpload(event) {
        let file = event.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function(evt) {
            let data = evt.target.result;
            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type:'binary' });
                let parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
                
                for (let i = 1; i < parsed.length; i++) {
                    let tempObj = { username: user.username, type: parsed[i][0], line: parsed[i][1] };
                    postExclusionBatch(tempObj);
                }
            } else {
                let tempArr = data.split('\n');
                tempArr.shift();
                tempArr.pop();
                
                for (let i in tempArr) {
                    let tempSplit = tempArr[i].split(',');
                    let tempObj = { username: user.username, type: tempSplit[0], line: tempSplit[1] };
                    postExclusionBatch(tempObj);
                }
            }
        }
        
        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
        
    }

    function getGroupName(gId) {
        let group;
        if (gId) {
            group = globalGroups.find(obj => obj.id === gId);
        }
        return (group) ? (group.description) : (null);
    }

    async function handleChangePassword(e) {
        e.preventDefault();
        try {
            if (password !== confirmPassword) {
                alert('Passwords do not match');
                return;
            }

            let response = await axios.post(postUserPasswordReset, { username, password }, { Session: {}, withCredentials: true });
            if (response.data === "OK") alert("Password Reset");
        } catch (error) {
            console.log(error);
        }
    }

    // If there is an error status
    if (errorStatus) {
        if (errorStatus === 401) {
            return <Navigate to="/login" />;
        } else if (errorStatus === 404) {
            return <Navigate to="/accounts" />;
        } else {
            console.log(errorStatus);
        }
    }

    return (
        <>
            {userNotFound || deleteCheck ? <Navigate to="/accounts" /> : null}
            <div id="account-details" className="page">
                <Container>
                    <h1>Reset Password</h1>
                    <Form className="mb-3" onSubmit={(e) => handleChangePassword(e)}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type={'password'} name={'password'} onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type={'password'} name={'confirmPassword'} onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="submit" variant="warning">Submit</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Form>
                    <h1>Edit Access</h1>
                    {isError && <div>There was an error getting user ...</div>}
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <Form onSubmit={event => handleSubmit(event)}>
                                <AccountsDetailsTable user={user} userMod={userMod} setUserMod={setUserMod} exclustionsList={exclustionsList} deleteExclusionFromList={deleteExclusionFromList} handleExclusionUpload={handleExclusionUpload} setExType={setExType} setExProductLine={setExProductLine} postExclusion={postExclusion} auth={auth} />
                                {/* {(auth.user.access === 1) ? (
                                    <AdminTable user={user} userMod={userMod} setUserMod={setUserMod} exclustionsList={exclustionsList} deleteExclusionFromList={deleteExclusionFromList} handleExclusionUpload={handleExclusionUpload} setExType={setExType} setExProductLine={setExProductLine} postExclusion={postExclusion} />
                                ) : (
                                    <SubAdminTable user={user} exclustionsList={exclustionsList} />
                                )} */}
                                <Row className="mb-3">
                                    <Col sm={2}>
                                        <Button variant="warning" type="submit" name="save">Save modification</Button>
                                    </Col>
                                    <Col sm={2}>
                                        <Button variant="danger" type="button" onClick={deleteUser}>Delete Account</Button>
                                    </Col>
                                    <Col sm={1}>
                                        <Button variant="secondary"> 
                                            <Link to="/accounts">Cancel</Link>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                    <h3>Group Manager</h3>
                    <Table>
                        <thead>
                            <tr>
                                <th>Current Groups</th>
                                <th>New Groups</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="item-input">
                                <td>
                                    <div>
                                        <ul className="groupset-ul">
                                            {inputList.map((x, _i) => {
                                                return (
                                                    <>
                                                        {(globalGroups) ? (globalGroups.map(item => 
                                                            (item.id === x.gid) ? (
                                                                <li>{item.description}</li>
                                                            ) : (null)
                                                        )) : (null)}
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    {(isLoading) ? <Loading /> : inputList.map((x, i) => {
                                        return (
                                            <Row>
                                                <Form.Label column md={2}>Group Id:</Form.Label>
                                                <Form.Group as={Col}>
                                                    <Form.Select name="gid" value={x.gid} onChange={e => handleInputChange(e, i)}>
                                                        <option value=""></option>
                                                        {(globalGroups) ? (globalGroups.map(item =>
                                                            <option value={item.id}>{item.description}</option>
                                                        )) : (null)}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Col>
                                                    <Button variant="warning" type="button" onClick={() => handleSubmitGroupSet(x)}>Submit Group Set</Button>{' '}
                                                    {inputList.length - 1 === i && <Button variant="secondary" type="button" onClick={handleAddClick}>Add</Button>}
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                    <Row>
                                        <Form.Group as={Col}>
                                            <Form.Select name="deleteGid" value={groupId} onChange={e => setGroupId(e.target.value)}>
                                                <option value="">Choose a group</option>
                                                {(inputList) ? (
                                                    inputList.map(item => <option value={item.id}>{getGroupName(item.gid)}</option>)
                                                ) : (null)}
                                            </Form.Select>
                                        </Form.Group>
                                        <Col md={3}>
                                            <Button variant="danger" type="button" onClick={handleDeleteGroupSet}>Delete GroupSet</Button>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </div>
        </>
    );
}

export default AccountDetails;
