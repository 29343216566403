import Form from 'react-bootstrap/Form';

function GlobalInput({ objectValues, setObjectValues, productLine, defaultValue }) {
    return (
        <Form.Group>
            <Form.Label>{productLine}: </Form.Label>
            <Form.Control type="number" min="0" onChange={(e) => setObjectValues({...objectValues, [productLine]: Number(e.target.value)})} defaultValue={defaultValue} />
        </Form.Group>
    )
}

export default GlobalInput;