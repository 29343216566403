import UserDetails from './UserDetails.js';
import MaxQuantity from './MaxQuantity.js';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ItemDiscountsRow from './ItemDiscountsRow.js';
import { account_details_table_header } from '../../../../context/orderModels.js';

function AccountsDetailsTable({ user, userMod, setUserMod, exclustionsList, deleteExclusionFromList, handleExclusionUpload, setExType, setExProductLine, postExclusion, auth }) {

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(account_details_table_header).map(([_key, value], index) => {
                        return <th key={index}>{value}</th>
                    })}
                </tr>
            </thead>
        )
    }

    return (
        <Table className="table-bordered">
            {TableHeaders()}
            <tbody>
                <tr>
                    <td className="date-field-width">Username</td>
                    <td>{user.username}</td>
                    <td>{user.username}</td>
                </tr>
                <tr>
                    <UserDetails dataField={"First Name"} currentUserSetting={user.firstname} type={"text"} id={"fname"} name={"firstname"} defaultVal={user.firstname} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"Last Name"} currentUserSetting={user.lastname} type={"text"} id={"lname"} name={"lastname"} defaultVal={user.lastname} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"Company Name"} currentUserSetting={user.company_name} type={"text"} id={"company_name"} name={"company_name"} defaultVal={user.company_name} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"Email"} currentUserSetting={user.email} type={"text"} id={"email"} name={"email"} defaultVal={user.email} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <td>Phone Number</td>
                    <td>{user.phone_primary}</td>
                    <td>
                        <Form.Control className="form-control" type="tel" onChange={(e) => setUserMod({...userMod, "phone_primary": e.target.value})} defaultValue={(user.phone_primary) ? (user.phone_primary) : ("")} pattern="[0-9]{3}[0-9]{3}[0-9]{4}" />
                    </td>
                </tr>
                <tr>
                    <UserDetails dataField={"Fax"} currentUserSetting={user.fax} type={"text"} id={"fax"} name={"fax"} defaultVal={user.fax} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"Address"} currentUserSetting={user.address} type={"text"} id={"address"} name={"address"} defaultVal={user.address} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"City"} currentUserSetting={user.city} type={"text"} id={"city"} name={"city"} defaultVal={user.city} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"State/Province"} currentUserSetting={user.state} type={"text"} id={"state"} name={"state"} defaultVal={user.state} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"Zip"} currentUserSetting={user.zip} type={"text"} id={"zip"} name={"zip"} defaultVal={user.zip} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"Country"} currentUserSetting={user.country} type={"text"} id={"country"} name={"country"} defaultVal={user.country} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <UserDetails dataField={"Sales Rep"} currentUserSetting={user.sales_rep} type={"text"} id={"sales_rep"} name={"sales_rep"} defaultVal={user.sales_rep} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <td>Access</td>
                    <td>{user.access}</td>
                    <td>
                        <Form.Select onChange={(e) => setUserMod({...userMod, "access": Number(e.target.value)})} defaultValue={user.access} disabled={(auth.user.access !== 1) ? (true) : (false)}>
                            <option value="1">1 - Admin</option>
                            <option value="3">3 - Subadmin</option>
                            <option value="5">5 - Customer</option>
                            <option value="7">7 - CSR</option>
                        </Form.Select>
                    </td>
                </tr>
                <tr>
                    <td>Active</td>
                    <td>{user.active}</td>
                    <td>
                        <Form.Select onChange={(e) => setUserMod({...userMod, "active": Number(e.target.value)})} defaultValue={user.active}>
                            <option value="0">0 - Not Active</option>
                            <option value="1">1 - Active</option>
                            <option value="2">2 - Pending</option>
                        </Form.Select>
                    </td>
                </tr>
                {/* <tr>
                    <td>Term Discount</td>
                    <td>{user.termdiscount}</td>
                    <td>
                    <Form.Control type="number" name="termdiscount" defaultValue={user.termdiscount} onChange={e => setUserMod({...userMod, [e.target.name]: e.target.value})} min="0" max="1" />
                    </td>
                </tr> */}
                <tr>
                    <td>Product Exclusion</td>
                    <td>   
                        <Table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Line</th>
                                    <th>Item</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(exclustionsList) ? (
                                    exclustionsList.map(item => {
                                        return (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.type}</td>
                                                <td>{item.line}</td>
                                                <td>{item.item}</td>
                                                <td>
                                                    <Button variant="danger" type="button" value={item.id} onClick={e => deleteExclusionFromList(e.target.value)}>Delete</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (null)}
                            </tbody>
                        </Table>
                        <Form.Control type="file" onChange={(e) => handleExclusionUpload(e)} />
                    </td>
                    <td>
                        <Row>
                            <Form.Label column md={1}>Type:</Form.Label>
                            <Form.Group as={Col}>
                                <Form.Select onChange={e => setExType(e.target.value)}>
                                    <option value=""></option>
                                    <option value="coupon">Coupon</option>
                                    <option value="promo">Promo</option>
                                    <option value="term">Term</option>
                                </Form.Select>
                            </Form.Group>
                            {/* <Form.Group as={Col}>
                                <Form.Label>Product Line:</Form.Label>
                                <Form.Select onChange={e => setExProductLine(e.target.value)}>
                                <option value=""></option>
                                <option value="AFN">AFN</option>
                                <option value="ONZ">ONZ</option>
                                <option value="OCB">OCB</option>
                                <option value="SCB">SCB</option>
                                <option value="SNZ">SNZ</option>
                                <option value="SYS">SYS</option>
                                <option value="CDM">CDM</option>
                                <option value="CDP">CDP</option>
                                <option value="MD">MD</option>
                                <option value="bearing">Bearing</option>
                                    <option value="engine-oil">Engine Oil</option>
                                    <option value="brake-cleaner">Brake Cleaner</option>
                                    <option value="windshield-fluid">Windshield Fluid</option>
                                    <option value="oil-absorbent">Oil Absorbent</option>
                                    <option value="gloves">Gloves</option>
                                    </Form.Select>
                                </Form.Group> */}
                            <Form.Label column md={2}>Product:</Form.Label>
                            <Form.Group as={Col}>
                                <Form.Control type="text" onChange={e => setExProductLine(e.target.value)} />
                            </Form.Group>
                            <Col md={2}>
                                <Button variant="warning" type="button" onClick={postExclusion}>Add</Button>
                            </Col>
                        </Row>
                    </td>
                </tr>
                <tr className="item-input">
                    <td>Maximum Quantity</td>
                    <td>
                        <Row>
                            <Col>[maxonz: {user.maxonz}]</Col>
                            <Col>[maxocg: {user.maxocg}]</Col>
                            <Col>[maxscb: {user.maxscb}]</Col>
                        </Row>
                        <Row>
                            <Col>[maxsnz: {user.maxsnz}]</Col>
                            <Col>[maxafn: {user.maxafn}]</Col>
                            <Col>[maxcdm: {user.maxcdm}]</Col>
                        </Row>
                        <Row>
                            <Col>[maxcdp: {user.maxcdp}]</Col>
                            <Col>[maxmd: {user.maxmd}]</Col>
                            <Col>[maxbearing: {user.maxbearing}]</Col>
                        </Row>
                        <Row>
                            <Col>[maxcnz: {user.maxcnz}]</Col>
                            <Col>[Other: {user.maxother}]</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <MaxQuantity id={"maxonz"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxonz)} />
                            <MaxQuantity id={"maxocg"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxocg)} />
                            <MaxQuantity id={"maxscb"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxscb)} />
                        </Row>
                        <Row>
                            <MaxQuantity id={"maxsnz"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxsnz)} />
                            <MaxQuantity id={"maxafn"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxafn)} />
                            <MaxQuantity id={"maxcdm"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxcdm)} />
                        </Row>
                        <Row>
                            <MaxQuantity id={"maxcdp"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxcdp)} />
                            <MaxQuantity id={"maxmd"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxmd)} />
                            <MaxQuantity id={"maxbearing"} userMod={userMod} setUserMod={setUserMod} defaultVal={user.maxbearing} />
                        </Row>
                        <Row>
                            <MaxQuantity id={"maxcnz"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxcnz)} />
                            <MaxQuantity id={"maxother"} userMod={userMod} setUserMod={setUserMod} defaultVal={Number(user.maxother)} />
                        </Row>
                    </td>
                </tr>
                <tr>
                    <UserDetails dataField={"Terms"} currentUserSetting={user.terms} type={"number"} id={"terms"} name={"terms"} defaultVal={user.terms} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                <tr>
                    <td>Credit Limit</td>
                    <td>{user.credit_limit}</td>
                    <td>
                        <Form.Control type="number" onChange={(e) => setUserMod({...userMod, "credit_limit": e.target.value})} defaultValue={user.credit_limit} min="0.00" step="0.01" />
                    </td>
                </tr>
                <tr>
                    <UserDetails dataField={"Credit balance"} currentUserSetting={user.balance} type={"number"} id={"balance"} name={"balance"} defaultVal={user.balance} userMod={userMod} setUserMod={setUserMod} />
                </tr>
                {/* <tr>
                    <td>Instant Rebate</td>
                    <td>{user.instant_rebate}</td>
                    <td>
                        <Form.Control type="number" name="active" onChange={(e) => setUserMod({...userMod, "instant_rebate": Number(e.target.value)})} defaultValue={user.instant_rebate} min="0.00" step="0.01" />
                    </td>
                </tr> */}
                <tr>
                    <UserDetails dataField={"Price List"} currentUserSetting={user.price_set} type={"text"} id={"price_set"} name={"price_set"} defaultVal={user.price_set} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                </tr>
                <tr className="item-input">
                    <td>Category Discounts</td>
                    <td>
                        <Row>
                            <Col>[ONZ: {user.ONZ}]</Col>
                            <Col>[OCG: {user.OCG}]</Col>
                            <Col>[SNZ: {user.SNZ}]</Col>
                        </Row>
                        <Row>
                            <Col>[SCB: {user.SCB}]</Col>
                            <Col>[APEX/AFN: {user.AFN}]</Col>
                            <Col>[CDM: {user.CDM}]</Col>
                        </Row>
                        <Row>
                            <Col>[CDP: {user.CDP}]</Col>
                            <Col>[Bearing: {user.bearing}]</Col>
                            <Col>[Caliper: {user.NCG}]</Col>
                            <Col>[MD: {user.MD}]</Col>
                        </Row>
                        <Row>
                            <Col>[CNZ: {user.CNZ}]</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <ItemDiscountsRow defaultVal={user.ONZ} product={"ONZ"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            <ItemDiscountsRow defaultVal={user.OCG} product={"OCG"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            <ItemDiscountsRow defaultVal={user.SNZ} product={"SNZ"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                        </Row>
                        <Row>
                            <ItemDiscountsRow defaultVal={user.SCB} product={"SCB"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            <ItemDiscountsRow defaultVal={user.AFN} product={"AFN"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            <ItemDiscountsRow defaultVal={user.CDM} product={"CDM"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                        </Row>
                        <Row>
                            <ItemDiscountsRow defaultVal={user.CDP} product={"CDP"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            <ItemDiscountsRow defaultVal={user.bearing} product={"Bearing"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            <ItemDiscountsRow defaultVal={user.NCG} product={"Caliper"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            <ItemDiscountsRow defaultVal={user.MD} product={"MD"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                            
                        </Row>
                        <Row>
                            <ItemDiscountsRow defaultVal={user.CNZ} product={"CNZ"} userMod={userMod} setUserMod={setUserMod} disabled={(auth.user.access !== 1) ? (true) : (false)} />
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td>Other Remarks</td>
                    <td>{user.special_info}</td>
                    <td>
                        <textarea className="form-control" type="number" onChange={(e) => setUserMod({...userMod, "special_info": e.target.value})} defaultValue={user.special_info}></textarea>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default AccountsDetailsTable;