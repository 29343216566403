/**************************************************************************************
* Author: Adam Khan
* Date Created: Oct, 27th, 2020
*
* Description: The component that will allow admins to change global settings for users
*
***************************************************************************************/

import React, { useEffect, useState } from "react";
import axios from 'axios';
import qs from "qs";
import { pricesApiURL, groupsApiURL, getGroupItems, postGlobalDiscounts, postGlobalMax, usersApiURL, getMonthStatment, globalAccessoryRoute } from '../../../context/addresses.js';
import "./styles/global-settings.css";
import * as XLSX from 'xlsx';
import { Navigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import downloadTemplate from './example/groups.xlsx';
import Loading from "../../../context/loading.js";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GlobalInput from "./components/GlobalInput.js";
import GlobalInputAccessory from "./components/GlobalInputAccessory.js";
import GlobalInputAddAccessory from "./components/GlobalInputAddAccessory.js";
import GlobalPrices from "./components/GlobalPrices.js";

function GlobalSettings() {
    const [uploadPrice, setUploadPrice] = useState(); // Get the prices from upload file
    const [group, setGroup] = useState([]); // Download Groups 
    const [groupId, setGroupId] = useState(''); // Get the items by id 
    const [deleteId, setDeleteId] = useState(); // Group Id to be deleted
    const [groupDescription, setGroupDescription] = useState(''); // New group description
    const [groupItems, setGroupItems] = useState([]); // New Group items
    const [users, setUsers] = useState([]); // Get all users
    const [srcdoc, setSrcdoc] = useState("");
    const [globalMax, setGlobalMax] = useState({ maxonz: 0, maxocg: 0, maxsnz: 0, maxscb: 0, maxafn: 0, maxcdm:0, maxcdp: 0, maxcnz: 0, maxmd: 0, maxbearing: 0, maxother: 0,maxncg:0 });
    const [globalMaxUser, setGlobalMaxUser] = useState('all');
    const [globalDiscount, setGlobalDiscount] = useState({ ONZ: 0, OCG: 0, SNZ: 0, SCB:0, AFN: 0, CDM:0, CDP: 0, MD: 0, bearing: 0, CNZ: 0,NCG:0 });
    const [globalDiscountUser, setGlobalDiscountUser] = useState('all');
    const [globalAccessory, setGlobalAccessory] = useState([]);
    const [globalAddAccessory, setGlobalAddAccessory] = useState([{ bucket: 0, part: '', discount: 0, qty: 0 }]);
    const [numRowsOne, setNumRowsOne] = useState(globalAccessory.length);
    const [numRowsTwo, setNumRowsTwo] = useState(1);
    const [errorStatus, setErrorStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [deleteAccessoryBucket, setDeleteAccessoryBucket] = useState('');
    const [deleteAccessoryPart, setDeleteAccessoryPart] = useState('');
    const [tableInstance, setTableInstance] = useState(0);
    let rowOne = [];
    let rowTwo = [];

    useEffect(() => {
        document.title = 'Global Settings - Max Advanced Brakes Wholesale';
        getUsers();
        getAccessory();
    }, []);

    async function getUsers() {
        try {
            const response = await axios.get(`${usersApiURL}?perpage=200`, { Session: {}, withCredentials: true });
            setUsers(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("getUsers failed: " + error.message);
        }
    }

    async function getAccessory() {
        try {
            const response = await axios.get(globalAccessoryRoute, { Session: {}, withCredentials: true });

            let tempArr = response.data;
            let tempBucket = [];
            tempArr.forEach((item) => {
                let i = tempBucket.findIndex(x => (x.bucket === item.bucket));
                if (i <= -1) {
                    tempBucket.push({ bucket: item.bucket, discount: item.discount, qty: item.qty });
                }
                return null;
            })
            let temp = [];
            tempBucket.forEach((item) => {
                let i = tempArr.filter(x => (x.bucket === item.bucket));
                temp.push({ bucket: item.bucket, parts: i, discount: item.discount, qty: item.qty })
            })
            setGlobalAccessory(temp);
            setNumRowsOne(temp.length);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("getAccessory failed: " + error.message);
        }
    }

    // Get the current prices
    async function handleDownloadPrices() {
        try {
            const response = await axios.get(pricesApiURL, { Session: {}, withCredentials: true })
        
            let csv = '';
            for (let i in response.data[0]) {
                csv += i + ',';
            }
            csv += '\n';
            response.data.forEach((item) => {
                for (let key in item) {
                    csv += item[key] + ',';
                }
                csv += "\n";
            });
        
            let hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'Price.csv';
            hiddenElement.click();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("handleDownload failed: " + error.message);
        }
    }

    // The xlsx or csv file that will be uploaded to prices
    function handleUpload(event) {
        let file = event.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function(evt) {
            let data = evt.target.result;
            let lines = [];
            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type:'binary' });
                let parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
                let headers = parsed[0].toString().split(',');

                for (let i = 1; i < parsed.length; i++) {
                    let fileData = parsed[i].toString().split(',');
                    if (fileData.length === headers.length) {
                        let temp = [];
                        for (let j = 0; j < headers.length; j++) {
                            temp.push({ [headers[j]]: fileData[j] });
                        }
                        temp = Object.assign({}, ...temp);
                        lines.push(temp)
                    }
                }
            } else {
                let allText = data.split(/\r\n|\n/);
                let headers = allText[0].split(',');
                
                for (let i = 1; i < allText.length; i++) {
                    let fileData = allText[i].split(',');
                    if (fileData.length === headers.length) {
                        let temp = [];
                        for (let j = 0; j < headers.length; j++)  {
                            temp.push({ [headers[j]]: fileData[j] });
                        }
                        temp = Object.assign({}, ...temp);
                        lines.push(temp);
                    } 
                }
            }
            setUploadPrice(lines);
        }
        
        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
        
    }

    // Post the new prices
    async function handleSubmitPrices() {
        try {
            await axios.post(pricesApiURL, { data: uploadPrice }, { Session: {}, withCredentials: true });
            alert("Prices submitted");
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("handleSubmit failed: " + error.message);
        }
    }

    // Get all Groups
    async function handleDownloadGroups() {
        setIsLoading(true);
        try {
            const response = await axios.get(groupsApiURL, { Session: {}, withCredentials: true });
            setGroup(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("handleDownloadGroups failed: " + error.message);
        }
        setIsLoading(false);
    }

    // Get group items in group
    async function handleGetGroupItems(id) {
        try {
            const response = await axios.get(`${getGroupItems}${id}`, { Session: {}, withCredentials: true });
            
            let temp = '<ul>';
            response.data.map(item => temp += `<li>${item.gproduct}</li>`)
            temp += '</ul>';
            
            setSrcdoc(temp);
            setGroupId(id);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            } 
                
            console.log("handleGetGroupItems failed: " + error.message);
        }
    }

    // Upload Group Items from file
    async function handleGroupUpload(event) {
        let file = event.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function(evt) {
            let data = evt.target.result;
            let parsed = '';
            let tempArr = [];
            let tempObj = {};

            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type:'binary' });
                parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
            } else {
                parsed = data.split(/\r\n|\n/);
                parsed = parsed.map((item) => {
                    return item.split(',');
                })
            }

            for (let i in parsed) {
                if (i === '0' || parsed[i].length === 0 || parsed[i][0] === '' || parsed[i][1] === '') continue;

                tempObj = { "gproduct": parsed[i][0].toString().trim(), "gprice": parsed[i][1].toString().trim() };
                tempArr.push(tempObj);
            }

            if (tempArr.length > 0) setGroupItems(tempArr);
            else alert("There are no items to upload.");
        }

        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
    }

    // Sunbmit new group
    async function handleSubmitGroups() {
        let groupParams = '';

        if (groupId !== '' && groupDescription !== '') {
            alert('Either overwrite an exsiting group or make new group.');
        } else if (groupItems.length === 0) {
            alert('No items for group');
        } else {
            if (groupId !== '') groupParams = { id: groupId, items: groupItems };
            if (groupDescription !== '') groupParams = { description: groupDescription, items: groupItems };

            try {
                await axios.post(groupsApiURL, qs.stringify(groupParams), { Session: {}, withCredentials: true });

                setGroupId('');
                alert("Group created")
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                } 
                    
                console.log("handleSubmitGroups failed: " + error.message);
            }
        }
    }

    async function clearGroups() {
        setGroupId('');
        setGroupDescription('');
        setGroupItems([]);
        document.getElementById('groupSelect').value = '';
        document.getElementById('newGroupNameInput').value = '';
        document.getElementById('groupItemsFileInput').value = '';
    }

    // Delete group
    async function handleDeleteGroup() {
        try {
            await axios.delete(`${groupsApiURL}/${deleteId}`, { Session: {}, withCredentials: true });

            alert("Group deleted")
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("handleDeleteGroup failed: " + error.message);
        }
    }

    // POST default max quantity
    async function handlePostMaxQuantityDefaultValue() {
        try {
            let params = {};
            if (globalMaxUser !== "all") {
                params = { ...globalMax, username: globalMaxUser };
            } else {
                params = globalMax;
            }
            await axios.post(postGlobalMax, params, { Session: {}, withCredentials: true });
            alert('Max Quantity Updated');
            getUsers();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("handlePostMaxQuantityDefaultValue failed: " + error.message);
        }
    }

    // POST default item discount
    async function handlePostItemDiscountDefaultValue() {
        try {
            let params = {};
            if (globalDiscountUser !== "all") {
                params = { ...globalDiscount, username: globalDiscountUser };
            } else {
                params = globalDiscount;
            }
            await axios.post(postGlobalDiscounts, params, { Session: {}, withCredentials: true });
            alert('Item Discounts Updated');
            getUsers();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("handlePostItemDiscountDefaultValue failed: " + error.message);
        }
    }

    async function monthTest() {
        try {
            const response = await axios.get(getMonthStatment, { Session: {}, withCredentials: true });
            console.log(response);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("monthTest failed: " + error.message);
        }
    }

    // Update Accessory promotion
    async function handlePostGlobalAccessory() {
        let params = [];
        globalAccessory.map((item) => item.parts.map((x) => params.push(x)))
        try {
            await axios.post(globalAccessoryRoute, params, { Session: {}, withCredentials: true });
            alert("Accessory Promotion Prices Updated");
            getAccessory();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("postglobalAccessory failed: " + error.message);
        }
    }

    // Add new Accessory promotion
    async function handlePostAddGlobalAccessory() {
        let check = false;
        
        let params = globalAddAccessory.map((item) => {
            if (item.bucket === 0 || !item.part || item.discount === 0 || item.qty === 0) check = true
            return item
        });
        console.log(params)
        if (check) {
            alert("There are empty rows or fields")
            return;
        }

        try {
            await axios.post(globalAccessoryRoute, params, { Session: {}, withCredentials: true });
            alert("Accessory Promotion Prices Updated");
            getAccessory();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("postglobalAccessory failed: " + error.message);
        }
    }

    // Input fields for global/user maxqty
    function getUserMaxInfo() {
        let user = users.find((item) => item.username === globalMaxUser);
        return (
            <Row>
                <Col>
                    <Form.Label>maxonz: {user.maxonz}</Form.Label>
                    <Form.Label>maxocg: {user.maxocg}</Form.Label>
                    <Form.Label>maxsnz: {user.maxsnz}</Form.Label>
                </Col>
                <Col>
                    <Form.Label>maxscb: {user.maxscb}</Form.Label>
                    <Form.Label>maxafn: {user.maxafn}</Form.Label>
                    <Form.Label>maxcdm: {user.maxcdm}</Form.Label>
                </Col>
                <Col>
                    <Form.Label>maxcdp: {user.maxcdp}</Form.Label>
                    <Form.Label>maxmd: {user.maxmd}</Form.Label>
                    <Form.Label>maxbearing: {user.maxbearing}</Form.Label>
                </Col>
                <Col>
                    <Form.Label>maxcnz: {user.maxcnz}</Form.Label>
                    <Form.Label>maxother: {user.maxother}</Form.Label>
                </Col>
            </Row>
        )
    }

    // Input fields for Accessory promotion table
    function globalAccessoryTableRow(id) {
        return (
            <tr>
                <td>{globalAccessory[id].bucket}</td>
                <td>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Parts</Accordion.Header>
                            <Accordion.Body>
                                {globalAccessory[id].parts.map((item) => 
                                    <ul>
                                        <li>{item.part}</li>
                                    </ul>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </td>
                <td><GlobalInputAccessory rowid={id} objectValues={globalAccessory} setObjectValues={setGlobalAccessory} property={'discount'} min={'0.00'} type={'number'} defaultValue={globalAccessory[id].discount} /></td>
                <td><GlobalInputAccessory rowid={id} objectValues={globalAccessory} setObjectValues={setGlobalAccessory} property={'qty'} min={'0'} type={'number'} defaultValue={globalAccessory[id].qty} /></td>
            </tr>
        ) 
    }
    
    function handleDeleteAddAccessoryRow(id) {
        let globalAddAccessoryTemp = globalAddAccessory;
        rowTwo = rowTwo.filter((item, index) => index !== id);
        globalAddAccessoryTemp = globalAddAccessoryTemp.filter((item, index) => index !== id)
        setGlobalAddAccessory(globalAddAccessoryTemp)
        setNumRowsTwo(rowTwo.length)
        setTableInstance(prev => prev + 1)
    }

    // Input fields for Add Accessory table
    function globalAddAccessoryTableRow(id) {
        return (
            <tr>
                <td><GlobalInputAddAccessory rowid={id} objectValues={globalAddAccessory} setObjectValues={setGlobalAddAccessory} property={'bucket'} min={'1'} type={'number'} /></td>
                <td><GlobalInputAddAccessory rowid={id} objectValues={globalAddAccessory} setObjectValues={setGlobalAddAccessory} property={'part'} type={'text'} /></td>
                <td><GlobalInputAddAccessory rowid={id} objectValues={globalAddAccessory} setObjectValues={setGlobalAddAccessory} property={'discount'} min={'0.00'} type={'number'} /></td>
                <td><GlobalInputAddAccessory rowid={id} objectValues={globalAddAccessory} setObjectValues={setGlobalAddAccessory} property={'qty'} min={'0'} type={'number'} /></td>
                <td>
                    <Button className="btn btn-danger" onClick={() => handleDeleteAddAccessoryRow(id)}>Delete</Button>
                </td>
            </tr>
        )
    }

    // Input fields for global/user discount
    function getUserDiscountInfo() {
        let user = users.find((item) => item.username === globalDiscountUser);
        return (
            <>
                <Row>
                    <Form.Label>ONZ: {user.ONZ}</Form.Label>
                    <Form.Label>OCG: {user.OCG}</Form.Label>
                    <Form.Label>SNZ: {user.SNZ}</Form.Label>
                </Row>
                <Row>
                    <Form.Label>SCB: {user.SCB}</Form.Label>
                    <Form.Label>AFN: {user.AFN}</Form.Label>
                    <Form.Label>CDM: {user.CDM}</Form.Label>
                </Row>
                <Row>
                    <Form.Label>CDP: {user.CDP}</Form.Label>
                    <Form.Label>MD: {user.MD}</Form.Label>
                    <Form.Label>Bearing: {user.Bearing}</Form.Label>
                    <Form.Label>Caliper: {user.NCG}</Form.Label>
                </Row>
                <Row>
                    <Form.Label>CNZ: {user.CNZ}</Form.Label>
                </Row>
            </>
        )
    }

    // When Adding a new row to Add Accessory table
    function handleAddAccessoryRow() {
        let newArr = globalAddAccessory;
        newArr.push({ bucket: 0, part: '', discount: 0, qty: 0 });
        setGlobalAddAccessory(newArr);
        setNumRowsTwo(numRowsTwo + 1);
    }

    async function handleDeleteAccessory() {
        try {
            await axios.delete(`${globalAccessoryRoute}${deleteAccessoryBucket}/${deleteAccessoryPart}`, { Session: {}, withCredentials: true });
            alert("Accessory Promotion Table");
            getAccessory();
        } catch(error) {
            console.log(error)
        }
    }

    // Rows for Accessory Promotion table
    for (let i = 0; i < numRowsOne; i++) {
        rowOne[i] = globalAccessoryTableRow(i);
    }

    // Rows for Add Accessory table
    for (let i = 0; i < numRowsTwo; i++) {
        rowTwo[i] = globalAddAccessoryTableRow(i);
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="global-settings-page" className="page">
            <Container>
                <h2>Edit Global Settings</h2>
                {/* <GlobalPrices setErrorStatus={setErrorStatus} /> */}
                <Table className="table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Field</th>
                            <th scope="col">Download</th>
                            <th scope="col">Upload</th>
                            <th scope="col">Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td id={"price"}>Price</td>
                            <td>
                                <Button variant="secondary" type="button" onClick={handleDownloadPrices}>Download Price</Button>
                            </td>
                            <td>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Control className="uploadButton" type="file" onChange={(e) => handleUpload(e)} />
                                    </Form.Group>
                                    <Col>
                                        <Button variant="warning" type="button" onClick={handleSubmitPrices}>Submit</Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td id={"group-manager"}>Group Manager</td>
                            <td>
                                <Button variant="secondary" type="button" onClick={handleDownloadGroups}>Get Groups</Button>
                                <br/ ><br />
                                <a className="downloadTemplate" href={downloadTemplate} download="templateGroup.xlsx">Download Template</a>
                            </td>
                            <td>
                                <Row>
                                    {(isLoading) ? (
                                        <Loading />
                                    ) : (
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>Select a Group:</Form.Label>
                                            <Form.Select id="groupSelect" onChange={e => { handleGetGroupItems(e.target.value); setDeleteId(e.target.value); }}>
                                                <option value=""></option>
                                                {group.map((item) => <option value={item.id}>{item.description}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>Name New Group:</Form.Label>
                                        <Form.Control id="newGroupNameInput" type="text" onChange={e => setGroupDescription(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4}>
                                        <Form.Control id="groupItemsFileInput" type="file" onChange={(e) => handleGroupUpload(e)} />
                                        <Form.Text muted>File containing products and prices.</Form.Text>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="button" variant="secondary" onClick={clearGroups}>Clear</Button>{' '}
                                        <Button type="button" variant="danger" onClick={handleDeleteGroup}>Delete Selected Group</Button>{' '}
                                        <Button variant="warning" type="button" onClick={handleSubmitGroups}>Submit</Button>
                                    </Col>
                                </Row>
                            </td>
                            <td>
                                <iframe className="col-md" srcDoc={srcdoc} title="Group Items"></iframe>
                            </td>
                        </tr>
                        <tr>
                            <td id="max-quantity">Max quantity</td>
                            <td>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Choose All or a customer:</Form.Label>
                                        <Form.Select onChange={e => setGlobalMaxUser(e.target.value)}>
                                            <option value="all">All</option>
                                            {(users !== undefined && users !== null) ? (
                                                users.map((user) => <option value={user.username}>{user.company_name} ({user.firstname} {user.lastname})</option>) 
                                            ) : (null)}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col>
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxonz'} defaultValue={globalMax.maxonz} />
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxocg'} defaultValue={globalMax.maxocg} />
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxsnz'} defaultValue={globalMax.maxsnz} />
                                    </Col>
                                    <Col>
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxscb'} defaultValue={globalMax.maxscb} />
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxafn'} defaultValue={globalMax.maxafn} />
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxcdm'} defaultValue={globalMax.maxcdm} />
                                    </Col>
                                    <Col>
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxcdp'} defaultValue={globalMax.maxcdp} />
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxmd'} defaultValue={globalMax.maxmd} />
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxbearing'} defaultValue={globalMax.maxbearing} />
                                    </Col>
                                    <Col>
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxcnz'} defaultValue={globalMax.maxcnz} />
                                        <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxother'} defaultValue={globalMax.maxother} />
                                    </Col>
                                    <Col>
                                        <Button variant="warning" type="button" onClick={handlePostMaxQuantityDefaultValue}>Update</Button>
                                    </Col>
                                </Row>
                            </td>
                            <td>{(globalMaxUser !== 'all') ? (getUserMaxInfo()) : (null)}</td>
                        </tr>
                        <tr>
                            <td id="item-discount">Item Discounts</td>
                            <td>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Choose All or a customer:</Form.Label>
                                        <Form.Select onChange={e => setGlobalDiscountUser(e.target.value)}>
                                            <option value="all">All</option>
                                            {(users !== undefined && users !== null) ? (
                                                users.map((user) => <option value={user.username}>{user.company_name} ({user.firstname} {user.lastname})</option>)
                                            ) : (null)}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col>
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'ONZ'} defaultValue={globalDiscount.ONZ} />
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'OCG'} defaultValue={globalDiscount.OCG} />
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'SNZ'} defaultValue={globalDiscount.SNZ} />
                                    </Col>
                                    <Col>
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'SCB'} defaultValue={globalDiscount.SCB} />
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'AFN'} defaultValue={globalDiscount.AFN} />
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'CDM'} defaultValue={globalDiscount.CDM} />
                                    </Col>
                                    <Col>
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'CDP'} defaultValue={globalDiscount.CDP} />
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'MD'} defaultValue={globalDiscount.MD} />
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'Bearing'} defaultValue={globalDiscount.bearing} />
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'Caliper'} defaultValue={globalDiscount.NCG} />
                                    </Col>
                                    <Col>
                                        <GlobalInput objectValues={globalDiscount} setObjectValues={setGlobalDiscount} productLine={'CNZ'} defaultValue={globalDiscount.CNZ} />
                                    </Col>
                                    <Col>
                                        <Button variant="warning" type="button" onClick={handlePostItemDiscountDefaultValue}>Update</Button>
                                    </Col>
                                </Row>
                            </td>
                            <td>{(globalDiscountUser !== 'all') ? (getUserDiscountInfo()) : (null)}</td>
                        </tr>
                        <tr>
                            <td>
                                <Button variant="secondary" onClick={monthTest}>MonthTest</Button>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <div className="global-accessories mb-3">
                    <h2>Accessory Promotion</h2>
                    <Table className="table-bordered">
                        <thead>
                            <tr>
                                <th>Bucket</th>
                                <th>Product</th>
                                <th>Discount</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowOne}
                        </tbody>
                    </Table>
                    <Button variant="warning" onClick={handlePostGlobalAccessory}>Submit Table</Button>
                </div>
                <h2>Add Accessories</h2>
                <Table className="table-bordered" key={setTableInstance}>
                    <thead>
                        <tr>
                            <th>Bucket</th>
                            <th>Product</th>
                            <th>Discount</th>
                            <th>Quantity</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowTwo}
                    </tbody>
                </Table>
                <div className="text-align-center">
                    <Button variant="secondary" onClick={handleAddAccessoryRow}>Add Row</Button>{' '}
                    <Button variant="warning" onClick={handlePostAddGlobalAccessory}>Submit Table</Button>
                </div>
                <h2>Delete Accessories</h2>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Select onChange={(e) => setDeleteAccessoryBucket(e.target.value)}>
                            <option value=""></option>
                            {(globalAccessory) ? (
                                globalAccessory.map((item) => <option value={item.bucket}>{item.bucket}</option>)
                            ) : (null)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Select onChange={(e) => setDeleteAccessoryPart(e.target.value)}>
                            <option value=""></option>
                            {(globalAccessory) ? (
                                globalAccessory.map((item) => item.parts.map((bucket) => <option value={bucket.part}>{bucket.part}</option>))
                            ) : (null)}
                        </Form.Select>
                    </Form.Group>
                    <Col>
                        <Button variant="danger" onClick={handleDeleteAccessory}>Delete</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GlobalSettings;