/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: Get product for the e-catalouge base of the category chosen
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import  ecat_to_max  from "../../../../context/utility.js";
import axios from 'axios';
import { catalogApiURL, postQuantityListApiURL } from '../../../../context/addresses.js';
import Loading from '../../../../context/loading.js';
import { useLocation, Navigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import AddToCartFeature from './components/AddToCartFeature.js';

const pattern = /ONZ|TSU|OCG|SCB|SNZ|CDM|CNZ|CDP|AFN|^8100|WHB|NCG/;

function GetProductByCategory(props) {
    const [itemInfo, setItemInfo] = useState([]); // The item Info from categories
    const [isLoading, setIsLoading] = useState(false); // Check if loading
    const [isError, setIsError] = useState(false); // Check if error
    const [errorStatus, setErrorStatus] = useState();
    const exisitingProducts = JSON.parse(localStorage.getItem("productId")); // Get existing products in cart
    const [product] = useState((exisitingProducts) ? (exisitingProducts) : ([])); // Items to put in cart
    const location = useLocation();
    const productLine = { "ONZ": " Regular Rotor", "OCG": " Geomet Rotor", "SCB": "Black Coated, Drilled and Slotted", "SNZ": " Drilled and Slotted", "CDM": " Max Ceramic", "CNZ": " Premium Cross-Drilled", "CDP": " Elite Max", "AFN": " APEX ONE Ceramic Pad", 8100: "Bearing","WHB":"Bearing","TSU":" TOYOTA TSUSHO OES CERAMIC BRAKE PADS","NCG":"Caliper" };
    
    const fetchProduct = useCallback(async () => {
        setIsError(false)
        setIsLoading(true);

        try {
            const response = await axios.get(catalogApiURL + "?year=" + props.year + "&make=" + props.make + "&model=" + encodeURIComponent(props.model), { Session: {} });
            // console.log('before ecat');
            let tempList = ecat_to_max(response.data);
            let items = [];
            let newArr = [];
            for (let i of tempList) {
                if ((i.itemtype + " " + i.axle.slice(0, 1)) === decodeURIComponent(props.category)) {
                    if (i.maxnum.search("SYS") === -1) {
                        items.push([i.maxnum, i.id, i.applicationnote, i.axle, i.order, i.submodel, i.original, i.partnum, i.displayname, i.interchange]);
                    }
                }
            }
            // console.log('after ecat');
            
            items = checkSubmodel(items);
            // console.log('after checksub');
            newArr = countDuplicates(items);
            // console.log('after countdup');
            newArr = await getItemQty(newArr);
            // console.log('await getitem');

            setItemInfo(newArr);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("fetchProduct failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }, [props])

    // Same as the category api
    useEffect(() => {
        fetchProduct();
    }, [fetchProduct]);

    // Check list of items for submodels and group them
    function checkSubmodel(items) {
        let submodels = []; // array to hold submodels with their maxnums
        
        // add items as objects to the array 
        for (let i of items) {
            submodels.push({ maxnum: i[0], submodel: i[5] })
        }
        
        let seen = {};
        submodels = submodels.filter((entry) => {
            let previous;
            // Check to see if we have alreay seen this number
            if (seen.hasOwnProperty(entry.maxnum)) {
                previous = seen[entry.maxnum];
                if (!Array.isArray(entry.submodel)) entry.submodel = [entry.submodel];

                if (previous.submodel.toString().match(entry.submodel) === null) previous.submodel.push(entry.submodel);
                // Don't keep this entry, we've merged it into the previous one
                return false;
            }

            // submodels.submodel is not an array; make it one
            if (!Array.isArray(entry.submodel)) entry.submodel = [entry.submodel];

            // Remember that we have alreay seen this number
            seen[entry.maxnum] = entry;

            // Keep this one, we'll merge any others that match into it
            return true;
        })

        // change original array submodel to contain the new list of submodels
        for (let i of items) {
            for (let j of submodels) {
                if (i[0] === j.maxnum && i[5].match(j.submodel.toString()) === null) {
                    i[5] += ',' + j.submodel.toString();
                }
            }
        }
        return items;
    }

    // Count duplicates
    function countDuplicates(items) {
        let newArr = [];
        let counter = {};
        let itemApplicationNote = {};
        let itemAxle = {};
        let name = {};
        let altName = {};
        let itemSubmodel = {};
        items.forEach((item) => {
            let key = item[0];
            let applicationnote = item[2];
            let axle = item[3];
            let display = item[8];
            let altDisplay = item[9];
            let submodel = item[5];
            counter[key] = (counter[key] || 0) + 1;
            itemApplicationNote[key] += "; " + (applicationnote || "");
            itemAxle[key] = (axle || "");
            name[key] = (display || "");
            altName[key] = (altDisplay || "");
            itemSubmodel[key] = (submodel || "");
        })
        
        // remove undefined from string
        for (let key in itemApplicationNote) {
            itemApplicationNote[key] = itemApplicationNote[key].replace("undefined;", "");
            
            let temp = itemApplicationNote[key].split(';');
            temp = temp.filter((item) => {
                return item !== ' ';
            });

            if (temp.length !== 0) {
                temp = temp.filter((item, index) => { 
                    return !temp.includes(item, index + 1);
                });
            }

            itemApplicationNote[key] = temp;
        }

        for (let item in counter) {
            newArr.push({ item, "amount": counter[item], "applicationnote": itemApplicationNote[item], "axle": itemAxle[item], "display": name[item], "interchange": altName[item], "submodel": itemSubmodel[item] });
        }

        return newArr;
    }

    // Get item quantity
    async function getItemQty(items) {
        try {
            let products = { data: [{ part: '' }] }; // Object that will be sent to POST

            // populate the part array of products with list of products
            products.data = items.map(item => { return { part: item.item } });
            
            let response = await axios.post(postQuantityListApiURL, qs.stringify(products), { Session: {},  withCredentials: true });
            
            // Add the quantity to the items array of objects
            for (let i of items) { 
                for (let j of response.data) {
                    if (i.item !== null && i.item !== undefined && i.item !== "") {
                        if (i.item === j.part.toString()) {
                            i.quantity = j.qty;
                        } 
                    }
                }
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("getItemQty failed: " + error.message);
        }

        return items;
    }

    // Display list of products
    function listOfProducts() {
        if (itemInfo === null || itemInfo.length === 0) return;

        return (
            itemInfo.map((item, index) => {
                let partText = item.display + " ";
                if (item.interchange !== "") partText = item.interchange + " ";

                let productLineText = productLine[item.item.match(pattern)];
                if (productLineText === undefined) productLineText = "Bearing";

                let axleText = "Base";
                if (item.axle.match(/R Right/)) axleText = "Rear Right";
                else if (item.axle.match(/R Left/)) axleText = "Rear Left";
                else if (item.axle.match(/F Right/)) axleText = "Front Right";
                else if (item.axle.match(/F Left/)) axleText = "Front Left";
                else if (item.axle.match(/R/)) axleText = "Rear";
                else if (item.axle.match(/F/)) axleText = "Front";

                return (
                    <ListGroup.Item id={`${location.pathname}/${item.item}`} className={`e-cat-item`} key={index}>
                        <Row>
                            <Col md={12} lg={7} className="text-align-left pl-3">
                                <strong>{partText}</strong> {productLineText}
                                <br />
                                <ul className="tab-li" key={index}>
                                    <li>{axleText}</li>
                                    {(item.applicationnote !== undefined && item.applicationnote.length !== 0) ? (
                                        item.applicationnote.map((applicationnote) => {
                                            return (applicationnote.trim() !== '') ? <li>{applicationnote}</li> : (null)
                                        })
                                    ) : (null)}
                                    {(item.submodel !== undefined && item.submodel.toString() !== '') ? (<li>{item.submodel.toString().replace(/,/g, ', ')}</li>) : (null)}
                                </ul>
                            </Col>
                            {(item.quantity !== null && item.quantity !== undefined && item.quantity > 0) ? (<AddToCartFeature item={item.item} addToCart={addToCart} />) : (null)}
                        </Row>
                    </ListGroup.Item>
                )
            })
        )
    }

    // Add item to the cart
    function addToCart(item, num) {
        let productTemp = product;
        let itemToAdd = [item, num];
        productTemp.push(itemToAdd);
        for (let i = 0; i < productTemp.length; i++) {
            let count = 0;
            for (let j = i + 1; j < productTemp.length; j++) {
                if (productTemp[i][0] === productTemp[j][0]) {
                    productTemp.splice(j, 1);
                    count++;
                }
            }
            if (count > 0) {
                productTemp[i][1] = Number(productTemp[i][1]) + Number(num);
            }
        }
        localStorage.setItem("productId", JSON.stringify(productTemp));
        alert("Item added to cart");
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <>
            {isError && <div>Something went wrong ...</div>}
            {isLoading ? (<Loading />) : (listOfProducts())}
        </>
    )
}

export default GetProductByCategory;