import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

function MaxQuantity({ id, setUserMod, userMod, defaultVal }) {
    return (
        <Form.Group as={Col} controlId={id}>
            <Form.Label>{id}:</Form.Label>
            <Form.Control type="number" min="0" max="5000" onChange={(e) => setUserMod({...userMod, [id]: Number(e.target.value)})} defaultValue={(defaultVal) ? (defaultVal) : (0)} />
        </Form.Group>
    )
}

export default MaxQuantity;