/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: August 3rd, 2020
 * 
 *  Description: Routes used to access functions in the server.js file that will access the database
 *  
 *  serverAPI:
 *      - live: https://api.maxbrakes.com/ 
 *      - live: https://api.maxbrakeswholesale.com/ 
 *      - testing: http://localhost:3002/
 *****************************************************************************************************/

const serverAPI = "https://api.maxbrakes.com/"; // Server route
const loginApiURL = `${serverAPI}login`; // POST login
const logoutApiURL = `${serverAPI}logout`; // GET logout
const postCheckSession = `${serverAPI}checksession`; // POST previous session user and session token
const catalogApiURL = `${serverAPI}ecat`; // GET e-cat with no params
const ordersApiURL = `${serverAPI}product/`; // GET item info
const orderPricesApiURL = `${serverAPI}price/`; // GET item prices
const postPriceListApiURL = `${serverAPI}pricelist/`; // POST optional username and a group of partnumbers to get prices
const postOrderApiUrl = `${serverAPI}order`; // POST preview order post
const ordersHistoryApiURL = `${serverAPI}orders`; // GET Orders with no params
const historyApiURL = `${serverAPI}orders?page=`; // GET order-history-pages
const orderItemApiURL = `${serverAPI}orderitems/`; // GET history-order-details
const orderItemIdApiURL = `${serverAPI}orders?order_id=`; // GET history-order-id
const invoiceApiURL = `${serverAPI}invoice/`; // invoice for order
const packinglistPDF = `${serverAPI}packinglist/`; // packing list for order
const usersApiURL = `${serverAPI}users/`; // GET Accounts
const userApiURL = `${serverAPI}user/`; // GET and POST user
const getPendingUsers = `${serverAPI}pendingusers`; // GET pending users
const returnsApiURL = `${serverAPI}return/`; // POST returns
const couponApiURL = `${serverAPI}coupon`; // coupon
const pingApiURL = `${serverAPI}ping`; // GET idleTimer
const creditUserAPI = `${serverAPI}returns`; // GET user credit
const creditApiURL = `${serverAPI}returns?page=` // GET Credit
const statementsApiURL = `${serverAPI}statements`; // GET Statement statements
const statementItemsApiURL = `${serverAPI}statement/`; // GET Statement statement items
const getDraftsApiURL = `${serverAPI}drafts`; // GET Drafts
const draftApiURL = `${serverAPI}draft`; // GET, POST and DELETE Draft
const postReturnApiUrl = `${serverAPI}return`; // POST Return
const exclusionsApiUrl = `${serverAPI}exclusions/`; // GET, POST and DELETE Exclusions
const getUnpaidBalanceAPIURL = `${serverAPI}unpaidbalance`; // Get the users unpaid balance
const pricesApiURL = `${serverAPI}prices`; // GET and POST Global Prices
const getMonthStatment = `${serverAPI}monthtest`; // GET the monthly statement
const getInterchange = `${serverAPI}interchange/`; // GET interchange numbers
const postForgotPassword = `${serverAPI}forgotpassword`; // POST that will send an email to the users email
const postPasswordReset = `${serverAPI}passwordreset`; // POST reset the password for the user
const postShipOrder = `${serverAPI}shiporder`; // POST ship order on order 
const postNewUser = `${serverAPI}newuser`; // POST when logged in new user
const groupsApiURL = `${serverAPI}groups`; // GET, POST and DELETE groups
const getGroupItems = `${serverAPI}groupitems/`; // GET group items by id
const groupSetApiURL = `${serverAPI}groupset`; // GET, POST and DELETE groupset
const getUserTaxRate = `${serverAPI}taxrate/`; // GET tax rate by username
const getCouponId = `${serverAPI}coupon/`; // Get coupon by coupon id
const deleteCouponCode = `${serverAPI}coupon/`; // DELETE coupon by coupon code
const postQuantityListApiURL = `${serverAPI}wholesaleinv`; // POST a group of partnumbers to get quantity
const getReturnsById = `${serverAPI}returns?return_id=`;
const returninvoice = `${serverAPI}getReturnInvoice/returninvoice/`; // Get credit invoice
const getStatementInvoice = `${serverAPI}getStatementInvoice/`;
const postGlobalDiscounts = `${serverAPI}global/discounts/`; // POST global item discount
const postGlobalMax = `${serverAPI}global/max/`; // POST global max quantity
const globalAccessoryRoute = `${serverAPI}global/oil/`; // global accessory promotion price
const postCancelOrder = `${serverAPI}cancelorder`; // POST cancel order
const postSignUpRequest = `${serverAPI}signup`; // POST sign up
const postPendingReturnUpload = `${serverAPI}pendingreturnupload`; // POST file upload for pending returns
const postSingleItemReturnBucket = `${serverAPI}return-bucket-single-item`; // POST single item to return bucket
const postMultipleItemsReturnBucket = `${serverAPI}return-bucket-multiple-items`; // POST multiple items to return bucket
const getReturnBucketItems = `${serverAPI}return-bucket-items`; // GET return bucket items
const deleteReturnBucketItem = `${serverAPI}return-bucket-items-delete`; // DELETE return bucket item
const postCreateReturnInvoice = `${serverAPI}return-invoice-create`; // POST create a new return invoice
const getReturnInvoices = `${serverAPI}return-invoices`; // GET return invoices
const getReturnInvoiceItems = `${serverAPI}return-invoice-items`; // GET return invoices items
const getPendingReturnApiURL = `${serverAPI}pendingreturn`; // GET PendingReturns
const postPendingReturnApiURL = `${serverAPI}pendingreturn/`; // POST PendingReturns
const postMultiPendingReturn = `${serverAPI}multireturn`; // POST Multiple pending returns
const postUserPasswordReset = `${serverAPI}user/passwordreset`;

module.exports = { serverAPI, pingApiURL, loginApiURL, logoutApiURL, postCheckSession, catalogApiURL, getDraftsApiURL, draftApiURL, ordersApiURL, orderPricesApiURL, postPriceListApiURL, ordersHistoryApiURL, historyApiURL, postOrderApiUrl, orderItemApiURL, orderItemIdApiURL, invoiceApiURL, packinglistPDF, usersApiURL, userApiURL, getPendingUsers, returnsApiURL, creditUserAPI, creditApiURL, couponApiURL, statementsApiURL, statementItemsApiURL, postReturnApiUrl, exclusionsApiUrl, getUnpaidBalanceAPIURL, pricesApiURL, getMonthStatment, getInterchange, postForgotPassword, postPasswordReset, postShipOrder, postNewUser, groupsApiURL, getGroupItems, groupSetApiURL, getUserTaxRate, getCouponId, deleteCouponCode, postQuantityListApiURL, getReturnsById, returninvoice, getStatementInvoice, postGlobalDiscounts, postGlobalMax, globalAccessoryRoute, postCancelOrder, postSignUpRequest, postPendingReturnUpload, postSingleItemReturnBucket, postMultipleItemsReturnBucket, getReturnBucketItems, deleteReturnBucketItem, postCreateReturnInvoice, getReturnInvoices, getReturnInvoiceItems, getPendingReturnApiURL, postPendingReturnApiURL, postMultiPendingReturn, postUserPasswordReset };
