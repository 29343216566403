/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: A Footer component that will be shown at the footer of every page
 * 
 *****************************************************************************************************/

import React from "react";
import { Link } from "react-router-dom";
import './styles/footer.css';

function Footer() {
    return (
        <div id="footer" className="noPrint">
            <div className="container-fluid">
                <div className="line"></div>
                <div id="info" className="no-margin footer-no-margin-right row">
                    <div className="info-section col-md-3">
                        <h6>Max Advanced Brakes</h6>
                        <p>280 Hillmount Road, Unit 5</p>
                        <p>Markham, ON L6C3A1</p>
                        <p>Operation  Hours</p>
                        <p>Mon-Friday: 9AM-6PM EST</p>
                        <p>Sat-Sunday: CLOSED</p>
                    </div>
                    <div className="info-section col-md-3">
                        <h6>Contact List</h6>
                        <p>General Inquiry</p>
                        <p>1-888-835-2399 ext 124</p>
                        <p>Email</p>
                        <p>info.ws@maxbrakes.com</p>
                    </div>
                    <div className="info-section col-md-3">
                        <h6>Other Information</h6>
                        <Link to="/terms/termsAndConditions"><p>Terms and Conditions</p></Link>
                        <Link to="/terms/privacyPolicy"><p>Privacy Policy</p></Link>
                    </div>
                    <div className="info-section col-md-3">
                        <h6>Help Topics</h6>
                        <Link to="/terms/warranties"><p>Warranty Information</p></Link>
                        <Link to="/terms/returnPolicy"><p>Shipping &amp; Returns</p></Link>
                        <p><span>Easy FAQ</span></p>
                    </div>
                </div>
                <div id="footer-copyright" className="text-align-center">
                    Copyright 2020 - 2022 Max Brakes Prozone. All rights Reserved.
                </div>
            </div>
        </div>
    );
}

export default Footer;
