/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: August 6th, 2020
 * 
 *  Description: List of table headers and image data
 * 
 *****************************************************************************************************/

// Navigation Bar nav items
//"Request Access": "request-access",
const notLoggedInItems = { "Sign Up": "sign-up" };
const loggedInItems = { "Home": "/", "E-Catalogue": "/e-cat", "Interchange": "/interchange", "New order": "/new-order", "History": "/history", "Returns": "/return-page", "Credit": "/credit", "Statement": "/statements", "Accounts": "/accounts", "Coupons": "/coupon", "Promotion": "/promotion", "Global Settings": "/global-settings", "Unshipped": "/unshipped" };

// Home table headers
const home_table_headers = { "Product #": "Product #", "Description": "Description", "Price": "Price", "Availabilty": "Availabilty", "Button": "" };

// Interchange table headers
const interchange_table_headers = { "Part Number": "query", "Brands": "brand_name", "Max Number": "maxnum", "Part Type": "" };

// New Order and Preview Order table headers
const table_headers = { "rem": "", "pro": "Product#", "des": "Description", "qty": "Quantity", "dis": "Availability", "pri": "Price", "tot": "Total" };

// E- cat table headers
const e_cat_table_headers = { "Year": "Year", "Make": "Make", "Model": "Model", "Category": "Category", "Product": "Product" }

// History table headers
const history_table_headers = { "#": "", "Order Number": "order_id", "Invoice": "", "Packing List": "", "Date Ordered": "date_created", "Date Shipped": "date_shipped", "PO": "custom_po", "Method": "ship_method", "Total": "", "Company Name": "company_name", "Status": "status" };

// Invoice table headers
const invoice_table_headers = { "#": "#", "Product": "Product", "Max-Product": "Max-Product", "Description": "Description", "Qty": "Qty", "Ship": "Ship", "Returned": "Returned", "Price": "Price", "Discount": "Discount", "Total": "Total" }

// Invoice table headers admin
const invoice_table_headers_admin = { "#": "#", "Max-Product": "Max-Product", "Product": "Product", "Description": "Description", "Qty": "Qty", 'Available': 'Available', "Ship": "Ship", "Returned": "Returned", "Price": "Price", "Discount": "Discount", "Total": "Total" }

// Returns table headers
const returns_table_headers = { "#": "id", "Return Id": "return_id", "Return Request Created": "date_created", "Quantity": "qty", "Return Total": "return_total", "User": "username", "Status": "status" };

const returns_table_headers_old = { "#": "id", "Return Id": "return_id", "Product": "product", "User": "username", "Quantity": "qty", "Order Created": 'order_created', "Return Request Created": "date_created", "Status": "status" };

// Credit table headers
const credit_table_headers = { "#": "", "Credit": "return_id", "Download": "Download", "User": "username", "Date": "date_created", "Total": "" };

// Credit details table Headers
const creditDetails_table_headers = { "#": "", "Invoice": "order_ids", "Products": "products", "Unit Cost": "price_discounted", "Quantity": "qty", "Credit": "refund_total" };

// Account Receivable/ Account Payable table headers
const accRePay_table_headers = { "#": "#", "Statement #": "Statement #", "Download": 'Download', "Paid": "Paid", "Date": "date_created", "R.Balance": "R.Balance", "Company": "Company", "Details": "Details" };

// Statement table headers
const statement_table_headers = { "#": "#", "Date Shipped": "Date Shipped", "Code": "Code", "Invoice/Credit#": "Invoice/Credit#", "Amount": "Amount" };

// Accounts table headers
const accounts_table_headers = { "#": "", "Name": "firstname", "Username": "username", "Email": "email", "Access": "", "Active": "active" };

// Account details table headers
const account_details_table_header = { "Data Field": "Data Field", "Current User Setting": "Current User Setting", "Update User Setting": "Update User Setting" };

const promotion_fields = { 'id': 'number', 'enabled': 'checkbox', 'start_time': 'date', 'end_time': 'date', 'coupon': 'text', 'discount': 'number', 'part_type': 'text', 'part_finish': 'text', 'part_number': 'text', 'item_use': 'number' };

const returnsResults_table_headers = { '': '', 'Query': 'Query', 'Product': 'Product', 'Shipped': 'Qty Sipped', 'Available to Return': 'Qty Returned', 'Return': 'Qty to Return' };

const returnsCart_table_headers = { '#': '#', 'Product': 'Product', 'Return': 'Qty To Return', 'Price': 'Unit Price (discounted)', 'Remove': 'Remove' };

const pattern = /ONZ|OCG|SCB|SNZ|AFN|CNZ|CDM|CDP|TSU|8100|MD|NCG|WHB/ig;

module.exports = { table_headers, notLoggedInItems, loggedInItems, home_table_headers, e_cat_table_headers, history_table_headers, invoice_table_headers, invoice_table_headers_admin, returns_table_headers, credit_table_headers, creditDetails_table_headers, accRePay_table_headers, statement_table_headers, accounts_table_headers, account_details_table_header,interchange_table_headers, promotion_fields, returnsResults_table_headers, returnsCart_table_headers, pattern, returns_table_headers_old };
