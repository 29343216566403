const finishes = { 'rotor': ['ONZ', 'CNZ', 'SCB', 'SYS', 'SNZ'], 'pad': ['AFN', 'CDP','TSU'] };
const productLinesArray = ['ONZ', 'OCG', 'SCB', 'SYS', 'SNZ', 'CDM', 'CNZ', 'CDP', 'AFN', '8100','TSU','NCG','WHB']
const finish_order = { ONZ: 1, OCG: 2, SCB: 3, SNZ: 4 };

function num_to_max(numobj) {
    let l = numobj.itemtype.toLowerCase();

    if (l.indexOf('pad') !== -1) {
        return [
            ...finishes.pad.map(v => {
                return { type: v, num: v + ('0000' + numobj.partnum).slice(-4) }; 
            }),
            { num: 'P' + numobj.partnum.replace(/^0+/, "") + 'CDM', type: 'CDM'}
        ];
    } else if (l.indexOf('rotor') !== -1) {
        if (numobj.partnum.startsWith('OCG')) {
            return [{ type: 'OCG', num: numobj.partnum }];
        } else
            return finishes.rotor.map(v => { 
                return { type: v, num: 'R' + numobj.partnum + v}; }
            );
    } else if (l.indexOf('bearing') !== -1) {
        return [{ type: 'BEARING', num: numobj.partnum }];
    }else if (l.indexOf('caliper') !== -1) {
        return [{ type: 'CALIPER', num: numobj.maxnumber }];
    }
}

function ecat_to_max(data) {
    let out = [];
    let productLineCheck = true;

    for (let i of data) {
        productLineCheck = productLinesArray.some((productLine) => i.maxnumber.startsWith(productLine));
        if (!productLineCheck) continue;
        
        let tx = ''
        if (i.partnum) {
            tx = num_to_max(i);
        } else {
            continue;
        }

        i.order = 5;
        for (let t of tx) {
            let x = Object.assign({}, i);
            let findorder = finish_order[t.type];
            if (findorder) {
                x.order = findorder;
            }

            if (i.type === 'OCG') {
                x.displayname = 'R' + i.original + 'OCG';
            } else if(i.type==='CALIPER'){
                x.displayname=i.partnum;
                
            } else{
                x.displayname = t.num;
            }

            x.maxnum = t.num;
            
            if (!i.alt) {
                x.original = i.partnum;
            }
            out.push(x);
        }
    }

    out.sort((a, b) => {
        if (a.original === b.original) {
            return a.order - b.order;
        } else {
            return (a.original < b.original) ? -1 : 1;
        }
    });
    
    return out;
}

export default ecat_to_max;