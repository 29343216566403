import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../../context/auth";
import { postSingleItemReturnBucket, postReturnApiUrl, ordersApiURL } from '../../../../context/addresses.js';
import Form from "react-bootstrap/Form";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


// The row information
function OrderLineTableRows({ id, order, item, onShipped }) {
    let auth = useAuth(); // Get the authorization
    const [lineProduct, setLineProduct] = useState('');
    const [lineDescription, setLineDescription] = useState('');
    const [shipped, setShipped] = useState((item.qty_shipped === null || item.qty_shipped === 0) ? (item.qty) : (item.qty_shipped));
    const [total, setTotal] = useState((Number(item.price_discounted) * shipped).toFixed(2));
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [returnOrderId, setReturnOrderId] = useState("");
    const [returnOrderQty, setReturnOrderQty] = useState(1);
    const params = [{ orderitemid: returnOrderId, qty: Number(returnOrderQty), username: auth.user.username }];
    const pattern = /^(ONZ|OCG|SCB|SNZ|CNZ|CDM|CDP|AFN|MD|NCG)/i

    const getShippedItems = useCallback(() => {
        let shippedLine = { id: item.id, qty: Number(shipped) };
        onShipped(shippedLine, id);
    }, [id, item.id, onShipped, shipped])

    async function getProductDescription(maxProduct) {
        try {
            const response = await axios.get(ordersApiURL + maxProduct, { Session: {}, withCredentials: true });
            
            if (response.data.int.length !== 0) {
                let productDescription = response.data.int.filter((product) => product.n === item.product || product.n === item.description)
                setLineDescription(productDescription[0].d);
            } else  {
                setLineDescription('');
            }
        } catch(error) {
            console.log("Failed to get product description: ", error)
            setLineDescription('');
        }
    }

    useEffect(() => {
        getShippedItems();
    }, [getShippedItems]);

    useEffect(() => {
        setTotal((Number(item.price_discounted) * shipped).toFixed(2));
    }, [total, shipped, item.price_discounted]);

    useEffect(() => {
        let tempMaxProduct = '';
        let tempNotMaxCode = '';
        
        if (!item.product && item.description) {
            tempMaxProduct = item.description;
            tempNotMaxCode = item.description;
        } else if (item.product && !item.description) {
            tempMaxProduct = item.product;
            tempNotMaxCode = item.product;
        } else if (item.product === item.pcode) {
            tempMaxProduct = item.product;
            tempNotMaxCode = item.product;
        } else if (item.product.match(pattern)) {
            tempMaxProduct = item.product;
            tempNotMaxCode = item.description;
        } else if (item.description.match(pattern)) {
            tempMaxProduct = item.description;
            tempNotMaxCode = item.product;
        } else {
            tempMaxProduct = item.product;
            tempNotMaxCode = item.product;
        }
        console.log(tempMaxProduct, tempNotMaxCode)
        setLineProduct(tempMaxProduct);
        getProductDescription(tempNotMaxCode);
    }, [item])

    // Handle return of an order item and quantity
    async function handleReturnItem() {
        try {
            let returnTotal = Number(returnOrderQty) + Number(item.qty_returned);
            if (returnTotal > item.qty_shipped) {
                alert("Return amount exceeds quantity shipped");
                return;
            }
            console.log(params)
            
            if (JSON.stringify(params[0].qty) !== 0 && JSON.stringify(params[0].qty) !== "0" && JSON.stringify(params[0].qty) !== null && JSON.stringify(params[0].qty) !== undefined) {
                await axios.post(postReturnApiUrl, params, { Session: {}, withCredentials: true });
                
                handleClose();
                alert("Items Pending Return!");
            } else {
                alert("There must be a quantity to return.");
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
            }

            alert(error.message);
            console.log("handleReturnItem() failed: " + error.message);
        }
    }

    return (
        <>
            <tr key={`row-${id}`}>
                <td>{id + 1}</td>
                <td>{lineProduct}</td>
                <td>{item.pcode}</td>
                <td>{lineDescription}</td>
                <td>{item.qty}</td>
                {(auth.user.access !== 5) ? (
                    <td className="noPrint">{item.forsale}</td>
                ) : (null)}
                <td>
                    {(auth.user.access !== 5 && order.date_shipped === null) ? (
                        <Form.Control type="number" min="0" max={item.qty} value={shipped} onChange={(e) => { setShipped(e.target.value) }} />
                    ) : (item.qty_shipped !== null) ? (
                        item.qty_shipped
                    ) : (
                        item.qty
                    )}
                </td>
                <td>{item.qty_returned}</td>
                <td>${item.price}</td>
                <td>${item.price_discounted}</td>
                <td>${total}</td>
                <td>
                    {(item.qty_returned < item.qty_shipped) ? (
                        <>
                            <Button className="returnButton noPrint" variant="secondary" onClick={() => { setReturnOrderId(item.id); handleShow(); }}>Return</Button>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Return Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure that you want to return item Id: {returnOrderId}?</p>
                                    <Form.Label htmlFor="orderQty">Quantity:</Form.Label>
                                    <Form.Control id="orderQty" type="number" value={returnOrderQty} onChange={e => setReturnOrderQty(e.target.value)} min={1} max={item.qty_shipped} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                                    <Button variant="warning" onClick={handleReturnItem}>Return</Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    ) : (null)}
                </td>
            </tr>
        </>
    )
}

export default OrderLineTableRows;