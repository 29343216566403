/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: July 15th, 2020
 * 
 *  Description: The login page that the user will use to login
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useCallback } from "react";
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import './styles/login.css';
import imagePlaceholder2 from '../../../styles/pictures/distributor.jpeg';
import imagePlaceholder3 from '../../../styles/pictures/TOOLKIT.png';
import imagePlaceholder4 from '../../../styles/pictures/pexels-negative-space-97075.jpg'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Login() {
    let auth = useAuth(); // set the auth for the user
    let navigate = useNavigate();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Checks if the user is logged in
    const [loginInfo, setLoginInfo] = useState({ username: "", password: "" }); // State for username and password

    useEffect(() => {
        document.title = 'Login - Max Advanced Brakes Wholesale';
    }, []);

    const loginCheckSession = useCallback(() => {
        auth.checkSession().then((status) => {
            if (status === "OK") navigate(from);
        });
    }, [auth, from, navigate])

    useEffect(() => {
        loginCheckSession()
    }, [loginCheckSession]);

    // When the user submits there credentials
    let login = (e) => {
        e.preventDefault();
        auth.signin(loginInfo.username, loginInfo.password).then(() => {
            navigate(from);
        })
    }

    return (
        <>
            {isLoggedIn ? <Navigate to="/" /> : null}
            <div id="login" className="page">
                <div id="firstRow" className="row no-margin">
                    <div id="bannerText" className="col-md-6">
                        <p id="maxpro">
                            <strong id="max">MAX</strong> <strong id="pro">PRO</strong><br />
                            <span className="imgText">SUPERIOR QUALITY BRAKES FOR</span><br />
                            <span className="imgText">DISTRIBUTORS</span>
                        </p>
                    </div>
                    <div className="col-md-6 login">
                        <div id="loginForm">
                            <Form onSubmit={event => login(event)}>
                                <Form.Group className="mb-3" controlId="username">
                                    <Form.Label bsPrefix="login-label">Username:</Form.Label>
                                    <Form.Control type="username" placeholder="username" value={loginInfo.username} onChange={e => setLoginInfo({ ...loginInfo, username: e.target.value })} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label bsPrefix="login-label">Password:</Form.Label>
                                    <Form.Control type="password" placeholder="password" value={loginInfo.password} onChange={e => setLoginInfo({ ...loginInfo, password: e.target.value })} required />
                                </Form.Group>
                                <div className="text-align-center">
                                    <Button type="submit" className="login-btn" size="lg">Login</Button>
                                    <br />
                                    <Link to="/password/reset">Forgot your Password?</Link>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <Row className="no-margin">
                    <Col md={6} className="imgSize itemPadding">
                        <span id="img2text">BECOME A DISTRIBUTOR</span>
                        <img className="imgWidth" id="img2" src={imagePlaceholder2} alt="Placeholder" />
                    </Col>
                    <Col md={6} className="textArea">
                        <h2>Benefits of Partnering</h2>
                        <p>If you are a parts shop, this is for you. We can help you keep the best parts in stock. Sign up now and we will add you to our list of official distributors!</p>
                        <Link to="/sign-up">
                            <button className="btn btn-lg login-btn">Sign up</button>
                        </Link>
                    </Col>
                </Row>
                <Row className="no-margin">
                    <Col md={6} className="textArea">
                        <h2>Advertising Package / Sales Tools</h2>
                        <p>We give you all the necessary items needed to properly advertise yourself and showcase your items. Let your customers know about your superior parts with our sales toolkit.</p>
                        {/* <button className="btn btn-lg login-btn">Sign in To View</button> */}
                        <p>Coming soon...</p>
                    </Col>
                    <Col md={6} className="imgSize itemPadding">
                        <img className="imgWidth" id="img3" src={imagePlaceholder3} alt="Placeholder" />
                    </Col>
                </Row>
                <Row className="no-margin">
                    <Col md={6} className="imgSize itemPadding">
                        <img className="imgWidth" id="img4" src={imagePlaceholder4} alt="Placeholder" />
                    </Col>
                    <Col md={6} className="textArea">
                        <h2>Contact Us</h2>
                        <p>If you have any more inquiries or specific questions about partnering with us, please feel free to contact your distributor and our incredible staff will help to answer and resolve any possible issues or inquiries that you may have.</p>
                        {/* <button className="btn btn-lg login-btn">Contact Us</button> */}
                        <p>General Inquiry: <br /> 1-877-200-2280 ext. 124</p>
                        <p>Delivery Issue: <br /> 1-877-200-2280 ext. 110</p>
                        <p>Email: <br /> info.ws@maxbrakes.com</p>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Login;
