import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

function ItemDiscountsRow({ product, setUserMod, userMod, defaultVal, disabled }) { 
    return (
        <Form.Group as={Col} controlId={product}>
            <Form.Label>{product}:</Form.Label>
            <Form.Control type="number" onChange={(e) => setUserMod({...userMod, [product]: Number(e.target.value)})} defaultValue={defaultVal} disabled={(disabled !== null && disabled !== undefined) ? (disabled) : (false)} />
        </Form.Group>   
    )
}

export default ItemDiscountsRow;