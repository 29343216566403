/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date Created: June 25th, 2020
 * 
 *  Description: Main JS component
 * 
 *****************************************************************************************************/

import { BrowserRouter as Router } from "react-router-dom";
import { Navigation, Footer, IdleTimer, MainContent } from "./components";
import { ProvideAuth } from './context/auth';
import './styles/App.css';
import './styles/print.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    //Navigation, pages and footer
    return (
        <div className="Wholesale">
            <ProvideAuth>
                <Router basename="/prozone">
                    <IdleTimer />
                    <Navigation />
                    <MainContent />
                    <Footer />
                </Router>
            </ProvideAuth>
        </div>
    );
}

export default App;
