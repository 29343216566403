/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The terms for Warranties
* 
*****************************************************************************************************/

function Warranties() {
    return (
        <>
            <div className="terms-header">
                <h3>Warranties</h3>
            </div>
            <div className="terms-content row">
                <div className="terms-columns">
                    <h5>Max Advanced Brakes Manufacturer’s Warranty Disclaimer:</h5>
                    <p>Max Advanced Brakes brake rotors and brake pads are for street and recreational off-road use only. They are not specifically designed or approved for off-road racing or competitive driving. Failure of components used for any type of racing is not covered under warranty.
                    <br /><br />
                    Max Advanced Brakes Brake Rotors or Brake Pads Purchased Individually
                    <br /><br />
                    Max Advanced Brakes warrants its rotors and pads to be free from defect in materials or workmanship. If properly installed, the product 
                    <br /><br />
                    Max Advanced Brakes Wheel Hub Bearing 
                    <br /><br />
                    LIMITED LIFETIME WARRANTY
                    <br /><br />
                    Limited Lifetime Warranty will be valid provided the product has not been subject to misuse in its operation or installation. The warranty will be given in the form of a replacement of the same part and will be subjected to an inspection. Warranty will be valid for 40,000 km or lifetime of the vehicle, whichever comes first. Please see our website for full terms and conditions.
                    <br /><br />
                    Max Advanced Brakes Brake Kits
                    <br /><br />
                    1 Year Warranty against cracking when purchased and installed with our brake pads.
                    <br /><br />
                    6 Months Warranty against warping and/or manufacturer defects (must be within the minimum thickness)
                    <br /><br />
                    30 Days Warranty on manufacturer defects. This warranty does not cover tapered wear, uneven wear or any issue caused by external components such as the caliper.
                    <br /><br />
                    This warranty does not apply to mileage, accidents, abnormal operations, or improper installation.
                    <br /><br />
                    In no event will Max Advanced Brakes be liable for incidental or consequential damages of any sort.
                    <br /><br />
                    Only one (1) warranty claim per order.</p>
                </div>
            </div>
        </>
    )
}
 
 export default Warranties;