/*****************************************************************************************************
*  Author: Adam Khan
*  Date Created: September 10th, 2021
* 
*  Description: The navigation bar between terms
* 
*****************************************************************************************************/

import { Link } from "react-router-dom";

function TermNavigation() {
    const termList = [
        { name: "Terms and Conditions", id: "termsAndConditions" },
        { name: "Logins and Cookies", id: "loginsAndCookies" },
        { name: "Rules and Regulations", id: "rulesAndRegulations" },
        { name: "Privacy Policy", id: "privacyPolicy" },
        { name: "Warranties", id: "warranties" },
        { name: "Pricing and Invoices", id: "pricingAndInvoices" },
        { name: "Return Policy", id: "returnPolicy" }
    ];
    
    return (
        <div className="row terms-navigation">
            <nav className="col-md">
                {termList.map(({ name, id }) => 
                    <Link className="col-md" to={`/terms/${id}`}>{name}</Link>
                )}
            </nav>
        </div>
    )
}

export default TermNavigation;