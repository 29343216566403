/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: Oct 7th, 2020
 * 
 *  Description: Main JS component
 * 
 *****************************************************************************************************/

import React, { useState, useEffect } from "react";
import { Navigate, Link } from 'react-router-dom';
import axios from 'axios';
import './styles/sign-up.css';
import { postSignUpRequest } from '../../../context/addresses.js';
import qs from 'qs';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import SignUpInput from "./components/SignUpInput";
import SignUpInputOptional from "./components/SignUpInputOptional";
import SignUpSelectOptional from "./components/SignUpSelectOptional";

function SignUp() {
    const [newUser, setNewUser] = useState({ firstname: '', lastname: '', company_name: '', phone: '', title: '', email: '', currentsupplier: '', partexp: 0, stockinv: 0, estimatedvol: 0, yearestablished: 1900, state: 'ON', confirmEmail: '', terms: false });
    const [signUpCheck, setSignUPCheck] = useState(false); // When the post to sign up goes through
    const requestParams = { newUser }; // params for account

    useEffect(() => {
        document.title = 'Sign Up - Max Advanced Brakes Wholesale';
    }, []);

    // account info to be sent to the server
    async function postSignUp(event) {
        event.preventDefault();
        if (newUser.terms !== false && (newUser.email !== null && newUser.email !== "") && (newUser.email === newUser.confirmEmail)) {

            delete requestParams.terms;

            try {
                const response = await axios.post(postSignUpRequest, qs.stringify(requestParams), { withCredentials: true });
                alert("Sign Up Request Sent");
                if (response.data === "OK") setSignUPCheck(true);
            } catch(error) {
                if (error.response.status === 422) {
                    console.log("Company already exists");
                }

                console.log("postSignUp() failed: " + error.message);
                alert(error.response.data);
            }
        } else {
            if (newUser.email !== newUser.confirmEmail) alert("Email do not match");
        }
    }

    return (
        <>
            {signUpCheck ? <Navigate to="/login" /> : null}
            <div id="sign-up" className="page">
                <Container>
                    <h3>Sign Up</h3>
                    <Form id="signUpForm" onSubmit={event => postSignUp(event)}>
                        <div id="userAccessInfo">
                            <h5>Account Information</h5>
                            <hr />
                            <p>Required fields are indicated by <span className="text-danger">*</span></p>
                            <hr />
                            <Row>
                                <Form.Group as={Col} controlId="firstname">
                                    <SignUpInput type={"text"} name={"firstname"} newUser={newUser} value={newUser.firstname} setNewUser={setNewUser} label={"First Name"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="lastname">
                                    <SignUpInput type={"text"} name={"lastname"} newUser={newUser} value={newUser.lastname} setNewUser={setNewUser} label={"Last Name"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="company_name">
                                    <SignUpInput type={"text"} name={"company_name"} newUser={newUser} value={newUser.company_name} setNewUser={setNewUser} label={"Company or Agency Legal Name"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="phone">
                                    <SignUpInput type={"tel"} name={"phone"} newUser={newUser} value={newUser.phone} setNewUser={setNewUser} label={"Business Phone"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="title">
                                    <SignUpInput type={"text"} name={"title"} newUser={newUser} value={newUser.title} setNewUser={setNewUser} label={"Title"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="state">
                                    <SignUpInput type={"text"} name={"state"} newUser={newUser} value={newUser.state} setNewUser={setNewUser} label={"Province/State"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="yearestablished">
                                    <SignUpInputOptional type={"number"} name={"yearestablished"} newUser={newUser} value={newUser.yearestablished} setNewUser={setNewUser} label={"Year of Establishment"} min="1900" max="2099" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="currentsupplier">
                                    <SignUpInputOptional type={"text"} name={"currentsupplier"} newUser={newUser} value={newUser.currentsupplier} setNewUser={setNewUser} label={"Who are your current suppliers for parts? (WorldPAC, World Automotive, etc.)"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="partexp">
                                    <SignUpSelectOptional name={"partexp"} value={newUser.partexp} newUser={newUser} setNewUser={setNewUser} label={"Are you experienced with part numbers from the following companies? (Aimco, Raybestos, Centric, MOOG?)"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="stockinv">
                                    <SignUpSelectOptional name={"stockinv"} value={newUser.stockinv} newUser={newUser} setNewUser={setNewUser} label={"Are you able to stock inventory at your location?"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="estimatedvol">    
                                    <SignUpInputOptional type={"number"} name={"estimatedvol"} newUser={newUser} value={newUser.estimatedvol} setNewUser={setNewUser} label={"What is the estimated volume of orders per month?"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <div className="fieldset">
                                    <fieldset className="legendBorder">
                                        <legend>E-mail Address</legend>
                                            <Form.Group controlId="email">
                                                <SignUpInput id={"email"} type={"email"} name={"email"} newUser={newUser} value={newUser.email} setNewUser={setNewUser} label={"E-mail"} />
                                            </Form.Group>
                                            <Form.Group controlId="confirmEmail" className="mt-3">
                                                <SignUpInput id={"confirmEmail"} type={"email"} name={"confirmEmail"} newUser={newUser} value={newUser.confirmEmail} setNewUser={setNewUser} label={"Confirm E-mail"} />
                                            </Form.Group>
                                    </fieldset>
                                </div>
                            </Row>
                        </div>
                        <div id="agree">
                            <h5>Agreement</h5>
                            <hr />
                            <Row>
                                <Form.Group as={Col} md="1" className="text-align-right">
                                    <Form.Check aria-label="terms" onChange={_e => setNewUser({...newUser, ['terms']: !newUser.terms})} required />
                                </Form.Group>
                                <Form.Label column md={4}>I have read and agree to the <Link to="/terms/termsAndConditions">Terms & Conditions</Link> <span className="text-danger">*</span></Form.Label>
                                <Col md="7" className="text-align-center mt-0">
                                    <Button variant="warning" type="submit">Submit</Button>
                                </Col>
                            </Row>
                            <hr />
                            <p>Max Brakes respects your privacy and your concerns about safety. Read more about our security and our privacy policy for more information.</p>
                        </div>
                    </Form>
                    <div id="cancelButton">
                        <Link to="/login">Cancel</Link>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default SignUp;
