/**************************************************************************************
* Author: Adam Khan
* Date Created: June, 25th, 2020
*
* Description: The component that will show a list of users
*
***************************************************************************************/

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, Navigate, useLocation } from 'react-router-dom';
import NewUser from '../new-user/new-user';
import { useAuth } from '../../../context/auth.js';
import AccountDetails from "../account-details/account-details";
import './styles/accounts.css';
import { userApiURL, usersApiURL, getPendingUsers } from '../../../context/addresses.js';
import Loading from '../../../context/loading.js';
import qs from 'qs';
import { accounts_table_headers } from "../../../context/orderModels.js";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import useSortableData from "../../sortTable/useSortableData";

export const NewAccountUser = () => <NewUser />;
export const Details = () => {
    let location = useLocation();
    return <AccountDetails username={location.pathname.split('/')[3]} />;
}

function Accounts() {
    let auth = useAuth(); // Get the current user data
    const [accountList, setAccountList] = useState([]); // Get the list of accounts for filter
    const [accounts, setAccounts] = useState([]); // The user that is filtered
    const [isLoading, setIsLoading] = useState(false); // loading data
    const [isError, setIsError] = useState(false); // There was an error getting api
    const [errorStatus, setErrorStatus] = useState([]); // Get the error status
    const [username, setUsername] = useState(""); // The username used for filter
    const [page, setPage] = useState(0); // change page
    const { items, requestSort } = useSortableData(accounts); // For the sort filter
    const prevButtonRef = useRef(); // ref for the prev button
    const nextButtonRef = useRef(); // ref for the next button

    useEffect(() => {
        document.title = 'Accounts - Max Advanced Brakes Wholesale';
    }, []);

    useEffect(() => {
        getAccounts();
    }, [auth.user.access, auth.user.username, page]);

    useEffect(() => {
        if (auth.user.access !== 5) fetchAccountList();
    }, [auth])

    // GET accounts 
    async function getAccounts() {
        if (auth.user.access !== 5) {
            setIsError(false);
            setIsLoading(true);

            try {
                let perpage = 30;
                if (username === "Deactivated") perpage = 999;

                const response = await axios(`${usersApiURL}?page=${page}&&perpage=${perpage}`, { Session: {}, withCredentials: true });
            
                buttonToDisable(response.data.length);
                if (username === "Deactivated") {
                    response.data = response.data.filter((item) => {
                        return item.active === 0;
                    });
                }
                setAccounts(response.data);
            } catch(error) {
                if (error.response && error.response.status === 401) {
                    console.log("User is not signed in: " + error.message);
                    setErrorStatus(error.response.status);
                }

                console.log("getAccounts() failed: " + error.message);
                setIsError(true);
            }

            setIsLoading(false);
        }
    }

    // GET account list for search
    const fetchAccountList = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios(`${usersApiURL}?page=${0}&&perpage=${999}`, { Session: {}, withCredentials: true });
            
            response.data = response.data.sort((a, b) => {
                return ("" + a.company_name).localeCompare("" + b.company_name);
            });

            response.data = response.data.filter((item) => {
                return item.active !== 0;
            })
            
            setAccountList(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("fetchAccountList failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    // GET account by username
    const fetchSearchUserAccount = async () => {
        setIsError(false);
        setIsLoading(true);
       
        try {
            const response = await axios.get(`${userApiURL}${username}`, { Session: {}, withCredentials: true });
        
            buttonToDisable(response.data.length)

            setAccounts([response.data]);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
             
            console.log("fetchSearchUserAccount() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    // GET accounts that are pending
    const fetchSearchPendingUserAccount = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(getPendingUsers, { Session: {}, withCredentials: true });
        
            buttonToDisable(response.data.length);

            setAccounts(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
             
            console.log("fetchSearchPendingUserAccount() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }

    // When the admin uses the search function
    function handleSubmit(event) {
        event.preventDefault();
        if (username !== "Request" && username !== "" && username !== "Deactivated") {
            fetchSearchUserAccount();
        } else if (username === "" || username === "Deactivated") {
            getAccounts();
        } else {
            fetchSearchPendingUserAccount();
        }
    }

    // What buttons to disable depending on conditions
    function buttonToDisable(arrLength) {
        if (page === 0 && arrLength < 30) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = true;
        } else if (page === 0) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = false;
        } else if (arrLength < 30) {
            prevButtonRef.current.disabled = false;
            nextButtonRef.current.disabled = true;
        } else {
            nextButtonRef.current.disabled = false;
            prevButtonRef.current.disabled = false;
        }
    }

    // When the clear button is clicked
    function handleClear() {
        setUsername("");
        setPage(0);
        document.getElementById('accName').value = '';
    }

    // When the next button is clicked
    function handleNext() {
        if (1 <= page) setPage(page + 1);
        else setPage(1);
    }

    // When the previous button is clicked
    function handlePrevious() {
        if (page <= 0) return;
        else setPage(page - 1);
    }

    // When the accept button is clicked for a pending account 
    async function requestPost(e) {
        try {
            await axios.post(userApiURL, qs.stringify({ "username": e, "active": 1 }), { Session: {}, withCredentials: true });
            await getAccounts();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in:" + error.message);
                setErrorStatus(error.response.status);
            }
             
            console.log("requestPost() failed: " + error.message);
        }
        alert("Account Accepted!");
    }

    // When the reject button is clicked for a pending account
    async function rejectDelete(e) {
        try {
            await axios.delete(userApiURL + e, { Session: {}, withCredentials: true });
            await getAccounts();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in:" + error.message);
                setErrorStatus(error.response.status);
            }
             
            console.log("rejectDelete(): " + error.message);
        }
        alert("Account Rejected!")
    }

    function DisplayUserAccounts() {
        return (
            (auth.user.access !== 5) ? (
                items.map((item, index) => {
                    let lineIndex = (Number(page) * 30) + index + 1;

                    return (
                        <tr key={index}>
                            <td>{lineIndex}</td>
                            <td>{item.firstname}</td>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td>
                                <Link to={"/accounts/user/" + item.username} id={item.username}>Edit Access</Link>
                            </td>
                            <td>
                                {(item.active === 2) ? (
                                    <>
                                        <Button className="requestButton" value={items[index].username} onClick={e => requestPost(e.target.value)}>Accept</Button>
                                        <Button className="rejectButton" value={items[index].username} onClick={e => rejectDelete(e.target.value)}>Reject</Button>
                                    </>
                                ) : (null)}
                            </td>
                        </tr>
                    )
                })
            ) : (
                <tr key={items.id}>
                    <td>{items.id}</td>
                    <td>{items.firstname}</td>
                    <td>{items.username}</td>
                    <td>{items.email}</td>
                    <td>
                        <Link to={`/accounts/user/${items.username}`} id={items.username}>Edit Access</Link>
                    </td>
                </tr>
            )
        )
    }

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries(accounts_table_headers).map(([key, value], index) => {
                        return <th key={index} className={(value !== "") ? ("sort") : (value)} onClick={() => requestSort(value)}>{key}</th>
                    })}
                </tr>
            </thead>
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="accounts-page" className="page">
            <Container>
                <h1>Accounts</h1>
                {isError && <div>There was an error</div>}
                <Form className="mb-3" onSubmit={(event) => handleSubmit(event)}>
                    {(auth.user.access !== 5) ? (
                        <Row>
                            <Form.Label column md={1}>Account:</Form.Label>
                            <Form.Group as={Col} md={7} controlId="accName">
                                <Form.Select id="accName" placeholder="Account Name" onChange={(e) => { setUsername(e.target.value); }}>
                                    <option value=""></option>
                                    <option value="Request">Request</option>
                                    <option value="Deactivated">Deactivated</option>
                                    {accountList.map((item, index) => <option value={item.username} key={index}>{item.company_name} ({item.firstname} {item.lastname})</option>)}
                                </Form.Select>
                            </Form.Group>
                            <Col>
                                <Button variant="warning" type="submit">Search</Button>{' '}
                                <Button variant="secondary" type="submit" onClick={handleClear}>Clear</Button>
                            </Col>
                        </Row>
                    ) : (null)}
                    <Row>
                        <Col>
                            <Button variant="secondary" ref={prevButtonRef} type="submit" onClick={handlePrevious}>Previous</Button>
                        </Col>
                        <Col className="text-align-right">
                            <Button variant="secondary" ref={nextButtonRef} type="submit" onClick={handleNext}>Next</Button>
                        </Col>
                    </Row>
                </Form>
                <Table striped>
                    {TableHeaders()}
                    <tbody>
                        {(isError && (errorStatus === 401 || errorStatus === 403)) ? (<Navigate to="/notAuthorized" />) : (isError && <tr><td>There was an error loading users ...</td></tr>)}
                        {isLoading ? (<tr><td><Loading /></td></tr>) : (DisplayUserAccounts())}
                    </tbody>
                </Table>
                {(auth.user.access !== 5) ? (
                    <div className="text-align-center">
                        <Button variant="secondary" type="button">
                            <Link to="/accounts/new-user">New Account</Link>
                        </Button>
                    </div>
                ) : (null)}
            </Container>
        </div>
    );
}

export default Accounts;
