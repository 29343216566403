/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: The component that will show the users order history
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from '../../../context/auth';
import { history_table_headers } from "../../../context/orderModels.js";
import { ordersHistoryApiURL, historyApiURL, getUnpaidBalanceAPIURL, getInterchange, usersApiURL, invoiceApiURL, packinglistPDF } from '../../../context/addresses.js';
import Loading from '../../../context/loading.js';
import HistoryDetails from "../history-details/history-details";
import './styles/history.css';
import download from '../../../styles/pictures/download.png';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import useSortableData from "../../sortTable/useSortableData";
import SearchFields from "../../orderSearchFields/SearchFields.js";

export const OrderDetails = () => {
    let location = useLocation();
    return <HistoryDetails orderId={location.pathname.split('/')[2]} />
};

function History() {
    let auth = useAuth(); // Get the current user data
    let location = useLocation();
    const [history, setHistory] = useState([]); // Get history
    const [searchFilter, setSearchFilter] = useState({ order_id: '', sDate: '', eDate: '', product: '', po: '', company_name: '', page: 0, perpage: 30 }); // Search filter params
    const [companies, setCompanies] = useState([]); // An array of companies
    const [isLoading, setIsLoading] = useState(false); // check if loading
    const [isError, setIsError] = useState(false); // check for error
    const [errorStatus, setErrorStatus] = useState([]); // check error status
    const [unpaidBalance, setUnpaidBalance] = useState("0.00"); // users unpaid balance
    const { items, requestSort } = useSortableData(history);
    const prevButtonRef = useRef();
    const nextButtonRef = useRef();
    const prevButtonRefTwo = useRef();
    const nextButtonRefTwo = useRef();
    let [searchParams, setSearchParams] = useSearchParams();

    const fetchHistory = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(`${historyApiURL}${searchFilter.page}&&perpage=${searchFilter.perpage}`, { Session: {}, withCredentials: true });

            buttonToDisable(response.data.length, searchFilter.page)

            setHistory(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("fetchHistory() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }, [searchFilter.page, searchFilter.perpage])

    const fetchSearchHistory = useCallback(async (filter) => {
        setIsError(false);
        setIsLoading(true);

        setSearchParams(filter);
        try {
            // Find the interchange for the product number
            let productCode = filter.product.match(/\d+/i); 
            const temp_product = (filter.product !== "") ? (await axios.get(`${getInterchange}${productCode}/contain`, { Session: {}, withCredentials: true })) : ([]);

            // Search with the parameters in the filter
            const response = await axios.get(ordersHistoryApiURL, { params: filter, Session: {}, withCredentials: true });

            if (temp_product.data !== undefined && temp_product.data[0] !== undefined && temp_product.data[0].Max !== undefined) {
                // Find products in the ocg product line 
                const response_ocg = await axios.get(ordersHistoryApiURL, { 
                    params: { 
                        sdate: filter.sDate,
                        edate: filter.eDate,
                        product: temp_product.data[0].Max,
                        custom_po: filter.po,
                        order_id: filter.order_id,
                        company_name: filter.company_name,
                        page: filter.page,
                        perpage: filter.perpage 
                    },
                    Session: {},
                    withCredentials: true
                });

                // Merge the two arrays
                Array.prototype.push.apply(response.data, response_ocg.data);
            }

            buttonToDisable(response.data.length, filter.page)
            setHistory(response.data);

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("handleSubmit() failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }, [setSearchParams])

    const getHistory = useCallback(async () => {
        if (location.search !== '') {
            let tempFilters = { order_id: searchParams.get('order_id'), sDate: searchParams.get('sDate'), eDate: searchParams.get('eDate'), product: searchParams.get('product'), po: searchParams.get('po'), company_name: searchParams.get('company_name'), page: Number(searchParams.get('page')), perpage: searchParams.get('perpage') };
            
            setSearchFilter(tempFilters);
            
            fetchSearchHistory(tempFilters);
        } else {
            // When the user first enters the page get the table with no filters
            fetchHistory();
        }
    }, [fetchHistory, fetchSearchHistory, location.search, searchParams])

    useEffect(() => {
        document.title = 'Order History - Max Advanced Brakes Wholesale';

        getHistory();
        
        if (auth.user.access !== 5) fetchCompanies();
        
        fetchUnpaidBalance();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [getHistory, auth.user.access]);

    async function fetchCompanies() {
        try {
            let temp = [];
            const response = await axios.get(`${usersApiURL}?perpage=200`, { Session: {}, withCredentials: true });

            response.data = response.data.sort((a, b) => {
                return ("" + a.company_name).localeCompare("" + b.company_name);
            });

            response.data.forEach(item => {
                return temp.push(item.company_name);
            });

            temp = temp.filter((item, index) => {
                return temp.indexOf(item) === index;
            });

            setCompanies(temp)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("fetchCompanies() failed: " + error.message);
        }
    }

    // Get the users unpaid balance, credit limit and credit remaining
    const fetchUnpaidBalance = async () => {  
        try {
            const response = await axios.get(getUnpaidBalanceAPIURL, { Session: {}, withCredentials: true });

            setUnpaidBalance(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("fetchUnpaidBalance failed: " + error.message);
        }
    }

    // Get table with filter options
    function handleSubmit(event) {
        event.preventDefault();
        fetchSearchHistory(searchFilter);
    }

    // What buttons to disable depending on conditions
    function buttonToDisable(arrLength, page) {
        if (page === 0 && arrLength < 30) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = true;
            prevButtonRefTwo.current.disabled = true;
            nextButtonRefTwo.current.disabled = true;
        } else if (page === 0) {
            prevButtonRef.current.disabled = true;
            nextButtonRef.current.disabled = false;
            prevButtonRefTwo.current.disabled = true;
            nextButtonRefTwo.current.disabled = false;
        } else if (arrLength < 30) {
            prevButtonRef.current.disabled = false;
            nextButtonRef.current.disabled = true;
            prevButtonRefTwo.current.disabled = false;
            nextButtonRefTwo.current.disabled = true;
        } else {
            nextButtonRef.current.disabled = false;
            prevButtonRef.current.disabled = false;
            prevButtonRefTwo.current.disabled = false;
            nextButtonRefTwo.current.disabled = false;
        }
    }

    // When the clear button is clicked
    function handleClear() {
        setSearchFilter({ order_id: '', sDate: '', eDate: '', product: '', po: '', company_name: '', page: 0, perpage: 30 });
    }

    // When the next button is clicked
    function handleNext() {
        if (1 <= searchFilter.page) setSearchFilter({ ...searchFilter, page: Number(searchFilter.page) + 1 });
        else setSearchFilter({ ...searchFilter, page: 1 });
    }

    // When the previous button is clicked
    function handlePrevious() {
        if (searchFilter.page <= 0) return;
        else setSearchFilter({ ...searchFilter, page: Number(searchFilter.page) - 1 });
    }

    function TableHeaders() {
        return (
            <thead>
                <tr>
                    {Object.entries(history_table_headers).map(([key, value], index) => {
                        return <th key={index} className={(value !== "") ? ("sort") : (value)} onClick={() => requestSort(value)}>{key}</th>;
                    })}
                </tr>
            </thead>
        )
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="history-invoice" className="page">
            <Container>
                <h1>History</h1>
                <Form onSubmit={(event) => handleSubmit(event)}>
                    <Row>
                        <Form.Group as={Col} controlId="sDate">
                            <Form.Label>Start Date:</Form.Label>
                            <SearchFields type="date" name="sDate" placeholder="" value={searchFilter.sDate} filter={searchFilter} setValue={setSearchFilter} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="eDate">
                            <Form.Label>End Date:</Form.Label>
                            <SearchFields type="date" name="eDate" placeholder="" value={searchFilter.eDate} filter={searchFilter} setValue={setSearchFilter} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="product">
                            <SearchFields type="text" name="product" placeholder="product #" value={searchFilter.product} filter={searchFilter} setValue={setSearchFilter} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="po">
                            <SearchFields type="text" name="po" placeholder="PO" value={searchFilter.po} filter={searchFilter} setValue={setSearchFilter} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="orderNum">
                            <SearchFields type="text" name="order_id" placeholder="Order #" value={searchFilter.order_id} filter={searchFilter} setValue={setSearchFilter} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        {(auth.user.access !== 5) ? (
                            <>
                                <Form.Label column md={2}>Company Name</Form.Label>
                                <Form.Group as={Col} md={4} controlId="companyName">
                                    <Form.Select placeholder="Company Name" value={searchFilter.company_name} onChange={(e) => { setSearchFilter({ ...searchFilter, company_name: e.target.value}) }}>
                                        {companies.map((item, index) => {
                                            return <option value={item} key={index}>{item}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </>
                        ) : (null)}
                        
                    </Row>
                    <Button variant="warning" size="lg" type="submit">Search</Button>{' '}
                    <Button variant="secondary" size="lg" type="submit" onClick={handleClear}>Clear</Button>
                    <Row id="creditBalance">
                        <ListGroup variant="flush">
                            <ListGroup.Item>Total Unpaid: <span>${(unpaidBalance.unpaid > 0) ? (unpaidBalance.unpaid) : ("0.00")}</span></ListGroup.Item>
                            <ListGroup.Item>Credit Limit: <span>${(unpaidBalance) ? (unpaidBalance.credit_limit) : ("0.00")}</span></ListGroup.Item>
                            <ListGroup.Item>Credit Remaining: <span>${(unpaidBalance && unpaidBalance.unpaid > 0) ? (Number(unpaidBalance.credit_limit) - unpaidBalance.unpaid) : ("0.00")}</span></ListGroup.Item>
                        </ListGroup>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Button variant="secondary" ref={prevButtonRef} type="submit" onClick={handlePrevious}>Previous</Button>
                        </Col>
                        <Col className="text-align-right">
                            <Button variant="secondary" ref={nextButtonRef} type="submit" onClick={handleNext}>Next</Button>
                        </Col>
                    </Row>
                </Form>
                {isError && <tr><td>There was an error getting orders</td></tr>}
                {(isLoading) ? (
                    <Loading />
                ) : (
                    <Table striped responsive="lg">
                        {TableHeaders()}
                        <tbody id="history-invoice">
                            {items.map((invoice, index) => {
                                let lineIndex = (Number(searchFilter.page) * 30) + index + 1;

                                return (
                                    <tr key={index}>
                                        <td>{lineIndex}</td>
                                        <td className="visited-details">
                                            <Link to={"/history/" + invoice.order_id} id={invoice.order_id}>{invoice.order_id}</Link>
                                        </td>
                                        {/* <td>{invoice.order_id}</td> */}
                                        <td>
                                            <a href={invoiceApiURL + invoice.order_id} target="_blank" rel="noopener noreferrer">
                                                <img width="20" height="22" src={download} alt="download" />
                                            </a>
                                        </td>
                                        <td>
                                            <a href={packinglistPDF + invoice.order_id} target="_blank" rel="noopener noreferrer">
                                                <img width="20" height="22" src={download} alt="download" />
                                            </a>
                                        </td>
                                        <td>{invoice.date_created}</td>
                                        <td>{(invoice.date_shipped) ? ("Shipped") : ("Not Shipped")}</td>
                                        <td>{invoice.custom_po}</td>
                                        <td>{invoice.ship_method}</td>
                                        <td>${(invoice.cost_total) ? (invoice.cost_total) : (invoice.price_total)}</td>
                                        <td>{invoice.company_name}</td>
                                        <td>{invoice.status}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )}
                <Form onSubmit={event => handleSubmit(event)}>
                    <Row>
                        <Col>
                            <Button variant="secondary" ref={prevButtonRefTwo} type="submit" onClick={handlePrevious}>Previous</Button>
                        </Col>
                        <Col className="text-align-right">
                            <Button variant="secondary" ref={nextButtonRefTwo} type="submit" onClick={handleNext}>Next</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}

export default History;
