/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: July 24th, 2020
 * 
 *  Description: The component that will show the chosen order details
 * 
 *****************************************************************************************************/

import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from "axios";
import qs from "qs";
import { orderItemApiURL, orderItemIdApiURL, postShipOrder, getUserTaxRate, postQuantityListApiURL, userApiURL, postCancelOrder } from '../../../context/addresses.js';
import { useAuth } from "../../../context/auth.js";
import './styles/history-details.css';
import Loading from '../../../context/loading.js';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { invoice_table_headers, invoice_table_headers_admin } from "../../../context/orderModels.js";
import OrderLineTableRows from './components/OrderLineTableRows.js';

// layout of the invoice
function HistoryDetails({orderId}) {
    let auth = useAuth();
    let navigate = useNavigate();
    const [order, setOrder] = useState({}); // The order info
    const [orderItem, setOrderItem] = useState([]); // Order Items
    const [isLoading, setIsLoading] = useState(false); // If loading
    const [isLoadingOrderItems, setIsLoadingOrderItems] = useState(false); // If loading
    const [isError, setIsError] = useState(false); // If there is an error
    const [errorStatus, setErrorStatus] = useState();
    const [numrows, setNumRows] = useState(0); // The num of items in the order
    const [shippedItems, setShippedItems] = useState([]);
    const [taxRate, setTaxRate] = useState();
    const [userInfo, setUserInfo] = useState({});
    const params = { order_id: order.order_id, ship_method: order.ship_method, items: shippedItems };
    const pattern = /^(ONZ|OCG|SCB|SNZ|CNZ|CDM|CDP|AFN|MD|NCG)/i
    let lines = [];

    const fetchOrderItem = useCallback(async () => {
        let product = { data: [{ part: '' }] };
        let response = { data: [] };

        setIsError(false);
        setIsLoadingOrderItems(true);

        try {
            response = await axios.get(orderItemApiURL + orderId, { Session: {}, withCredentials: true });

            product.data = response.data.map((item) => {
                let tempMaxProduct = '';
                let tempNotMaxCode = '';
                let productToSearch = '';
                
                if (!item.product && item.description) {
                    tempMaxProduct = item.description;
                    tempNotMaxCode = item.description;
                } else if (item.product && !item.description) {
                    tempMaxProduct = item.product;
                    tempNotMaxCode = item.product;
                } else if (item.product === item.pcode) {
                    tempMaxProduct = item.product;
                    tempNotMaxCode = item.product;
                } else if (item.product.match(pattern)) {
                    tempMaxProduct = item.product;
                    tempNotMaxCode = item.description;
                } else if (item.description.match(pattern)) {
                    tempMaxProduct = item.description;
                    tempNotMaxCode = item.product;
                } else {
                    tempMaxProduct = item.product;
                    tempNotMaxCode = item.product;
                }
                
                productToSearch = tempNotMaxCode;
                if (tempMaxProduct.match(/^OCG/i)) {
                    productToSearch = tempMaxProduct;
                }

                return { part: productToSearch }
            })
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("fetchOrderItem failed: " + error.message);
            setIsError(true);
        }

        try {
            if (auth.user.access !== 5) {
                const response_AS = await axios.post(postQuantityListApiURL, qs.stringify(product), { Session: {}, withCredentials: true });

                // assign forsale property from response_AS to response
                response.data = response.data.map((item) => {
                    let tempVal = response_AS.data.find((obj) => {
                        return obj['part'].toString() === item['product'].toString();
                    })
                    Object.defineProperty(item, 'forsale', {
                        value: (tempVal) ? (tempVal.forsale) : ('N/A'),
                        writable: false
                    })
                    return item;
                });
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("WholesaleInv failed: " + error.message);
            setIsError(true);
        }

        setOrderItem(response.data);
        setNumRows(response.data.length);

        setIsLoadingOrderItems(false);
    }, [auth.user.access, orderId])

    const fetchOrder = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await axios.get(orderItemIdApiURL + orderId, { Session: {}, withCredentials: true });

            let x = await getTaxRate(response.data[0].username);

            setOrder(response.data[0]);
            setTaxRate(x);
            getUserInfo(response.data[0].username);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("fetchOrder failed: " + error.message);
            setIsError(true);
        }

        setIsLoading(false);
    }, [orderId])

    // Get orderItem and Order
    useEffect(() => {
        document.title = `${orderId} | History Details - Max Advanced Brakes Wholesale`;
        fetchOrderItem();
        fetchOrder();
    }, [orderId, fetchOrderItem, fetchOrder]);

    const onShipped = useCallback((shippedLine, id) => {
        let temp = shippedItems;
        temp[id] = shippedLine;
        setShippedItems(temp);
    }, [shippedItems])

    async function getUserInfo(username) {
        try {
            const response = await axios.get(`${userApiURL}${username}`, { Session: {}, withCredentials: true });

            setUserInfo(response.data);
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
                
            console.log("getUserInfo failed: " + error.message);
        }
    }

    // Get the tax rate for the user of the order
    async function getTaxRate(username) {
        try {
            const response = await axios.get(`${getUserTaxRate}${username}`, { Session: {}, withCredentials: true });
            return response.data;
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }

            console.log("getTaxRate failed: " + error.message);
        }
    }

    async function shipOrder() {
        try {
            if (params.order_id === undefined || params.ship_method === undefined) {
                params.order_id = order.order_id;
                params.ship_method = order.ship_method;
            }
            console.log(params, shippedItems)
            await axios.post(postShipOrder, qs.stringify(params), { Session: {}, withCredentials: true });

            fetchOrderItem();
            fetchOrder();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("shipOrder failed: " + error.message);
        }
    }

    async function handleCancelOrder() {
        try {
            await axios.post(postCancelOrder, qs.stringify({ order_id: orderId }), { Session: {}, withCredentials: true });
            alert('Order Canceled');
            fetchOrderItem();
            fetchOrder();
        } catch(error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not signed in: " + error.message);
                setErrorStatus(error.response.status);
            }
            
            console.log("handleCancelOrder failed: " + error.message);
        }
    }

    const TableHeaders = () => {
        return (
            <thead>
                <tr>
                    {Object.entries((auth.user.access !== 5) ? invoice_table_headers_admin : invoice_table_headers).map(([_key, value], index) => {
                        if (value === 'Return' || value === 'Avaliable') {
                            return <th className='noPrint' key={index}>{value}</th>
                        } else {
                            return <th key={index}>{value}</th>
                        }
                    })}
                </tr>
            </thead>
        )
    }

    function goBack() {
        navigate(-1);
    }

    for (let i = 0; i < numrows; i++) {
        lines[i] = <OrderLineTableRows key={i} id={i} order={order} item={orderItem[i]} onShipped={onShipped} />;
    }

    if (errorStatus === 401) return <Navigate to="/login" />

    return (
        <div id="orderItem" className="page">
            <Container>
                <h2>{order.date_shipped !== null ? "Shipped" : ""} {orderId}</h2>
                <Row>
                    <Col>
                        <Button variant="secondary" onClick={handleCancelOrder}>Cancel Order</Button>
                    </Col>
                </Row>
                {isError && <div>There was an error getting order</div>}
                <Table className="orderInfo mt-3">
                    <tbody>
                        {isLoading ? (
                            <tr key={'loading'}><td><Loading /></td></tr>
                        ) : (
                            <>
                                <tr className='text-align-left' key={`created-po`}>
                                    <th>Entered date:</th>
                                    <td>
                                        {(order.date_created) ? (order.date_created) : (null)}
                                    </td>
                                    <th>PO:</th>
                                    <td>{order.custom_po}</td>
                                </tr>
                                <tr className='text-align-left' key={`address-shipped`}>
                                    <th>Address:</th>
                                    <td>{userInfo.address}</td>
                                    <th>Shipped:</th>
                                    <td><span>
                                        {(order.date_shipped) ? (order.date_shipped) : (null)}
                                    </span></td>
                                </tr>
                                <tr className='text-align-left' key={`username-method`}>
                                    <th>Ship to:</th>
                                    <td>{order.username}</td>
                                    <th>Shipvia:</th>
                                    <td>{order.ship_method}</td>
                                </tr>
                                <tr className='text-align-left' key={`company`}>
                                    <th>Store:</th>
                                    <td>{order.company_name}</td>
                                    <td colSpan="2"></td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
                <Table className="itemTable" striped>
                    {TableHeaders()}
                    <tbody>
                        {isLoadingOrderItems ? (<tr><td><Loading /></td></tr>) : lines}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4">Total:</td>
                            <td>
                                {(orderItem && orderItem.length !== 0) ? (orderItem.map(item => item.qty).reduce((acc, cur) => acc + cur)) : (0)}
                            </td>
                            {(auth.user.access !== 5) ? (<td></td>) : (null)}
                            <td>{(orderItem && orderItem.length !== 0) ? (orderItem.map(item => item.qty_shipped).reduce((acc, cur) => acc + cur)) : (0)}</td>
                            <td colSpan="5"></td>
                        </tr>
                    </tfoot>
                </Table>
                <Row>
                    <Col className="text-align-right">
                        <span id="priceGrandTotal">Subtotal: ${(order.cost_subtotal) ? (order.cost_subtotal) : (order.price_subtotal) ? (order.price_subtotal) : (null)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-align-right">
                        <span id="priceTax">Tax: ${(((order.cost_subtotal) ? (Number(order.cost_subtotal)) : (order.price_subtotal) ? (Number(order.price_subtotal)) : (null)) * taxRate).toFixed(2)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-align-right">
                        <span id="priceFinal">Total: ${(order.cost_final) ? (order.cost_final) : (order.price_final) ? (order.price_final) : (null)}</span>
                    </Col>
                </Row>
                <div className="text-align-center">
                    <Button className="noPrint" variant="secondary" onClick={goBack}>Back</Button>{' '}
                    {(auth.user.access !== 5 && order.status === "pending" && order.date_shipped === null) ? (
                        <Button className="noPrint" variant="warning" type="button" onClick={shipOrder}>Ship Order</Button>
                    ) : (null)}
                </div>
            </Container>
        </div>
    )
}

export default HistoryDetails;
