/*****************************************************************************************************
 *  Author: Adam Khan
 *  Date: June 25th, 2020
 * 
 *  Description: Navigation component that will be at the top of everypage. 
 *               The list of items will change depending on user access
 * 
 *****************************************************************************************************/

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { notLoggedInItems, loggedInItems } from "../../context/orderModels.js";
import { useAuth } from "../../context/auth.js";
import './styles/navigation.css';
import maxLogoBlack from '../../styles/pictures/BLACKLOGO1.png';
import maxLogoWhite from '../../styles/pictures/WhiteLogoStroke.png';
import arrowDown from '../../styles/pictures/down-arrow.png';
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Navigation() {
    let auth = useAuth();
    let navigate = useNavigate();
    let location = useLocation();
    const [navBackground, setNavBackground] = useState((location.pathname === "/login" && auth.user === null) ? ('navigationBanner-login navBarTransparent') : ("navBarSolid"));
    const loggedOutNavList = ["/login", "/sign-up"];
    let checknavlist = loggedOutNavList.includes(location.pathname);
    let showlink = !checknavlist ? "d-block" : "d-none";
    const navRef = useRef();
    navRef.current = navBackground;

    // Current user is logged out
    function signout() {
        auth.signout(() => navigate("/login"));
    }

    // Navigation seen when there is no user logged in
    const notLoggedIn = () => {
        return (
            <>
                {Object.entries(notLoggedInItems).map(([key, value], index) => {
                    return (
                        <li id={value} key={"notLoggedIn-" + index} className={`nav-item ${(checknavlist) ? ("d-block") : ("d-none")}`}>
                            <Link className="nav-link" to={"/" + value}>{key}</Link>
                        </li>
                    )
                })}
                <li id="maxBrakes" key={"notLoggedIn-MaxBrakes"} className={`nav-item ${(checknavlist) ? ("d-block") : ("d-none")}`}>
                    <a className="nav-link" id="redirectMax" href="https://maxbrakes.com/" target="_self">Back to MaxBrakes.com</a>
                </li>
            </>
        )
    }

    // Navigation seen by user that is logged in
    const loggedIn = () => {
        return (
            Object.entries(loggedInItems).map(([key, value], index) => {
                let isActiveText = '';
                if (location.pathname === value) isActiveText = 'active';

                return (
                    (key !== "Accounts" && key !== "Coupons" && key !== "Promotion" && key !== "Global Settings" && key !== "Unshipped") ? (
                        <li id={key} key={"loggedin-" + index} className={`nav-item ${showlink} ${isActiveText}`}>
                            <Link className="nav-link" to={value}>{key}</Link>
                        </li>
                    ) : (null)
                )
            })
        )
    }

    // When the screen is in mobile view, the navigation is to be clicked and will pop out
    function OffcanvasMenu() {
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
    
        function list() {
            return (
                <ul id="mobileUl" className="nav justify-content-center">
                    {(auth.user === null || auth.user === undefined) ? (
                        <>
                            <li id="login-item" key={"notLoggedIn-mobile-login"} className={`navitem ${(checknavlist || location.pathname === "/notFound" || location.pathname === "/notAuthorized") ? ("d-block") : ("d-none")}`}>
                                <Link className="nav-link" to={"/login"}>Login</Link>
                            </li>
                            {notLoggedIn()}
                        </>
                    ) : (
                        <>
                            {loggedIn()}
                            <li id="accountsLink" key={"notLoggedIn-mobile-logout"} className={`nav-item ${(checknavlist || location.pathname === "/notFound" || location.pathname === "/notAuthorized") ? ("d-none") : ("d-block")}`} onClick={signout}>
                                <Link className="nav-link" to="/login">Log out</Link>
                            </li>
                        </>
                    )}
                </ul>
            );
        }
    
        return (
            <div>
                <div key={1}>
                    <Button id="navButton" onClick={handleShow} className="me-2">
                        <svg width="3em" height="3em" viewBox="0 0 16 16" className="bi bi-list" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </Button>
                    <Offcanvas key={1} placement={'end'} show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton></Offcanvas.Header>
                        <Offcanvas.Body onClick={handleClose}>
                            {list()}
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            </div>
        );
    }

    // When the user is on the login page the navigation will change background when page is scrolled down or return to the top
    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 0;
            if (location.pathname === "/login") {
                if (show) {
                    setNavBackground('navigationBanner-login navBarSolid');
                } else {
                    setNavBackground('navigationBanner-login navBarTransparent');
                }
            } else {
                setNavBackground("navBarSolid");
            }
        }
        handleScroll();
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [location.pathname])

    function accountDropdownList() {
        if (auth.user.access === 1) {
            return (
                <>
                    <Link to="/accounts">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Accounts</Dropdown.Item>
                    </Link>
                    <Link to="/coupon">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Coupons</Dropdown.Item>
                    </Link>
                    <Link to="/promotion">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Promotions</Dropdown.Item>
                    </Link>
                    <Link to="/unshipped">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Unshipped</Dropdown.Item>
                    </Link>
                    <Link to="/global-settings">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Global Settings</Dropdown.Item>
                    </Link>
                </>
            )
        } else if (auth.user.access !== 5) {
            return (
                <>
                    <Link to="/accounts">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Accounts</Dropdown.Item>
                    </Link>
                    <Link to="/coupon">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Coupons</Dropdown.Item>
                    </Link>
                    <Link to="/promotion">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Promotions</Dropdown.Item>
                    </Link>
                    <Link to="/unshipped">
                        <Dropdown.Item className={`nav-item ${showlink}`} as="button">Unshipped</Dropdown.Item>
                    </Link>
                </>
            )
        } else {
            return;
        }
    }

    return (
        <div id="websiteHeader" className="noPrint">
            <Container fluid className="pr-0 pl-0">
                <div className={`navigationBanner ${navRef.current}`}>
                    <div id="navigation">
                        <nav className="navbar">
                            <div className="col-md-2" id="logoHome">
                                <Link to="/">
                                    <img id="logo" src={(navRef.current.match(/navBarTransparent/) && navRef.current.match(/navBarTransparent/)[0] === "navBarTransparent") ? (maxLogoWhite) : (maxLogoBlack)} alt="Max Brakes Wholesale" />
                                </Link>
                            </div>
                            <ul className="nav justify-content-center">
                                {(auth.user === null || auth.user === undefined) ? (notLoggedIn()) : (loggedIn())}
                            </ul>
                            {(auth.user !== null && auth.user !== undefined) ? (
                                <div className={`col-sm ${showlink}`}>
                                    <Row>
                                        <Form.Label>
                                            <DropdownButton id="accountButton" title={<img id="accountLogo" src={arrowDown} alt="" />}>
                                                {accountDropdownList()}
                                                <Dropdown.Divider />
                                                <Link to="/login">
                                                    <Dropdown.Item onClick={signout} as="button">Log out</Dropdown.Item>
                                                </Link>
                                            </DropdownButton>
                                        </Form.Label>
                                    </Row>
                                </div>
                            ) : (null)}
                        </nav>
                    </div>
                    <div id="mobileUI">
                        <Row>
                            <div id="logoHomeMobile" className="col-md">
                                <Link to="/">
                                    <img id="logo" src={(navRef.current.match(/navBarTransparent/) && navRef.current.match(/navBarTransparent/)[0] === "navBarTransparent") ? (maxLogoWhite) : (maxLogoBlack)} alt="Max Brakes Wholesale" />
                                </Link>
                            </div>
                            <div id="drawerButton" className="col-md">
                                {OffcanvasMenu()}
                            </div>
                        </Row>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Navigation;
