import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function AddToCartFeature(props) {
    const [item] = useState(props.item);
    const [numToAdd, setNumToAdd] = useState(0);

    return (
        <>
            <Col md={12} lg={3}>
                <Form.Control type="number" value={numToAdd} min="0" onChange={(e) => setNumToAdd(e.target.value)} />
            </Col>
            <Col md={12} lg={2}>
                <Button variant='secondary' value={item} onClick={() => props.addToCart(item, numToAdd)}>Add</Button>
            </Col>
        </>
    )
}

export default AddToCartFeature;